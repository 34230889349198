import { Button, Dialog, DialogActions, DialogContent, DialogContentText, TextField } from '@material-ui/core'
import React, { useState } from 'react';
import { CustomerMessages } from '../../../core/api';
import { messageType } from '../../../core/contants';
import { CustomSelect } from '../../../shared/Forms';
import { showToast } from '../../../shared/ToastAlert/ToastAlert';
import  '../OrderDetails.scss';

export default function RaiseIssue({
    close=()=>{},
    open=false,
    issueTypes=[],
    orderId=""
}) {

    const [formInvalid,setFormInvalid]= useState(false);
    const [issueType,setIssueType]= useState('');
    const [subject,setSubject]= useState('');
    const [message,setMessage]= useState('');

    const onClickSubmit = () =>{
        if(subject === "" || issueType === '' || message === ''){
            setFormInvalid(true);
            return
        }
        const sendObj ={
            orderId: orderId,
            issueTypeId: issueType,
            subject: subject,
            message: message,
            messageType:messageType.orderMessage
        };
        CustomerMessages.addMessage(sendObj)
        .then((res)=>{
            if(res){
                showToast({
                    title:"The issue has been raised. Customer support will be in touch with you shortly."
                });
                close();
            }
        })
        .catch(errorHandler)
    }

    const errorHandler = (error) =>{
        let errorMessage = 'Something went wrong!'
        if (error.response && error.response.data) {
            errorMessage = error.response.data?.['Message']
        } else {
            errorMessage = error.message
        }
        showToast({
            title:errorMessage,
            type:'error'
        });
    }

  return (
    <Dialog className="issueModalContainer" open={open} fullWidth>
        <DialogContent>
            <DialogContentText>Raise Issue</DialogContentText>
            <TextField
                id="filled-basic" 
                label="Subject"
                onChange={(e)=>setSubject(e.target.value)}  
                value={subject}
                type="text"
                className="textInput"
                margin="normal"
                required
                variant="outlined"
                error={formInvalid && subject === ''}
            />
            <CustomSelect
                name='IssueType'
                value={issueType}
                onChange={(e)=>setIssueType(e.target.value)}
                options={issueTypes}
                label="Issue Type"
                variant="outlined"
                error={formInvalid && issueType === ''}
            />
             <TextField
                id="filled-basic" 
                label="message"
                onChange={(e)=>setMessage(e.target.value)}  
                value={message}
                type="text"
                className="textInput"
                margin="normal"
                required
                multiline
                variant="outlined"
                rows={4}
                error={formInvalid && message === ''}
            />
        </DialogContent>
        <DialogActions>
            <Button
                color="primary" 
                variant="contained" 
                onClick={onClickSubmit}
            >
                Send
            </Button>
            <Button color="primary" onClick={close}>
                Cancel
            </Button>
        </DialogActions>
    </Dialog>
  )
}
