import React, { Component } from "react";
import { Link } from "react-router-dom";

import masterApi from '../../core/api/master-api/master-api';
import authApi from '../../core/api/auth-api/auth-api'
import imageLib from '../../core/lib/image';

import "./OrderPageOne.scss";
import Divider from "@material-ui/core/Divider";

import SideMenu from "../../shared/SideMenu/SideMenu";
import TopBar from "../../shared/TopBar/TopBar";
import SimpleButton from "../../shared/SimpleButton/SimpleButton";
import TermsBar from "../../shared/TermsBar/TermsBar";

import SmallItemCard from "../../shared/SmallItemCard/SmallItemCard";
import { MainUtils } from "../../utils";

const displayEmptyProductsTags = false;

export default class OrderPageOne extends Component {
    state = {
        tags: [],
        restaurants: [],
        user: {},
        isOnlineOrderAllowed:null
    };

    componentDidMount = () => {
        this.checkOnlineOrderAllowed()
    }

    async checkOnlineOrderAllowed(){
        const isAllowed = await masterApi.getOnlineOrderAllowed();
        if(isAllowed){
            this.fetchRestaurants();
        }
        this.setState({isOnlineOrderAllowed:isAllowed})
    }

    filterByRestaurantID(item) {
        const { user } = this.state
        if (item.id === user.restaurantId) {
            return item
        }
    }

    fetchRestaurants = async () => {
        const user = await authApi.getUser();
        let restaurants = await masterApi.getRestaurants();
        this.setState({
            user: user
        }, () => {
            if (user.roleDescription === "POS") {
                window.location.href = `/restaurant/${user.restaurantId}`;
                restaurants = restaurants.filter(this.filterByRestaurantID.bind(this))
            };
        }
        );

        this.fetchProductsByTags(restaurants[0].id);

        this.setState({
            restaurants
        });
    }

    fetchProductsByTags = async (restaurantId) => {
        const tagIds = await masterApi.getTags();
        const tags = [];
        const apiCalls = [];

        for (let tag of tagIds) {
            const { ids, description } = tag;

            tags.push({
                ids,
                description,
                items: []
            });
            for (let id of ids) {
                apiCalls.push(masterApi.getProductsByTag(id));
            }
        }

        this.setState({
            tags
        }, () => {
            Promise.all(apiCalls).then(results => {
                const { tags } = this.state;

                for (let i = 0; i < results.length; i++) {
                    if (!!results[i][0] && results[i].length) {
                        for (let tag of tags) {
                            if (tag.description === results[i][0].tagDescription) {
                                let items = tag.items
                                items = items.concat(results[i])
                                tag.items = items
                                break;
                            }
                        }

                    }
                }

                this.setState({
                    tags
                })
            })
        });
    }

    getRestaurants() {
        const { restaurants } = this.state;

        return restaurants.map((restaurant, index) => {
            return (
                <div key={index} className="restaurant-item">
                    <Link to={`/restaurant/${restaurant.id}`}>
                        <div className="item">
                            <img src={imageLib.getImageSrcByBase64(restaurant.icon)} />
                            <div className="restaurant-name">{restaurant.name}</div>
                        </div>
                    </Link>
                </div>
            );
        })
    }

    getProductsByTags() {
        const { tags } = this.state;

        return tags.map((tag, tagIndex) => {
            if (tag.items.length) {
                return (
                    <div className="row-item" key={tagIndex}>
                        <div className="title">{tag.description}</div>
                        <div className="specials-list">
                            {tag.items.map((item, itemIndex) => {
                                return MainUtils.isPosOnlyProduct(item.productName)?(
                                    <SmallItemCard
                                        key={itemIndex}
                                        productName={item.productName}
                                        categoryId={item.categoryId}
                                        productId={item.productId}
                                        productThumbnailURL={item.productThumbnailURL}
                                    />
                                ):null
                            })}
                        </div>
                    </div>
                )
            } else {
                if (displayEmptyProductsTags) {
                    return (
                        <div className="row-item" key={tagIndex}>
                            <div className="title">{tag.description}</div>
                            <div className="no-products">
                                No Products
                            </div>
                        </div>
                    )
                }

                return null;
            }
        })
    }

    render() {
        const {isOnlineOrderAllowed} = this.state;
        return (
            <div className="orderPageOne-container">
                <div className="section">

                    <div className="top">
                        <TopBar />
                    </div>

                    <div className="child-1">

                        {/* <div className="row-1">
                            <div className="title">Welcome to Gibraltar Food Hub</div>
                            <div className="subtitle">
                                For the best user experience, download the app for Android or iOS by clicking button below
                            </div>
                            <SimpleButton text="DOWNLOAD THE APP" />
                        </div> */}
                        {
                            !isOnlineOrderAllowed && isOnlineOrderAllowed !== null &&
                            <div className="row-1" >
                                <div className="title">Sorry we are currently closed online order</div>
                            </div>
                        }


                        <div className="restaurants">
                            <div className="restaurant-item">
                                {this.getRestaurants()}
                            </div>
                        </div>


                        <Divider variant="fullWidth" />

                        {this.getProductsByTags()}

                    </div>

                    <div className="footer">
                        <TermsBar />
                    </div>

                </div>
                <div className="side-menu-container">
                    <SideMenu />
                </div>

            </div>
        );
    }
}
