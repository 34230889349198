import httpApi from "../http-api/http-api";
import store from "../../store";

import authApi from "../auth-api/auth-api";

import { CartActions } from "../../constants/actions";

class CartApi {
  setRandomCartId = (randomCartId) => {
    localStorage.setItem("randomCartId", randomCartId);
  };

  getRandomCartId = () => {
    return localStorage.getItem("randomCartId");
  };

  addItem = async (cartItem) => {
    if (authApi.authenticated) {
      const res = await httpApi.sendPostRequest("/Cart/AddCartItem", cartItem);
      return res;
    } else {
      const res = await httpApi.sendPostRequest("/Cart/AddCartItem", {
        ...cartItem,
        ramdomCardId: this.getRandomCartId(),
      });

      const { ramdomCardId: randomCartId } = res;
      this.setRandomCartId(randomCartId);
      this.getCartDetails();
      return res;
    }
  };

  removeItem = async (cartItemId) => {
    const res = await httpApi.sendPostRequest(`/Cart/RemoveItem`, {
      cartItemId,
      ramdomCardId: authApi.authenticated ? null : this.getRandomCartId(),
    });

    return res;
  };

  getCartDetails = async () => {
    const cart = await httpApi.sendPostRequest(`/Cart/GetCartDetails/`, {
      ramdomCardId: authApi.authenticated ? null : this.getRandomCartId(),
    });

    const payload = {
      cart: cart['cartItems'],
      cartInfo:{
        netTotal:cart['netTotal'],
        promoDiscount:cart['promoDiscount'],
        totalPrice:cart['totalPrice'],
        promoName:cart['promoName']
      }
    }

    store.dispatch({
      type: CartActions.CART_UPDATE,
      payload ,
    });

    return payload;
  };

  editItem = async (CartItem) => {
    const res = await httpApi.sendPostRequest("/Cart/AddCartItem", CartItem);
    this.getCartDetails();
    return res;
  };

  editQuantity = async (editCartItem) => {
    const res = await httpApi.sendPostRequest(
      "/Cart/IncreaseItemQuantity",
      editCartItem
    );
    this.getCartDetails();
    return res;
  };

  getRandomProducts = async (cartId) => {
    const res = await httpApi.sendGetRequest(`/Cart/GetRoomProduct/${cartId}/`);
    return res;
  };

  clearCart = async (cartId) => {
    const res = await httpApi.sendGetRequest(`/Cart/ClearCart/${cartId}/`);
    this.getCartDetails();
    return res;
  };

  getMinimumOrderAmount =async ()=>{
    const res = await httpApi.sendGetRequest(`/System/MinimumOrderAmount`);
    return res;
  }
  isOnlinePaymentAllowed =async ()=>{
    const res = await httpApi.sendGetRequest(`/System/OnlinePaymentsAllowed`);
    return res;
  }
}

export default new CartApi();
