import httpApi from "../http-api/http-api";
import authApi from "../auth-api/auth-api";

class CustomerMessagesApi {
  getAllMessages = async () => {
    const itemsPerCategory = await httpApi.sendGetRequest(`/OrderMessage/GetAll`);
    return itemsPerCategory;
  };

  getNotResolvedMessages = async (pageIndex=1,rowsPerPage=10) => {
    const result = await httpApi.sendGetRequest(`/OrderMessage/GetNotResolved?PageNumber=${pageIndex}&PageSize=${rowsPerPage}`);
    return result;
  };

  getResolvedMessages = async (pageIndex=1,rowsPerPage=10) => {
    const result = await httpApi.sendGetRequest(`/OrderMessage/GetResolved?PageNumber=${pageIndex}&PageSize=${rowsPerPage}`);
    return result;
  };


  getNotResolveCount = async (status,data) => {
    const count = await httpApi.sendGetRequest(`/OrderMessage/GetNotResolvedCount`);
    return count;
  };

  async addMessage(data) {
    const response = await httpApi.sendPostRequest(`/OrderMessage/Add`,data);
    return response
  }

  async updateMessage(data){
    const response = await httpApi.sendPutRequest(`/OrderMessage/Update`,data);
    return response;
  }


  async getIssueTypes(){
    const result = await httpApi.sendGetRequest(`/IssueType/GetAll`);
    return result;
  }
  async getUserInbox(pageIndex=1,rowsPerPage=10){
    const result = await httpApi.sendGetRequest(`/OrderMessage/GetUserInbox?PageNumber=${pageIndex}&PageSize=${rowsPerPage}`);
    return result;
  }

  async getNotReadCount(){
    const result = await httpApi.sendGetRequest(`/OrderMessage/GetNotReadCount`);
    return result;
  }

  async markAsRead(id=0){
    const result = await httpApi.sendPutRequest(`/OrderMessage/Read/${id}`);
    return result;
  }
  async markAsResolved(id=0){
    const result = await httpApi.sendPutRequest(`/OrderMessage/Resolved/${id}`);
    return result;
  }
}

export default new CustomerMessagesApi();
