import moment from 'moment';
import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { convertToLast3digit } from '../../utils/MainUtils';
import './Epos.scss'

const DriverReportPrint = forwardRef((props,ref) => {
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
    });
  
    useImperativeHandle(ref, () => ({

        Print() {
            setTimeout(()=>handlePrint(),500)
        }
    
      }));

    return (
      <div>
           <div style={{ display: "none" }}>
            <ComponentToPrint 
                ref={componentRef} 
                data={props.data} 
                driverShift={props.driverShift}
            />
           </div>
        {/* <button onClick={handlePrint}>Print this out!</button> */}
      </div>
    );
  });

export default DriverReportPrint

export class ComponentToPrint extends React.PureComponent {
    render() {
        const {data,driverShift} = this.props

        if(!data){
            return <div></div>
        }

        const shiftTimeTable = (
            <table>
                <tr>
                    <th>
                        Start Date(Time)
                    </th>
                    <th>
                        {data.startDateTime}
                    </th>
                </tr>
                <tr>
                    <th>
                        End Date(Time)
                    </th>
                    <th>
                        {data.endDateTime}
                    </th>
                </tr>
            </table>
        )
        const printHtml =()=>(
            <div style={{
                        padding:'2%',
                        display:'flex',
                        justifyContent:'center',
                        flexDirection:'column',
                        paddingLeft:'7%'
                    }}>
                <h2 style={{margin:0}}>Driver Report for {data.driverName}</h2>
                
                {
                    driverShift?
                        shiftTimeTable
                    :
                    <h3 style={{margin:0}}>
                        {moment(data.selectedDate).format('DD-MM-YYYY')} 
                        {moment(data.startTime).format(' hh:mm a')} to 
                        {moment(data.endTime).format(' hh:mm a')}
                    </h3>

                }
                
                <table style={{marginTop:'10px'}}>
                    <tbody>
                        <tr>
                            <th>
                                Order ID
                            </th>
                            <th>
                                Payment Method
                            </th>
                            <th>
                                Amount
                            </th>
                        </tr>
                        {
                            data.orders.map((res,i)=>(
                                <tr>
                                    <td>
                                        {convertToLast3digit(res.orderNumber)}
                                    </td>
                                    <td>
                                        {res.paymentMethod}
                                    </td>
                                    <td>
                                        £{Number(res.totalPrice).toFixed(2)}
                                    </td>
                                </tr>
                            )) 
                        }
                        <tr><td colSpan="3"><hr /></td></tr>
                        <tr>
                            <td colSpan="2">Total Cash Amount</td>
                            <td>£{Number(data.cashAmount).toFixed(2)}</td>
                        </tr>
                        <tr>
                            <td colSpan="2">Cancelled Orders Cash Amount</td>
                            <td>£{Number(data.cancelledOrderCashAmount).toFixed(2)}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
 
            return printHtml()

    }
  }

 