import { CartActions } from "../constants/actions";

const defaultState = {
  cart: [],
  usePoints: false,
  points: 0,
  cartInfo:{},
  cartFinalTotal:0
};

const alert = (state = defaultState, action) => {
  if (action && action.payload) {
    switch (action.type) {
      case CartActions.CART_UPDATE:
        if (action.payload) {
          return {
            ...state,
            ...action.payload
          };
        }
        break;

      case CartActions.USE_POINTS:
        console.log('USE_POINTS', action);
        const { usePoints, points } = action.payload;

        return {
          ...state,
          usePoints,
          points
        }
        break;

      default:
        return { ...state };
    }
  }

  return state;
};

export default alert;
