import React, { useEffect } from 'react';
import TopBar from "../../shared/TopBar/TopBar";
import style from './OrderPlaced.module.scss';
import successImg from './img/success.png';
import SimpleButton from "../../shared/SimpleButton/SimpleButton";
import { useState } from 'react';
import orderApi from '../../core/api/order-api/order-api';
import authApi from '../../core/api/auth-api/auth-api';
import PosPrint from '../EPOS/PosPrint';
import { useRef } from 'react';
import OrderCancel from '../CustomerSupport/CSOrderDetails/components/OrderCancel';
import { showToast } from '../../shared/ToastAlert/ToastAlert';
import { paymentGateway } from '../../core/contants';

const OrderPlaced = (props) => {
    const [isLoading,setLoading] = useState(true);
    const [user,setUser] = useState(null);
    const PosPrintRef = useRef();
    const [orderData,setOrderData] = useState(null);
    const handleBackToHome = () => {
        props.history.push('/order-page-one');
    }

    const handleViewOrders = () => {
        props.history.push('/order-list');
    }

    async function sumupOrderPlaced(checkout_id) {
        // console.log("Retrieve Order status");
        try{
            // const orderData = JSON.parse(localStorage.getItem("cartOrder"));//NoUse
            const orderId = JSON.parse(localStorage.getItem("orderId"));
            
            const retrieveSumupCheckoutRef = await orderApi.retrieveSumupCheckout(checkout_id);
            // console.log("retrive checkout",retrieveSumupCheckoutRef)
            const responseDecode = JSON.parse(retrieveSumupCheckoutRef.responseMessage);
            if(responseDecode && retrieveSumupCheckoutRef.success && responseDecode.status === 'PAID'){
                const sendObj = {"orderId":orderId,"sumupTransactionCode":responseDecode['transaction_code']};
                // console.log("SentObj",sendObj)
                 const res = await orderApi.orderPlaced(sendObj);
                localStorage.removeItem("checkout_id");
                // localStorage.removeItem("cartOrder");
                localStorage.removeItem("orderId");
                setLoading(false);
            }else{
                localStorage.removeItem("checkout_id");
                // localStorage.removeItem("cartOrder");
                localStorage.removeItem("orderId");
                setLoading(false);
            }
        }
        catch(err){
            showToast({
                title:'Something went wrong, please try again',
                type:'error'
            });
            localStorage.removeItem("checkout_id");
            // localStorage.removeItem("cartOrder");
            localStorage.removeItem("orderId");
            setLoading(false);
            // console.log(err)
        }
    }

    async function setUserData(checkout_id){
        const user = await authApi.getUser();
        setUser(user)
        if(user && !checkout_id && ( user.roleDescription === 'POS'))
            setPOS_OrderData(JSON.parse(localStorage.getItem("OrderPrintData")));
    }

    async function setPOS_OrderData(data){
        setOrderData(data);
        setTimeout(()=>  PosPrintRef.current.Print(),1000)
    }

    useEffect( ()=>{
        const orderId = JSON.parse(localStorage.getItem("orderId"));
        if(orderId){
            orderApi.retrieveOrderCheckout(orderId).then(checkoutRes => {
                if(checkoutRes.gateway === paymentGateway.sumup){
                    setUserData(checkoutRes.checkoutId);
                    sumupOrderPlaced(checkoutRes.checkoutId);
                }
                else if(checkoutRes.gateway === paymentGateway.paylands){
                    setUserData();
                    setLoading(false);
                }
                else{
                    setUserData();
                    setLoading(false);
                }
            })        
        }
        else{
            setUserData();
            setLoading(false);
        }
        localStorage.removeItem("orderId");
    },[])
    
    if(isLoading)
    return (
        <div className={style.container}>
            <div className={style.section}>
                <h5 style={{textAlign:'center'}}>Loading Please Wait....</h5>
            </div>
        </div>    
    )
    return (
        <div className={style.container}>
            <div className={style.section}>
                <div className={style.topBar}>
                    <TopBar />
                </div>

                <div className={style.body}>
                    <div className={style.messageContainer}>
                        <div className={style.img}>
                            <img src={successImg} alt="" />
                        </div>

                        <div className={style.message}>
                            Order Placed Successfully!
                        </div>

                        <div className={style.actions}>
                            <SimpleButton
                                className={style.deleteButton}
                                size="small"
                                variant="contained"
                                color="primary"
                                type="button"
                                text="Back to Home"
                                onFunction={handleBackToHome}
                            >
                            </SimpleButton>

                            {
                                user && user.roleDescription === 'Customer' && 
                                <SimpleButton
                                    className={style.deleteButton}
                                    size="small"
                                    variant="outlined"
                                    color="primary"
                                    type="button"
                                    text="View Orders"
                                    onFunction={handleViewOrders}
                                >
                                </SimpleButton>
                            }
                            {
                                    user && orderData && (user.roleDescription === 'POS' || user.impersonation) &&
                                    <>
                                        <SimpleButton
                                            className={style.deleteButton}
                                            size="small"
                                            variant="outlined"
                                            color="primary"
                                            type="button"
                                            text="Reprint Receipt"
                                            onFunction={()=>PosPrintRef.current.Print()}
                                        >
                                        </SimpleButton>
                                        <OrderCancel 
                                            orderId = {orderData['orderDetails'].id}
                                            outlineButton = {true}
                                            fetchOrderDetails={()=>setTimeout(handleBackToHome,4000)}
                                        />
                                        <PosPrint
                                            ref={PosPrintRef} 
                                            data={{
                                                posOrderDetails:orderData['orderDetails'],
                                                paymentMethod:orderData['paymentMethod'],
                                                description:orderData['description']
                                            }} 
                                        />

                                    </>
                            }
                            
       
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OrderPlaced;