import React, { Component } from 'react'
import styles from './AddTime.module.scss'
import { createMuiTheme, withStyles, makeStyles, createStyles } from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import restaurantApi from '../../../../../core/api/restaurant-api/restaurant-api';


export default class AddTime extends Component {
    state = {
        isDialogOpen: false,
        minutes: 0,
    }

    closeDialog = () => {
        this.setState({
            isDialogOpen: false,
            minutes: 0,
        });
    };

    handleAddTime = async () => {
        const { minutes } = this.state
        const { updateOrders, restaurantId } = this.props;

        await restaurantApi.updateRestaurantPrepTime(restaurantId, minutes)
        this.closeDialog()
        if (updateOrders) {
            updateOrders()
        }
    }


    renderTimes() {
        const times = [10, 15, 20, 30, 45, 60]
        const { restaurantDelayTime } = this.props
        return times.map((i, j) => {
            return (
                <div className={styles.buttonItem} key={j}>
                    <Button
                        onClick={() => {
                            this.setState({
                                isDialogOpen: true,
                                minutes: i,
                            })
                        }}
                        style={{
                            backgroundColor: restaurantDelayTime == i ? "#48ff50" : "#d9211d",
                        }}
                        variant="contained"
                    // color={restaurantDelayTime == i ? 'secondary' : "primary"}
                    >
                        {i}
                    </Button>
                </div>
            )
        })

    }
    render() {
        const { isDialogOpen, minutes } = this.state
        const { restaurantDelayTime } = this.props
        return (
            <div className={styles.container}>
                <div className={styles.title}>
                    Add Time To Orders :

                </div>
                <div className={styles.buttonList}>
                    {this.renderTimes()}
                </div>
                <Dialog
                    open={isDialogOpen}
                    onClose={this.closeDialog}
                >
                    <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to {restaurantDelayTime == minutes ? "remove " : "add "} {minutes} minutes {restaurantDelayTime == minutes ? "from " : "to "} the kitchen?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleAddTime} color="primary">
                            Yes
                        </Button>
                        <Button onClick={this.closeDialog} color="primary" autoFocus>
                            No
                        </Button>
                    </DialogActions>
                </Dialog>

            </div>
        )
    }
}
