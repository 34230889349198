import React, { Component } from "react";
import "./TermsBar.scss";

import BackButton from "../BackButton/BackButton";
import Divider from "@material-ui/core/Divider";
import { Link } from "react-router-dom";

export default class TermsBar extends Component {
  

  render() {
    return (
      <div className="terms-bar">
        <Divider variant="fullWidth" />
        <div className="bottom">
        <Link to="/tnc" target="_blank">
          <div className="terms" >
            <u>TERMS AND CONDITIONS</u>
          </div>
        </Link>  
          <div className="back">
            <BackButton text="BACK TO HOME" link="/" />
          </div>
        </div>
      </div>
    );
  }
}
