export const validateHalfPizza =(cart) =>{
    const halfCategoryPizza = cart.filter((res)=>Number(res.categoryId) === 50);
    const numberOfHalfPizza = halfCategoryPizza.length;
    const isOddNumber = (numberOfHalfPizza & 1)?true:false;

    const StandardPizzaArray = halfCategoryPizza.filter((res)=>res.productSizeVarientName.includes('Standard'));
    const JumboPizzaArray = halfCategoryPizza.filter((res)=>res.productSizeVarientName.includes('Jumbo'));

    const reducer = (accumulator, currentValue) => accumulator + currentValue;

    const numberOfStandardPizza = StandardPizzaArray.length ? StandardPizzaArray.map((order)=>order.quantity).reduce(reducer) : 0;
    const numberOfJumboPizza = JumboPizzaArray.length ? JumboPizzaArray.map((order)=>order.quantity).reduce(reducer) : 0;

    const isStandardOdd = (numberOfStandardPizza & 1)?true:false;
    const isJumboOdd = (numberOfJumboPizza & 1)?true:false;

    // console.log("isOddNumber",isOddNumber,"numberOfStandardPizza",numberOfStandardPizza.length,isStandardOdd)
    // console.log("numberOfJumboPizza",numberOfJumboPizza.length,isJumboOdd)
    // console.log(numberOfStandardPizza,numberOfJumboPizza)
    // console.log(isStandardOdd,isJumboOdd)

    const returnObj ={
        success:true,
        errMessage:null
    }
    if(
        (isOddNumber && isStandardOdd && numberOfStandardPizza > 0) || 
        (isOddNumber && isJumboOdd && numberOfJumboPizza > 0) || 
        (isJumboOdd && numberOfStandardPizza === 0) || 
        (isStandardOdd && numberOfJumboPizza === 0)
    ){
        returnObj.success = false;
        returnObj.errMessage = "You have not completed your half and half pizza order"
    }
    else if(isStandardOdd || isJumboOdd){
        returnObj.success = false;
        returnObj.errMessage = "Please check you half and half pizza order, there is a difference in pizza sizes"
    }
    // console.log(returnObj)

    return returnObj;
}