import React, { Component } from "react";
import style from "./Header.module.scss";
import classNames from 'classnames'

import Button from "@material-ui/core/Button";

import restaurantApi from "../../../../../core/api/restaurant-api/restaurant-api";
import masterApi from "../../../../../core/api/master-api/master-api";
import RecepitList from "../KitchenReceiptList/KitchenReceiptList";
import FinanceReport from "../../../../CustomerSupport/shared/Sidebar/components/FinanceReport";
import moment from "moment";

export default class Header extends Component {
    state = {
        isHold: false,
        curTime:new Date()
    }

    componentDidMount = () => {
        setInterval(() => {
            this.setState({
              curTime : new Date()
            })
          }, 60000);

        this.fetchHoldState()
    }

    fetchHoldState = async () => {
        const restaurants = await masterApi.getRestaurants();
        restaurants.forEach(restaurant => {
            if (restaurantApi.restaurantId === restaurant.id) {
                this.setState({ isHold: !restaurant.isAllowOrder })
            }
        });
    }

    updateHoldState = async () => {
        const { isHold } = this.state
        await restaurantApi.setRestaurantHoldState(restaurantApi.restaurantId, !isHold)
    }

    handleHoldClick = () => {
        const { isHold } = this.state
        this.setState({
            isHold: !isHold
        }, () => {
            this.updateHoldState()
        })

    }

    handleLogout = async () => {
        await restaurantApi.logout(false)
    }
    render() {
        const { isHold , curTime } = this.state;
        const {orderHistory,restaurant} = this.props;
        return (
            <div className={style.container}>
                <div className={style.title}>{restaurant} <h2 className={style.time}>{moment(curTime).format('HH:mm')}</h2> </div>

                <div className={style.buttonContainer}>
                        <FinanceReport 
                            button2={true}
                            restaurantId ={ restaurantApi.restaurantId}
                            kitchen={true}
                        />
                    <div className={style.tools}>
                        <RecepitList orderHistory={orderHistory} />
                    </div>
        
                    <div className={classNames(style.tools, isHold && 'animated pulse infinite')}>
                        <Button
                            variant="contained"
                            onClick={this.handleHoldClick}
                            color="primary"
                            
                        >
                            {/* {isHold ? "Resume" : "Hold"} */}
                            {isHold ? "Reopen" : "Close"}
                        </Button>
                    </div>
                    
                    <div className={style.tools}>
                        <Button
                            variant="contained"
                            onClick={this.handleLogout}
                            color="primary">
                            LOGOUT
                        </Button>
                        
                    </div>
                </div>
              
                
            </div>
        );
    }
}
