import React, { Component } from "react";
import "./SingleCategory.scss";
import FastfoodIcon from "@material-ui/icons/Fastfood";
import LinearProgress from "@material-ui/core/LinearProgress";

import SideMenu from "../../shared/SideMenu/SideMenu";
import TopBar from "../../shared/TopBar/TopBar";
import BackButton from "../../shared/BackButton/BackButton";
import TermsBar from "../../shared/TermsBar/TermsBar";

import MediumItemCard from "../../shared/MediumItemCard/MediumItemCard";
import CategoryItem from "../../shared/CategoryItem/CategoryItem";

import categoryApi from "../../core/api/category-api/categoty-api";
import authApi from "../../core/api/auth-api/auth-api";
import { MainUtils } from "../../utils";

export default class SingleCategory extends Component {
  state = {
    isLoading: true,
    restaurantId: null,
    categoryId: null,
    categoryName: "",
    itemsPerCategory: [],
    categories: [],
    user: {},
  };

  componentDidMount = () => {
    this.init();
  };

  componentDidUpdate() {
    const { restaurantId, categoryId } = this.state;
    const {
      restaurantId: propRestaurantId,
      categoryId: propCategoryId,
    } = this.props.match.params;

    if (restaurantId !== propRestaurantId || (propCategoryId && categoryId !== propCategoryId)) {
      this.init();
    }
  }

  init = async () => {
    const { restaurantId, categoryId } = this.props.match.params;
    const user = await authApi.getUser();
    this.setState(
      {
        restaurantId,
        categoryId,
        isLoading: true,
        user: user,
      },
      async () => {
        await this.fetchCategories();
        this.fetchCategoryItems();
      }
    );
  }

  isPOS() {
    // const { user } = this.state
    // let isPos = false
    // if (!!user && user.roleDescription === "POS") {
    //   isPos = true
    // }
    // return isPos
    return authApi.isPosUser();
  }

  fetchCategories = async () => {
    const { restaurantId } = this.state;
    const categories = await categoryApi.getCategoriesByRestaurant(
      restaurantId
    );

    this.setState({
      categories: categories,
    });
  };

  fetchCategoryItems = async () => {
    const { categoryId, categories } = this.state;
    const itemsPerCategory = await categoryApi.getItemsPerCategory(
      categoryId ? categoryId : categories[0].categoryId
    );

    let categoryName = "";
    categories.forEach((item) => {
      if (categoryId == item.categoryId) {
        categoryName = item.name;
      }
    });

    this.setState({
      itemsPerCategory: itemsPerCategory,
      categoryId: categoryId ? categoryId : categories[0].categoryId,
      categoryName: categoryName,
      isLoading: false,
    });
  };

  renderCategories() {
    const { categories } = this.state;

    return categories.map((item, i) => {
      return (
        <CategoryItem
          key={i}
          name={item.name}
          thumbnailUrl={item.thumbnailUrl}
          restaurantId={item.restaurantId}
          categoryId={item.categoryId}
          bgColorCode={item.bgColorCode}
        />
      );
    });
  }

  render() {
    const { itemsPerCategory, categoryName, isLoading } = this.state;
    return (
      <div className="container">
        <div className="section">
          <div className="top">
            <TopBar />
          </div>
          <div className="child-1">
            <div className="row-category">{this.renderCategories()}</div>
            <div className="row-1">
              <div className="left">
                <div className="title">{categoryName}</div>
                <div className="subtitle"></div>
              </div>
              <div className="right">
                {this.isPOS() || <div className="back">
                  <BackButton text="BACK TO HOME" link="/order-page-one" />
                </div>}

              </div>
            </div>
            {isLoading ? (
              <LinearProgress />
            ) : (
              <div>
                {itemsPerCategory.length != 0 ? (
                  <div className="row-2">
                    {itemsPerCategory.map((item, i) => {
                     
                      return MainUtils.isPosOnlyProduct(item.productName)?(
                        <MediumItemCard
                          key={i}
                          productId={item.productId}
                          productName={item.productName}
                          productDescription={item.Description}
                          productThumbnailURL={item.productThumbnailURL}
                          price={item.price}
                        />
                      ):null
                    })}
                  </div>
                ) : (
                  <div className="empty-category">
                    <div className="img">
                      <FastfoodIcon className="foodIcon" />
                    </div>
                    Currently this Category is empty
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="footer">
            <TermsBar />
          </div>
        </div>
        <div className="side-menu-container">
          <SideMenu />
        </div>
      </div>
    );
  }
}
