import React, { PureComponent } from 'react'
import {
     Dialog, 
     DialogContent, 
     DialogContentText,
     Grid,
     Typography,
} from '@material-ui/core';
import masterApi from '../../../../../core/api/master-api/master-api';
import exportIcon from "../../../img/external-link.svg";
import style from "../Sidebar.module.scss";

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Switch from '@material-ui/core/Switch';
import Restaurant from '@material-ui/icons/Restaurant';
import imgLib from '../../../../../core/lib/image';
import restaurantApi from '../../../../../core/api/restaurant-api/restaurant-api';

export default class KitchenStatus extends PureComponent {
    state={
        dialogOpen:false,
        restaurants:[],
        loading:false
    }

    componentDidMount(){
        // console.log("Hello")
        this.fetchRestaurants();
    }
    async fetchRestaurants(){
        const restaurants = await masterApi.getRestaurants();
        // console.log("???",restaurants)
        this.setState({
            restaurants:restaurants,
            loading:false
        })
    }

    toggleDialogOpen =()=>{
        // console.log("Handle Kitchen Status");
        this.fetchRestaurants();
        this.setState({dialogOpen:!this.state.dialogOpen})
    }

    handleChange = async(event) => {
        // console.log(event.target.name, event.target.checked);
        this.setState({loading:true})
        await restaurantApi.setRestaurantHoldState(event.target.name, event.target.checked);
        this.fetchRestaurants();
        // this.setState({ ...state, [event.target.name]: event.target.checked });
    };
    updateHoldState = async () => {
      
    }

     listItemRenderer = (restaurant) =>(
        <ListItem key={restaurant.id}>
            <ListItemIcon>
            <img src={imgLib.getImageSrcByBase64(restaurant.icon)} width={50}/>
            </ListItemIcon>
            <ListItemText id="switch-list-label-wifi" primary={restaurant.name} />
            <Typography component="div">
                <Grid component="label" container alignItems="center" spacing={1}>
                <Grid item>Close</Grid>
                <Grid item>
                    <Switch
                        edge="end"
                        onChange={this.handleChange}
                        checked={restaurant.isAllowOrder}
                        inputProps={{
                            'aria-labelledby': 'switch-list-label-wifi',
                        }}
                        name={restaurant.id}
                        // disabled={this.state.loading}
                    />
                </Grid>
                <Grid item>Open</Grid>
                </Grid>
            </Typography>

        </ListItem>
    )
    render() {
        const {
            dialogOpen,
            restaurants,
            loading
        } = this.state;
        // console.log(restaurants)
        return (
            <div>
                <div className={style.navItem} onClick={this.toggleDialogOpen}>
                <Restaurant style={{marginRight: "20px",height: "25px"}}/> Kitchen Status
                    
                </div>
                <Dialog
                    open={dialogOpen}
                    fullWidth
                    onClose={this.toggleDialogOpen}
                >
                        <DialogContent>
                                <DialogContentText>Kitchen Status</DialogContentText>
                                <List
                                    sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                                    subheader={loading && <ListSubheader style={{textAlign:'center'}}>Updating kitchen status please wait.....</ListSubheader>}
                                    >
                                    <div>
                                        
                                        {
                                            restaurants.map((res)=>{
                                                return this.listItemRenderer(res)
                                            })
                                            
                                        }
                                    </div>

                                </List>
                        </DialogContent>
                    
                </Dialog>
            </div>
        )
    }
}
