import React, { Component } from "react";
import "./OrderItem.scss";
import SideMenu from "../../shared/SideMenu/SideMenu";
import TopBar from "../../shared/TopBar/TopBar";
import SimpleButton from "../../shared/SimpleButton/SimpleButton";
import BackButton from "../../shared/BackButton/BackButton";
import TermsBar from "../../shared/TermsBar/TermsBar";

import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from '@material-ui/core/DialogTitle';
import Select from "@material-ui/core/Select";
import InputAdornment from "@material-ui/core/InputAdornment";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import IconButton from "@material-ui/core/IconButton";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import { LinearProgress } from "@material-ui/core";

import productApi from "../../core/api/product-api/product-api";
import cartApi from "../../core/api/cart-api/cart-api";
import masterApi from "../../core/api/master-api/master-api";
import authApi from "../../core/api/auth-api/auth-api";
import { userRoles } from "../../core/contants";
import { AccountApi } from "../../core/api";
import { showConfrimBox } from "../../shared/ComfirmAlertBox/ConfirmAlertBox";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

 class OrderItem extends Component {
    onHold = false;

    state = {
        isLoading: true,
        isHold: false,
        isHoldMsg:'',
        product: [],
        sizes: [],
        ingredients: [],
        preferences: [],
        selectedSize: "",
        price: 0,
        selectedIngredients: [],
        selectedPreferences: [],
        disabledIngredients: [],
    };

    componentDidMount = () => {
        this.fetchProduct();
    };

    fetchProduct = async () => {
        this.setState({
            isLoading: true,
        });

        const productId = await this.props.match.params.productId;
        const product = await productApi.getProduct(productId);

        let selectedPreferences = [];
        product.preferences.forEach((item) => {
            item.options.forEach((i) => {
                if (i.isDefault === true) {
                    selectedPreferences.push(i.id);
                }
            });
        });

        let selectedIngredients = [];
        let disabledIngredients = [];
        product.ingredients.forEach((item) => {
            if (item.isStandard) {
                selectedIngredients.push(item.ingredientDescription);
            }
            if (!item.canModify) {
                disabledIngredients.push(item.ingredientDescription);
            }
        });

        this.setState({
            product: product,
            productId: productId,
            sizes: product.sizes,
            ingredients: product.ingredients,
            preferences: product.preferences,
            selectedPreferences: selectedPreferences,
            selectedSize: product.defaultProductVariantSizeId,
            selectedIngredients: selectedIngredients,
            disabledIngredients: disabledIngredients,
            quantity: 1,
            isLoading: false,
        });

        this.getPrice();
    };

    fetchHoldState = async () => {
        const { product } = this.state
        const restaurants = await masterApi.getRestaurants();
        restaurants.forEach(restaurant => {
            if (product.restId === restaurant.id) {
                this.setState({ 
                    isHold: !restaurant.isAllowOrder,
                    isHoldMsg:`${restaurant.name} is closed, please check later.`
                })
            }
        });
    }

    verifyAge = async() =>{
        showConfrimBox({
            title:"Please confirm that you are aged 18 or over",
            description:"",
            okButtonText:'Yes',
            cancelButtonText:'No',
            onOkClick:this.handleAddToCart 
        })
    }

    handleAddToCart = async () => {

        ////removed because it may cause issue in guest user and skip login user
        // AccountApi.isSessionValid().then((res)=>{
        // }).catch((err)=>{
        //     alert("Your Session has Expired.")
        //     authApi.logout();
        //     return
        // })
        const user =  authApi.getUser();
        if(user.role === userRoles.Customer){
            await this.fetchHoldState();
        }
        const { isHold } = this.state;
        if (!isHold) {
            this.handleSubmit();
            // this.isHold = true;
        } else {
            setTimeout(() => {
                this.setState({isHold:false});
            }, 3000)
        }
    }

    handleAddClick = () => {
        const { quantity } = this.state;

        this.setState({
            quantity: quantity + 1,
        });
    };

    handleMinusClick = () => {
        const { quantity } = this.state;
        if (quantity > 1) {
            this.setState({
                quantity: quantity - 1,
            });
        }
    };

    getPrice = () => {
        const {
            selectedIngredients,
            selectedSize,
            ingredients,
            sizes,
        } = this.state;

        let price = 0;

        sizes.forEach((item) => {
            if (item.productSizeVarientId === selectedSize) {
                price = item.price;
            }
        });

        selectedIngredients.forEach((selected) => {
            ingredients.forEach((item) => {
                if (item.ingredientDescription === selected && !item.isStandard) {
                    item.ingredientCosts.forEach((i) => {
                        if (i.productSizeVariantId === selectedSize) {
                            price = price + i.price;
                        }
                    });
                }
            });
        });

        this.setState({
            price: price,
        });
    };

    handleSizeChange = (event) => {
        event.persist();

        const { sizes } = this.state;
        sizes.forEach((item) => {
            if (item.productSizeVarientId * 10 === event.target.value) {
                this.setState(
                    {
                        selectedSize: event.target.value / 10,
                    },
                    () => {
                        this.getPrice();
                    }
                );
            }
        });
    };

    handleIngredientChange = (event) => {
        event.persist();

        let { selectedIngredients } = this.state;
        const { disabledIngredients } = this.state;

        let newSelect = event.target.value;
        let difference = "";
        if (selectedIngredients.length > newSelect.length) {
            difference = selectedIngredients.filter((x) => !newSelect.includes(x));
            difference = difference[0];

            if (disabledIngredients.indexOf(difference) === -1) {
                const index = selectedIngredients.indexOf(difference);
                selectedIngredients.splice(index, 1);
            }
        } else {
            difference = newSelect.filter((x) => !selectedIngredients.includes(x));
            difference = difference[0];

            if (disabledIngredients.indexOf(difference) === -1) {
                selectedIngredients.push(difference);
            }
        }
        this.setState(
            {
                selectedIngredients: selectedIngredients,
            },
            () => {
                this.getPrice();
            }
        );
    };

    handlePreferencesChange = (event) => {
        event.persist();

        const { selectedPreferences } = this.state;
        let newSelected = selectedPreferences;
        newSelected[parseInt(event.target.name)] = event.target.value / 10;
        this.setState({
            selectedPreferences: newSelected,
        });
    };

    handleSubmit = async () => {
        const {
            product,
            selectedSize,
            selectedIngredients,
            quantity,
            price,
            preferences,
            selectedPreferences,
            ingredients,
        } = this.state;

        let productPreferenceOptions = [];

        preferences.map((item, i) => {
            let obj = {
                productPreferenceOptionId: selectedPreferences[i],
                productPreferenceId: item.productPreferenceId,
            };
            productPreferenceOptions.push(obj);
        });

        let selectedIngredientIds = [];

        selectedIngredients.forEach((item) => {
            ingredients.forEach((i) => {
                if (i.ingredientDescription === item) {
                    selectedIngredientIds.push(i.ingredientId);
                }
            });
        });

        let cartItem = {
            quantity: quantity,
            productId: product.productId,
            productSizeVarientId: selectedSize,
            selectedIngredientIds: selectedIngredientIds,
            productPreferenceOptions: productPreferenceOptions,
            unitPrice: price,
            cartItemId: "",
        };

        try {
            await cartApi.addItem(cartItem);
            this.props.history.goBack();
            this.fetchProduct();

        } catch(err) {
            showConfrimBox({
                title:"Error",
                description:(err?.response?.data)? err?.response?.data: "Internal server error",
                onCancelClick:false
            });
        }
    };

    render() {
        const {
            product,
            sizes,
            ingredients,
            price,
            quantity,
            preferences,
            isLoading,
            isHold,
            isHoldMsg,
            selectedSize,
            selectedIngredients,
            selectedPreferences,
            disabledIngredients,
        } = this.state;
        const {walkInStatusButton} = this.props;
        return (
            <div className="order-item-container">
                <div className="section">
                    <div className="top">
                        <TopBar />
                    </div>
                    {isLoading ? (
                        <LinearProgress />
                    ) : (
                        <div className="child">
                            <div className="left">
                                <div className="title">{product.name}</div>
                                <div className="subtitle">{product.description}</div>
                                <div className="form">
                                    <div className="item">
                                        <div className="item-name">Size</div>
                                        <div className="item-action">
                                            <FormControl variant="outlined">
                                                <Select
                                                    fullWidth
                                                    value={selectedSize * 10}
                                                    onChange={this.handleSizeChange}
                                                    labelId="demo-simple-select-outlined-label"
                                                    id="demo-simple-select-outlined"
                                                >
                                                    {sizes.map((item, i) => (
                                                        <MenuItem
                                                            value={item.productSizeVarientId * 10}
                                                            key={i}
                                                        >
                                                            {item.description}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>

                                    {preferences.map((item, index) => (
                                        <div className="item" key={index}>
                                            <div className="item-name">{item.preference}</div>
                                            <div className="item-action">
                                                <FormControl variant="outlined">
                                                    <Select
                                                        fullWidth
                                                        value={selectedPreferences[index] * 10}
                                                        onChange={this.handlePreferencesChange}
                                                        labelId="demo-simple-select-outlined-label"
                                                        name={index.toString()}
                                                    >
                                                        {item.options.map((op, i) => (
                                                            <MenuItem key={i} value={op.id * 10}>
                                                                {op.description}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </div>
                                    ))}
                                    {ingredients.length && !product.noIngredients  ? (
                                        <div className="item">
                                            <div className="item-name">
                                                <div>Extras</div>
                                                <div style={{fontSize: 'smaller'}}>(click to edit)</div>
                                            </div>
                                            <div className="item-action">
                                                <FormControl variant="outlined">
                                                    <Select
                                                        fullWidth
                                                        multiple
                                                        value={selectedIngredients}
                                                        renderValue={(selected) => selected.join(", ")}
                                                        onChange={this.handleIngredientChange}
                                                    >
                                                        {ingredients.map((item, i) => (
                                                            <MenuItem
                                                                key={item.ingredientDescription}
                                                                value={item.ingredientDescription}
                                                            >
                                                                <Checkbox
                                                                    checked={
                                                                        selectedIngredients.indexOf(
                                                                            item.ingredientDescription
                                                                        ) > -1
                                                                    }
                                                                    disabled={
                                                                        disabledIngredients.indexOf(
                                                                            item.ingredientDescription
                                                                        ) > -1
                                                                    }
                                                                />
                                                                <ListItemText
                                                                    primary={`${item.ingredientDescription} - (£${(item.ingredientCosts.find(item=>item.productSizeVariantId === selectedSize)['price']).toFixed(2)})`}
                                                                />
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </div>
                                    ) : (
                                        <div></div>
                                    )}

                                    <div className="item">
                                        <div className="item-name"></div>
                                        <div className="item-action">
                                            <div className="count">
                                                <TextField
                                                    variant="outlined"
                                                    size="small"
                                                    value={quantity}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <IconButton onClick={this.handleMinusClick}>
                                                                    <RemoveIcon fontSize="small" />
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton onClick={this.handleAddClick}>
                                                                    <AddIcon fontSize="small" />
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="item-name"></div>
                                        <div className="item-action">
                                            <div className="price">
                                                £{(price * quantity).toFixed(2)}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="actions">
                                    <SimpleButton
                                        text="ADD TO ORDER"
                                        onFunction={product.onlyForAdult && !walkInStatusButton?this.verifyAge:this.handleAddToCart}
                                    />
                                </div>
                            </div>
                            <div className="right">
                                <div className="back">
                                    <BackButton text="BACK TO HOME" link="/order-page-one" />
                                </div>
                                <div className="thumb">
                                    <img src={product.thumbnailUrl} alt="thumbnail" />
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="footer">
                        <TermsBar />
                    </div>
                </div>
                <div className="side-menu-container">
                    <SideMenu />
                </div>
                <Dialog open={isHold}>
                    <DialogTitle >Alert</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {isHoldMsg}
                            </DialogContentText>
                    </DialogContent>
                </Dialog>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    const { cart, usePoints, points } = state.cartReducer;
    const { walkInStatusButton, pickUpStatusButton, deliveryStatusButton } = state.orderReducer;
    return {
        cart, usePoints, points, walkInStatusButton,
        pickUpStatusButton,
        deliveryStatusButton,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(OrderItem));