import httpApi from "../http-api/http-api";
import { AuthApi } from "..";

class CommonApi {

    //Only used for POS
    posRestaurantId = ()=>{
        const user = AuthApi.user;
        return user.restaurantId?user.restaurantId:null
    }

    auditLog = async ({ cartId,orderId,message})=>{
        return httpApi.sendPostRequest(`/Audit/Log`,{ cartId,orderId,message})
    }
}

export default new CommonApi()