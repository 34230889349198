import React, { Component } from 'react';
import style from './CSOrderHistory.module.scss';
import customerOrderApi from '../../../core/api/customer-order-api/customer-order.api';
import authApi from '../../../core/api/auth-api/auth-api';
import time from '../../../core/lib/time';

import { Link } from 'react-router-dom'
import Button from "@material-ui/core/Button";
import Page from '../shared/Page/Page';
import DateRange from '@material-ui/icons/DateRange';
import backIcon from '../img/back.svg';

import chevronRightIcon from '../img/chevron-right.svg';
import { convertToLast3digit } from '../../../utils/MainUtils';
import moment from 'moment';
import { DateRangePicker } from './Components';

export default class CSOrderHistory extends Component {
    state = {
        orderHistory: [],
        startDate:moment().format(),
        endDate:moment().format(),
        openDatePicker:true
    }

    componentDidMount() {
        // this.fetchOrderHistory();
    }

    isPermited = async () => {
        const user = await authApi.getCSUser()
        if (user.roleDescription !== "Customer Service") {
            window.location.href = "/customer-support";
        }
    }

    async fetchOrderHistory() {
        const {startDate,endDate} = this.state;
        this.isPermited();


        const startDateIso = new Date(startDate).toISOString();
        const endDateIso = new Date(endDate).toISOString();

        console.log(startDateIso,endDateIso)
        const sentData = {
            "fromDate": startDateIso,
            "toDate": endDateIso,
        }

        const orderHistory = await customerOrderApi.getOrderHistory(null,sentData);
        this.setState({
            orderHistory,
            openDatePicker:false
        });
    }

    routeOrderDetails = (orderId) => {
        this.props.history.push(`/customer-support/order-details/${orderId}`)
    }


    renderOrders() {
        const { orderHistory } = this.state;
        // console.log(orderHistory)
        return orderHistory.map((i, j) => {
            return (
                <tr key={j} className={i.status === 5?style.deletedOrder:{}}>
                    <td> {convertToLast3digit(i.orderNo)} </td>
                    <td> {time.timestampToDateString(new Date(i.orderPlacedOn))}  </td>
                    <td> {i.customerName} </td>
                    <td> {i.orderCost.toFixed(2)} </td>
                    <td> {i.deliveryZone} </td>
                    <td> {i.driverName || '-'} </td>
                    <td> {time.timestampToDateString(new Date(i.requestedTime))} </td>
                    <td> {i.orderTypeDescription} </td>
                    <td className={style.actions}>
                        <Button
                            variant="contained"
                            onClick={() => { this.routeOrderDetails(i.orderId) }}
                            color="primary">
                            <img src={chevronRightIcon} alt="" />
                        </Button>
                    </td>
                </tr>
            )
        })
    }

    render() {
        const {startDate,endDate,openDatePicker} = this.state;
        return (
            <Page>

                <div className={style.container}>
                    <div className={style.header}>
                        <img src={backIcon} alt="" />
                    </div>

                    <div className={style.content}>
                        <Button
                            variant="contained"
                            onClick={()=>this.setState({openDatePicker:true})}
                            color="primary"
                            style={{marginBottom:'1rem'}}
                            startIcon={<DateRange />}
                        >
                            Change Date Range
                        </Button>

                        <div className={style.title}>
                            ORDER HISTORY
                        </div>

                        <div>
                            <table>
                                <thead>
                                    <tr>
                                        <th># Order</th>
                                        <th> Order Time </th>
                                        <th> Customer </th>
                                        <th> Cost </th>
                                        <th> Delivery Zone </th>
                                        <th> Driver Name </th>
                                        <th> Requested Time </th>
                                        <th> Delivery or Collection </th>
                                        <th></th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {this.renderOrders()}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <DateRangePicker 
                    setStartDate = {(value)=>this.setState({startDate:value})}
                    setEndDate = {(value)=>this.setState({endDate:value})}
                    startDate = {startDate}
                    endDate = {endDate}
                    openDatePicker = {openDatePicker}
                    onSubmit = {()=>this.fetchOrderHistory()}
                />
            </Page>
        )
    }
}
