import React, { Component } from 'react';
import style from './PaymentInformation.module.scss';
import orderApi from '../../core/api/order-api/order-api';
import Button from "@material-ui/core/Button";
import { showConfrimBox } from '../../shared/ComfirmAlertBox/ConfirmAlertBox';
import { CommonApi } from '../../core/api';

export default class PaylandsPaymentStatus extends Component {
    state = {
        paymentUrl:null,
        isLoading:true,
        showBackButton:false,
        orderId:null,
        isMobile:false //used to detect is site working in webview
    }


    componentDidMount() {
        if(window.parent){
            window.parent.postMessage(
                {
                  type: 'payment',
                  message: this.props.match.params.status,
                },
                '*'
              )
        }
    }
    render(){
        return(<></>)
    }
}
