import moment from 'moment';
import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { convertToLast3digit } from '../../utils/MainUtils';
import './Epos.scss'

const DriverPrint = forwardRef((props,ref) => {
    const componentRef = useRef();
    const [dblPrint,setDblPrint] = useState(false);
    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
    });
  
    useImperativeHandle(ref, () => ({

        Print(isDblPrint) {
            setDblPrint(isDblPrint);
            setTimeout(()=>handlePrint(),500)
        }
    
      }));

    return (
      <div>
           <div style={{ display: "none" }}>
            <ComponentToPrint ref={componentRef} data={props.data} isDblPrint={dblPrint} />
           </div>
        {/* <button onClick={handlePrint}>Print this out!</button> */}
      </div>
    );
  });

export default DriverPrint

export class ComponentToPrint extends React.PureComponent {
    render() {
        const {data} = this.props
        if(!data){
            return <div></div>
        }
        const printHtml =()=>(
            <div style={{
                        padding:'2%',
                        display:'flex',
                        justifyContent:'center',
                        flexDirection:'column',
                        paddingLeft:'7%'
                    }}>
                <h2 style={{margin:0}}>{data.orderTypeDescription}</h2>
                <h3 style={{margin:0}}>{ data.userName}</h3>
                <h3 style={{margin:0}}>{data.deliveryAddress}</h3>
                <h3 style={{margin:0}}>{data.userMobile}</h3>
                <h4 style={{margin:0}}>Order No: {convertToLast3digit(data.orderNumber)}</h4>

                <table>
                    <tbody>
                        <tr>
                            <td colSpan="3"> <hr /></td>
                        </tr>
                        {
                            data.cartItems.length &&
                            data.cartItems.map((p,i)=>(
                                <>
                                    <tr>
                                        <th>{p.quantity}</th>
                                        <th>x {p.productName}</th>
                                        <td>£{p.totalPrice.toFixed(2)}</td>
                                    </tr>
                                    <tr key={i}>
                                    <td></td>
                                    <td colSpan="2">
                                        Size : {p.productSizeVarientName}
                                    </td>
                                </tr>
                                {
                                    p.productPreferenceOptions.map((prf,i)=>(
                                        <tr key={i}>
                                            <td></td>
                                            <td colSpan="2">
                                                {prf.productPreference} : {prf.productPreferenceOption}
                                            </td>
                                        </tr>
                                    ))
                                
                                }
                                {
                                    !!p.addedProductIngredients && !!p.addedProductIngredients.length &&
                                    <tr>
                                        <td></td>
                                        <td colSpan="2">Added Ingredients :</td>
                                    </tr>
                                }
                                {
                                    p.addedProductIngredients.map((ing,i)=>(
                                        <tr key={i}>
                                            <td></td>
                                            <td colSpan="2"><span>&nbsp;&nbsp;</span> + {ing.ingredient}</td>
                                        </tr>
                                    ))
                                
                                }
                                {
                                    !!p.deletedProductIngredients && !!p.deletedProductIngredients.length &&
                                    <tr>
                                        <td></td>
                                        <td colSpan="2">Removed Ingredients :</td>
                                    </tr>
                                }
                                {
                                    p.deletedProductIngredients.map((ing,i)=>(
                                        <tr key={i}>
                                            <td></td>
                                            <td colSpan="2"><span>&nbsp;&nbsp;</span>  - {ing.ingredient}</td>
                                        </tr>
                                    ))
                                
                                }
                                </>
                            ))
                        }
                        <tr>
                            <td colSpan="3"> <hr /></td>
                        </tr>
                        <tr>
                            <td colSpan="2">Subtotal</td>
                            <td>£{data.subTotal.toFixed(2)}</td>
                        </tr>
                        <tr>
                            <td colSpan="2">Delivery</td>
                            <td>£{data.deliveryCharge.toFixed(2)}</td>
                        </tr>
                        <tr>
                            <td colSpan="2">Total</td>
                            <td>£{data.totalPrice.toFixed(2)}</td>
                        </tr>
                        <tr>
                            <td colSpan="3"> <hr /></td>
                        </tr>
                        <tr>
                            <td colSpan="3"> Paid by {data.paymentMethod === 0?'Cash':'Card'}</td>
                        </tr>
                        <tr>
                            <td colSpan="3">{moment().format("DD.MM.YYYY HH:mm") }</td>
                        </tr>
                    </tbody>
                </table>
                {
                    !!data.driverName &&
                    <p><b>Driver:</b> {data.driverName}</p>
                }
                {
                    !!data.specialNotes &&
                    <p><b>Special Note :</b> {data.specialNotes}</p>
                }
            </div>
        );
        // if(isDblPrint){
        //     return (
        //         <div>
        //             {
        //                 printHtml()
        //             }
        //                 <hr />
        //             {
        //                 printHtml()
        //             }
        //         </div>
        //     )
        // }else{
            return printHtml()
        // }

    }
  }

 