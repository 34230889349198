import React, { Component } from 'react';
import Page from '../shared/Page/Page';
import style from './Promotions.module.scss';
import backIcon from '../img/back.svg';
import { Button, IconButton } from '@material-ui/core';
import { PromotionApi } from '../../../core/api';
import time from '../../../core/lib/time';
import { PromotionDiscountType } from '../shared/Utils/common';
import EditIcon from '@material-ui/icons/Edit';
import NotificationIcon from '@material-ui/icons/NotificationImportant';
import DeleteIcon from '@material-ui/icons/Delete';
import AddEditPromotion from './components/AddEditComponent';
import masterApi from '../../../core/api/master-api/master-api';
import { showToast } from '../../../shared/ToastAlert/ToastAlert';
import { showConfrimBox } from '../../../shared/ComfirmAlertBox/ConfirmAlertBox';

export default class Promotions extends Component {
    state = {
        allPromotions:[],
        openAddEditModal:false,
        editData:null,
        restaurants:[]
    }

    componentDidMount(){
        this.getData();
    }

    getData(){
        this.getAllPromotions();
        this.fetchRestaurants();

    }

    getAllPromotions = () =>{
        PromotionApi.getAllPromotions()
        .then((data)=>{
            this.setState({allPromotions:data && data?.length?data:[]})
        });
    }
    
    async fetchRestaurants(){
        let restaurants = await masterApi.getRestaurants();
        if(restaurants && restaurants?.length){
            restaurants = restaurants.map((v)=>({value:v['id'],label:v['name']}))
        }
        // console.log("resta",restaurants);
        this.setState({restaurants})
    }

    onAddPromotion = () =>{
        this.setState({
            editData:null
        },()=>{
            this.setState({openAddEditModal:true})
        })
    }

    onEditPromotion = (data) =>{
        this.setState({
            editData:data
        },()=>{
            this.setState({openAddEditModal:true})
        })
    }

    onDeletePromotion =(data)=>{
        showConfrimBox({
            title:"Are you sure?",
            description:"You want delete this promotion.",
            okButtonText:'YES',
            onOkClick:()=>{
                PromotionApi.deletePromotion(data['id'])
                .then(()=>{
                    showToast({title:"Promotion deleted."})
                    this.getAllPromotions();
                })
                .catch(()=>{
                    showToast({title:'Failed to delete promotion, please try again.',type:'error'})
                })
            }
        })
    }

    onCancelModal = (bool)=>{
        if(bool){
        this.getAllPromotions();
        }
        this.setState({openAddEditModal:false})
    }


    renderOrders(){
        const {allPromotions} = this.state;
        return allPromotions.map((v,i)=>(
            <tr key={v['id']}>
                <td>{v['name']}</td>
                <td>{v['restaurantName']}</td>
                <td>{time.timestampToDateString(new Date(v['startDate']))}</td>
                <td>{time.timestampToDateString(new Date(v['endDate']))}</td>
                <td className={style.alignRight}>{v['discountType'] === PromotionDiscountType.amount?'£':''}{v['discount']}{v['discountType'] === PromotionDiscountType.percentage?'%':''}</td>
                <td className={style.actions}>
                        <Button
                            variant="contained"
                            color="primary"
                            className={style.btn}
                            style={{
                                 backgroundColor: v['active']? "#32bd38" : "#d9211d",
                            }}
                        >
                            {v['active']?"Active":"Inactive"}
                        </Button>
                </td>
                <td className={style.alignRight}>£{v['minimumAmount']}</td>
                <td>
                <IconButton 
                    aria-label="edit" 
                    color='primary'
                    onClick={()=>this.onEditPromotion(v)}
                >
                    <EditIcon />
                </IconButton>
             
                </td>
                <td>
                    <IconButton 
                        aria-label="broadcast" 
                        color='primary'
                        onClick={()=>this.sendBroadcastMessage(v)}
                    >
                        <NotificationIcon />
                    </IconButton>
                </td>
                <td>
                <IconButton 
                    aria-label="delete" 
                    color='primary'
                    onClick={()=>this.onDeletePromotion(v)}
                >
                    <DeleteIcon />
                </IconButton>
                </td>
            </tr>
        ))
    }


    sendBroadcastMessage = (data) =>{
        console.log("???");

        const sendPromotion =()=>{
             PromotionApi.sendBroadcastToAllUser(data['id'])
             .then(()=>{
                showToast({title:"Message sent successfully!"})
             })
        }
        showConfrimBox({
            title:`Are you sure you want to broadcast promotion to all users?`,
            description:data['brodcastMessage'],
            okButtonText:'Yes',
            cancelButtonText:'No',
            onOkClick:sendPromotion,
        });
    }
    

  render() {
    const {openAddEditModal,editData,restaurants} = this.state;
    return (
      <Page>
            <div className={style.container}>
                    <div className={style.header}>
                        <img src={backIcon} alt="" /> Promotions
                    </div>
                    

                    <div className={style.content}>
                        <Button
                            variant="contained"
                            onClick={()=>this.onAddPromotion()}
                            color="primary"
                            style={{marginBottom:'1rem'}}
                            // startIcon={<DateRange />}
                        >
                            Add Promotion
                        </Button>

                        {/* <div className={style.title}>
                            PROMOTIONS
                        </div> */}

                        <div>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Restaurant</th>
                                        <th>Start Date</th>
                                        <th>End Date</th>
                                        <th>Discount (% / £)</th>
                                        <th>Status</th>
                                        <th>Minimum Amount (£)</th>
                                        <th></th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {this.renderOrders()}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <AddEditPromotion
                    openAddEditModal={openAddEditModal}
                    onCancel = {this.onCancelModal}
                    data={editData}
                    restaurants={restaurants}
                />
      </Page>
    )
  }
}
