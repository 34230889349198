import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import PrintOutlinedIcon from '@material-ui/icons/PrintOutlined';
import { red } from '@material-ui/core/colors';
import HtmlToPrint from '../../../../EPOS/HtmlToPrint';
import CustomAlert from '../../../../../shared/Alert/Alert';
import { convertToLast3digit } from '../../../../../utils/MainUtils';
import restaurantApi from '../../../../../core/api/restaurant-api/restaurant-api';

const useStyles = makeStyles({
  avatar: {
    backgroundColor: red[100],
    color: red[600],
  },
});

function SimpleDialog(props) {
  const classes = useStyles();
  const { onClose, selectedValue, open,OrderCookingList } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">Select order number to print receipt</DialogTitle>
      <List>
        {OrderCookingList.map((order) => (
          <ListItem button onClick={() => handleListItemClick(order)} key={order.orderNumber}>
            <ListItemAvatar>
              <Avatar className={classes.avatar}>
                <PrintOutlinedIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={`#${convertToLast3digit(order.orderNumber)}`} />
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

export default function RecepitList(props) {
  const [open, setOpen] = React.useState(false);
  const [OrderCookingList, setOrderCookingList] = React.useState([]);
  const [selectedOrder, setSelectedOrder] = React.useState(null);
  const printRef = React.useRef();
  const [printOpen,setPrintOpen] = React.useState(false);

  React.useEffect(()=>{
     setOrderList();
  },[])

  const setOrderList = async () =>{
    const {orderHistory} = props;
    // let orderHistory = await restaurantApi.getOrderHistory();
    const kitchenOrderHistory = orderHistory.filter((i) => {
      return i.orderStatus === 2;
    })
    setOrderCookingList(kitchenOrderHistory);
    // setSelectedOrder(kitchenOrderHistory[0])
    return
  }

  const handleClickOpen = async() => {
    await setOrderList();
    setOpen(true);
  };

  const handleClose = (order) => {
    // console.log(order)
    setOpen(false);
    if(!order)
      return
    setSelectedOrder(order);
    setPrintOpen(true);
    
  };

  const printTicket = async() =>{
    const FullOrderData = await restaurantApi.getFullKitchenOrderData(selectedOrder.orderId);
    setSelectedOrder(FullOrderData);
 
    setTimeout(()=>{
      printRef.current.Print();
    },500)

  }



  return (
    <div>
      {/* <Typography variant="subtitle1">Selected: {selectedValue}</Typography>
      <br /> */}
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        Reprint Receipt
      </Button>
     
      <SimpleDialog 
        open={open} 
        onClose={handleClose} 
        OrderCookingList={OrderCookingList}
      />
     { 
        selectedOrder && selectedOrder.restaurants &&
        <HtmlToPrint ref={printRef} data={selectedOrder} />
     }
      {
        selectedOrder &&
        <CustomAlert 
          open={printOpen}
          title="Alert!"
          description={`Do you want to print order #${selectedOrder.orderNumber}?`}
          okButtonText="Print"
          onOkClick={()=>{
            setPrintOpen(false);
            printTicket();
          }}
          onCancelClick={()=>{setPrintOpen(false)}}
        />
      }
    </div>
  );
}
