import React, { Component } from 'react';
import style from './Page.module.scss';

export default class Page extends Component {
    render() {
        return (
            <div className={style.page}>
                <div className={style.pageContent}>
                    {this.props.children}
                </div>
            </div>
        )
    }
}
