import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./KitchenOrderList.scss";

import Paper from "@material-ui/core/Paper";
import Grid from '@material-ui/core/Grid';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import DashboardIcon from '@material-ui/icons/Dashboard';

import TimerOutlinedIcon from "@material-ui/icons/TimerOutlined";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import ListAltOutlinedIcon from "@material-ui/icons/ListAltOutlined";

import restaurantApi from "../../../core/api/restaurant-api/restaurant-api"

export default class KitchenOrderList extends Component {
  state = {
    orderHistory: [],
    restaurant: '',
    currentState: ["NEW", "ACK", "PREP", "READY"],
    stateCount: [],
    currentTime: '',
    summery: [],
    tempSum: [],
    timer: null,
    updateFetch: null
  };

  constructor() {
    super();

    if (!restaurantApi.isLoggedIn) {
      window.location.href = '/kitchen/';
    }
  }

  componentDidMount = () => {
    this.getOrderHistory()
    this.updateTime()
    this.updateFetch()
  }

  componentWillUnmount() {
    clearInterval(this.state.timer)
    clearInterval(this.state.updateFetch)
  }

  routeDashboard = () => {
    // this.props.history.push('/kitchen/dashboard');
    this.props.history.push('/kitchenDashboard');
  }

  updateFetch = () => {
    let updateFetch = setInterval(this.getOrderHistory, 10000);
    this.setState({
      updateFetch: updateFetch
    })
  }

  getOrderHistory = async () => {
    let orderHistory = await restaurantApi.getOrderHistory(1);
    let summery = []

    orderHistory.map(async (i) => {
      let orderItems = await restaurantApi.getRestaurantOrderDetails(i.orderId);
      let itemList = []
      orderItems.map((item) => {
        let qty = item.quantity
        qty = qty.toString()
        let itemText = item.productName + " x " + qty
        itemList.push(itemText)
      })
      summery.push(itemList)
    })

    this.setState({
      orderHistory: orderHistory,
      restaurant: orderHistory[0].restaurant,
      summery: summery
    })

    this.tempSum()
    this.setStateCount()
  }

  tempSum = () => {
    const { summery } = this.state
    let temp = []
    summery.map((item) => {
      let text = ""
      item.map((i) => {
        text = text + i + ","
      })
      temp.push(text.slice(0, -1))
    })

    this.setState({
      tempSum: temp
    })

  }

  updateTime = () => {
    let timer = setInterval(this.getTime, 1000);
    this.setState({
      timer: timer
    })
  }

  getTime = () => {
    let d = new Date()
    let currentTime = ''
    let h = d.getHours();
    let m = d.getMinutes();
    let s = d.getSeconds();
    currentTime = this.formatTime(h, m, s)

    this.setState({
      currentTime: currentTime
    })

  }

  setStateCount = () => {
    const { orderHistory } = this.state
    let count = [0, 0, 0, 0]

    orderHistory.map((i) => (
      count[i.orderStatus] = count[i.orderStatus] + 1
    ))

    this.setState({
      stateCount: count
    })
  }


  formatTime = (h, m, s) => {
    let hh = String(h)
    let mm = String(m)
    let ss = String(s)

    if (hh[1] === undefined) {
      hh = "0" + hh
    }

    if (mm[1] === undefined) {
      mm = "0" + mm
    } if (mm[1] === undefined) {
      mm = "0" + mm
    }

    if (ss[1] === undefined) {
      ss = "0" + ss
    }

    let res = hh + ":" + mm + ":" + ss
    return res
  }

  formatReceived = (t) => {

    let rec = new Date(Date.parse(t))
    let recTime = ''
    let h = rec.getHours();
    let m = rec.getMinutes();
    let s = rec.getSeconds();

    recTime = this.formatTime(h, m, s)
    return recTime
  }

  formatPrepTime = (start, mm) => {
    let rec = new Date(Date.parse(start))
    rec.setMinutes(rec.getMinutes() + mm)
    let ss = (mm - Math.floor(mm)) * 60
    rec.setSeconds(rec.getSeconds() + ss)
    let recTime = ''
    let h = rec.getHours();
    let m = rec.getMinutes();
    let s = rec.getSeconds();

    recTime = this.formatTime(h, m, s)
    return recTime
  }

  handleLogout() {
    window.location.href = '/kitchen';
  }

  render() {
    const { orderHistory, restaurant, currentState, currentTime, stateCount, summery } = this.state

    return (
      <div className="kitchen-order-list-container">
        <div className="section">
          <div className="heading">
            <div className="kitchen-name">
              {restaurant}
            </div>
            <div className="time">
              {currentTime}
            </div>

            <Button
              variant="contained"
              onClick={this.routeDashboard}
              color="primary"
              endIcon={<DashboardIcon />}
            ></Button>

            <Button
              variant="contained"
              onClick={this.handleLogout}
              color="primary"
              endIcon={<ExitToAppIcon />}
            >
              Logout
            </Button>
          </div>
          <div className='status'>
            <Grid container
              spacing={3}>
              <Grid item xs>
                <div className="state" style={{ backgroundColor: "#EC7063" }}>
                  <div className='name'>
                    New
                  </div>
                  <div className='count'>
                    {stateCount[0]}
                  </div>
                </div>
              </Grid>
              <Grid item xs>
                <div className="state" style={{ backgroundColor: "#F8C471" }}>
                  <div className='name'>
                    Acknowledged
                  </div>
                  <div className='count'>
                    {stateCount[1]}
                  </div>
                </div>
              </Grid>
              <Grid item xs>
                <div className="state" style={{ backgroundColor: "#52BE80" }}>
                  <div className='name'>
                    Being Prepared
                  </div>
                  <div className='count'>
                    {stateCount[2]}
                  </div>
                </div>
              </Grid>
              <Grid item xs>
                <div className="state" style={{ backgroundColor: "#3498DB" }}>
                  <div className='name'>
                    Ready
                  </div>
                  <div className='count'>
                    {stateCount[3]}
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
          <div className="table-section">
            <div>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <div className="head-item">
                          <DescriptionOutlinedIcon fontSize="small" />
                          <div className="title">ORDER#</div>
                        </div>
                      </TableCell>
                      <TableCell align="left">
                        <div className="head-item">
                          <CheckCircleOutlineIcon fontSize="small" />
                          <div className="title">STATUS</div>
                        </div>
                      </TableCell>
                      <TableCell align="left">
                        <div className="head-item">
                          <TimerOutlinedIcon fontSize="small" />
                          <div className="title">TIME REC'D</div>
                        </div>
                      </TableCell>
                      <TableCell align="left">
                        <div className="head-item">
                          <TimerOutlinedIcon fontSize="small" />
                          <div className="title">START PREP AT</div>
                        </div>
                      </TableCell>
                      <TableCell align="left">
                        <div className="head-item">
                          <TimerOutlinedIcon fontSize="small" />
                          <div className="title">DUE</div>
                        </div>
                      </TableCell>
                      <TableCell align="left">
                        <div className="head-item">
                          <TimerOutlinedIcon fontSize="small" />
                          <div className="title">ETA</div>
                        </div>
                      </TableCell>
                      <TableCell align="left">
                        <div className="head-item">
                          <ListAltOutlinedIcon fontSize="small" />
                          <div className="title">ORDER SUMMARY</div>
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orderHistory.map((item, i) => (
                      <TableRow hover className={currentState[item.orderStatus].toLowerCase()} key={i}>
                        <TableCell align="left"><Link to={`/kitchen/order-item/${item.restaurantId}/${item.restaurantOrderId}`}><div>{item.orderNumber}</div></Link></TableCell>
                        <TableCell align="left"><Link to={`/kitchen/order-item/${item.restaurantId}/${item.restaurantOrderId}`}><div>{item.orderStatusDescription}</div></Link></TableCell>
                        <TableCell align="left"><Link to={`/kitchen/order-item/${item.restaurantId}/${item.restaurantOrderId}`}><div>{this.formatReceived(item.timeSincePlaced)}</div></Link></TableCell>
                        <TableCell align="left"><Link to={`/kitchen/order-item/${item.restaurantId}/${item.restaurantOrderId}`}><div>{this.formatPrepTime(item.timeSincePlaced, item.orderETATime)}</div></Link></TableCell>
                        <TableCell align="left"><Link to={`/kitchen/order-item/${item.restaurantId}/${item.restaurantOrderId}`}><div>18:43:20</div></Link></TableCell>
                        <TableCell align="left"><Link to={`/kitchen/order-item/${item.restaurantId}/${item.restaurantOrderId}`}><div>18:43:20</div></Link></TableCell>
                        <TableCell align="left"><Link to={`/kitchen-order-item/${item.restaurantId}/${i}`}><div> {this.state.tempSum[i]}</div></Link></TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </div>
      </div >
    );
  }
}
