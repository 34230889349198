import React, { Component } from 'react';
import style from './PaymentInformation.module.scss';
import orderApi from '../../core/api/order-api/order-api';
import Button from "@material-ui/core/Button";
import { showConfrimBox } from '../../shared/ComfirmAlertBox/ConfirmAlertBox';
import { CommonApi } from '../../core/api';
import { paymentGateway } from '../../core/contants';

export default class PaymentGateway extends Component {
    state = {
        orderId:null,
        isMobile:false //used to detect is site working in webview
    }


    componentDidMount() {
        //this.triggerCard();
        
        const orderId=this.props.match.params.orderId
        let query = this.getQuery();
        const isMobile=query.get('isMobile');
        this.setData(isMobile,orderId);
    }

    async setData(checkoutId,isMobile,orderId){
        // console.log("Retrive checkout",checkoutId)
        const orderCheckoutDetails = await orderApi.retrieveOrderCheckout(checkoutId);
        if(orderCheckoutDetails.gateway == paymentGateway.sumup){
            this.props.history.push(`/sumupPayment/?orderId=${orderId}&checkoutId=${orderCheckoutDetails.checkoutId}&isMobile=${isMobile}`);
        }
        else {
            this.props.history.push(`/paylandsPayment/?orderId=${orderId}&isMobile=${isMobile}`);
        }
        
        // console.log("on payment page ",responseDecode)
    }
    getQuery=()=> {
        const {location} = this.props;
        return new URLSearchParams(location.search);
    }
    render(){
        return(<></>)
    }
}
