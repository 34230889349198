import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./CSLogin.scss";

import logo from "../../../assets/logo.png";

import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import PersonIcon from "@material-ui/icons/Person";
import LockIcon from "@material-ui/icons/Lock";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import TermsBar from "../../../shared/TermsBar/TermsBar";

import authApi from "../../../core/api/auth-api/auth-api"
import { CS_Roles } from "../shared/Utils/common";

export default class Login extends Component {
    state = {
        showPassword: false,
        email: '',
        password: '',

        emailError: false,
        passwordError: false,
        emailErrorText: '',
        passwordErrorText: '',
    };

    isPermited = async () => {
        const user = await authApi.getCSUser();
        if (CS_Roles.includes(user.roleDescription)) {
            localStorage.setItem('CS-selected-filter',null);
            this.props.history.push('/customer-support/dashboard');
        }
        else {
            this.setState({
                emailError: true,
                passwordError: true,
                passwordErrorText: "Access denied"
            })
        }
    }

    handleLogin = async () => {
        let { email, password } = this.state

        let emailErrorText = ''
        let passwordErrorText = ''

        let passwordError = false
        let emailError = false

        if (!email) {
            emailError = true
            emailErrorText = 'This field cannot be empty'
        }

        if (!password) {
            passwordError = true
            passwordErrorText = 'This field cannot be empty'
        }

        let pattern = new RegExp(
            /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
        );

        if (!pattern.test(email)) {
            emailError = true;
            emailErrorText = "Please enter valid email address.";
        }

        let data = {
            email: email,
            password: password
        }

        if (!emailError && !passwordError) {
            try {
                await authApi.login(data);
                this.isPermited()
            } catch (err) {
                this.setState({
                    emailError: true,
                    passwordError: true,
                    errorMessage: err.message,
                    password: '',
                    passwordErrorText: err.message
                })
            }
        } else {
            this.setState({
                emailError: emailError,
                emailErrorText: emailErrorText,
                passwordError: passwordError,
                passwordErrorText: passwordErrorText
            })
        }

    }

    handleClickShowPassword = () => {
        const { showPassword } = this.state;
        this.setState({
            showPassword: !showPassword,
        });
    };

    handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    handleChange = (event) => {
        event.persist();

        this.setState({
            [event.target.name]: event.target.value
        });
    }

    render() {
        const { email, password } = this.state;

        return (
            <div className="login-container">
                <Paper className="paper" elevation={5}>
                    <img src={logo} />

                    <Divider variant="middle" />

                    <div className="title"> Customer Support Login</div>
                    <form onSubmit={this.handleLogin}>
                        <div className="login-form">
                            <div className="form-item">
                                <div className="name">EMAIL</div>
                                <TextField
                                    onChange={this.handleChange}
                                    error={this.state.emailError}
                                    helperText={this.state.emailErrorText}
                                    value={email}
                                    name="email"
                                    variant="outlined"
                                    fullWidth
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <IconButton disabled>
                                                    <PersonIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </div>

                            <div className="form-item">
                                <div className="name">PASSWORD</div>
                                <TextField
                                    onChange={this.handleChange}
                                    error={this.state.passwordError}
                                    helperText={this.state.passwordErrorText}
                                    name="password"
                                    variant="outlined"
                                    value={password}
                                    fullWidth
                                    type={this.state.showPassword ? "text" : "password"}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <IconButton disabled>
                                                    <LockIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={this.handleClickShowPassword}
                                                    edge="end"
                                                >
                                                    {this.state.showPassword ? (
                                                        <Visibility />
                                                    ) : (
                                                        <VisibilityOff />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </div>
                        </div>

                        <div className="options">
                            <Grid container alignItems="center" justify="center">
                                <Link to="/reset">
                                    <p id="red-button">
                                        <u>FORGOTTEN PASSWORD?</u>
                                    </p>
                                </Link>
                            </Grid>
                        </div>

                        <div className="login">
                            <Button
                                onClick={this.handleLogin}
                                variant="contained"
                                color="primary"
                                endIcon={<NavigateNextIcon />}
                            >
                                LOGIN
              </Button>
                        </div>

                        <div className="options">
                            <div className="signup">
                                {/* <Grid container alignItems="center" justify="center">
                  <p>DON'T HAVE AN ACCOUNT?</p>
                  <Link to="/signup">
                    <p id="red-button">
                      <u>SIGN UP</u>
                    </p>
                  </Link>
                </Grid> */}
                            </div>
                        </div>
                    </form>
                </Paper>
                <TermsBar />
            </div>
        );
    }
}
