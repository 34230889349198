import { AuthApi, CommonApi } from "..";
import httpApi from "../http-api/http-api";

class PromotionApi {
    addPromotion = async (data) => {
       const result =  await httpApi.sendPostRequest('/Promotion/Add', data);
       return result;
    }

    updatePromotion = async (promotionId,data)=>{
        const result = await httpApi.sendPutRequest(`/Promotion/Update?id=${promotionId}`,data);
        return result;
    }

    getAllPromotions = async () => {
        const promotions = await httpApi.sendGetRequest(`/Promotion/GetAll`);
        return promotions;
    }

    getPromotion = async (promotionId)=>{
        const promotion = await httpApi.sendGetRequest(`/Promotion/${promotionId}`);
        return promotion;
    }

    deletePromotion = async (promotionId)=>{
        const result = await httpApi.sendDeleteRequest(`/Promotion/Remove?id=${promotionId}`);
        return result;
    }

    sendBroadcastToAllUser = async (promotionId)=>{
        const result = await httpApi.sendPostRequest(`/Promotion/Broadcast/${promotionId}`)
    }

}

export default new PromotionApi()