import React, { Component } from 'react';
import style from './CustomerIssues.module.scss';
import filterIcon from '../../../img/filter.svg';
import { Button, Link, Paper, TablePagination } from '@material-ui/core';
import chevronRightIcon from '../../../img/chevron-right.svg';
import { CustomerMessages } from '../../../../../core/api';
import time from '../../../../../core/lib/time';
import ViewMessage from './components/ViewMessage';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { CommonActions } from '../../../../../core/constants/actions';
// import TablePagination from './components/TablePagination';

class CustomerIssues extends Component {
    state = {
        data: [],
        openModal: false,
        selectedIssueData: null,
        active: true,
        pageIndex:1,
        totalPages:1,
        totalCount:0,
        rowsPerPage:5
    }

    componentDidMount() {
        this.getActiveMessages();
    }

    getActiveMessages(){
        CustomerMessages.getNotResolvedMessages(this.state.pageIndex,this.state.rowsPerPage)
        .then((data) => {
            this.setState({ 
                data: data['messages'],
                totalPages:data['totalPages'],
                totalCount:data['totalCount']
            })
        })
    }
    getResolvedMessages(){
        CustomerMessages.getResolvedMessages(this.state.pageIndex,this.state.rowsPerPage)
        .then((data) => {
            this.setState({ 
                data: data['messages'],
                totalPages:data['totalPages'],
                totalCount:data['totalCount'] 
            })
        })
    }

    openModal = (data) => {
        this.setState({
            selectedIssueData: data
        }, () => {
            this.setState({ openModal: true })
        })
    }
    closeModal = (refreshData = false) => {
        this.setState({ openModal: false });
        if (refreshData) {
            this.setCustomerIssueCount()
            this.state.active?this.getActiveMessages():this.getResolvedMessages();
        }
    }
    setCustomerIssueCount = async () =>{
        const res = await CustomerMessages.getNotResolveCount();
        this.props.updateNotResolvedMessageCount(res['count'])
      }

    routeOrderDetails = (orderId) => {
        this.props.history.push(`/customer-support/order-details/${orderId}`);
    }


    renderIssues = () => {
        const { data, openModal, selectedIssueData } = this.state;
        return data
            .map((v) => (
                <tr key={v['id']}>
                    <td>
                        <Link onClick={() => this.routeOrderDetails(v['orderId'])}>{v['orderNo']}</Link>
                    </td>
                    <td>{time.timestampToDateString(new Date(v['createdOn']))}</td>
                    <td>{v['customerName']}</td>
                    <td>{v['typeofIssue']}</td>
                    <td className={style.actions}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => this.openModal(v)}
                        >
                            <img src={chevronRightIcon} alt="" />
                        </Button>
                    </td>
                </tr>
            ))
    }

    onChangeFilter(active=false){
        this.setState({active,pageIndex:1},()=>{
            this.callApiDependsOnFilter(active)
        });    
    }

    callApiDependsOnFilter = (active=false) =>{
        active?this.getActiveMessages():this.getResolvedMessages();
    }

    onNext = ()=>{
        const pageIndex = this.state.pageIndex;
        this.setState({pageIndex:pageIndex+1},()=>{
            this.callApiDependsOnFilter(this.state.active)
        });  
    }
    onPrev = ()=>{
        const pageIndex = this.state.pageIndex;
        if(pageIndex > 1)
        this.setState({pageIndex:pageIndex-1},()=>{
            this.callApiDependsOnFilter(this.state.active)
        });  
    }

    onChangePage =(event, newPage) =>{
        this.state.pageIndex>newPage?this.onPrev():this.onNext()
    }

    handleChangeRowsPerPage = (event) =>{
        this.setState({rowsPerPage:parseInt(event.target.value, 10),pageIndex:1},()=>{
          this.callApiDependsOnFilter()
        })
      }

    render() {
        const { data, openModal, selectedIssueData,active,pageIndex,totalCount,rowsPerPage } = this.state;
        return (
            <div className={style.container}>
                <div className={style.title}>
                    Customer Issues
                    <span>
                        <Button
                            variant={!active ? "outlined" : "contained"} 
                            size="small"
                            color="primary"
                            className={style.grpBtn}
                            onClick={()=>this.onChangeFilter(true)}
                        >Active</Button>
                    </span>
                    <span>
                        <Button
                            variant={active ? "outlined" : "contained"}
                            size="small" 
                            color="primary" 
                            className={style.grpBtn}
                            onClick={()=>this.onChangeFilter(false)}
                        >Resolved</Button>
                    </span>
                </div>


                <table className={style.title}>
                    <thead>
                        <tr>
                            <th># Order</th>
                            <th> Date/Time </th>
                            <th> Customer Name</th>
                            <th> Issue Type </th>

                            <th></th>
                        </tr>
                    </thead>

                    {
                        data.length > 0 && (
                            <tbody>
                                {this.renderIssues()}
                            </tbody>
                        )
                    }

                </table>
                {/* <TablePagination 
                    onNext={this.onNext}
                    onPrev={this.onPrev}
                    pageIndex={pageIndex}
                /> */}
                <Paper>
                    { data.length > 0 &&
                            <TablePagination
                            rowsPerPageOptions={[5,10,25]}
                            component="div"
                            count={totalCount}
                            rowsPerPage={rowsPerPage}
                            page={pageIndex-1}
                            onChangePage={this.onChangePage}
                            style={{borderTop:'1px solid lightgrey'}}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        />
                    }

                </Paper>


                <ViewMessage
                    openModel={openModal}
                    close={this.closeModal}
                    data={selectedIssueData}
                />
            </div>
        )
    }
}

// export default CustomerIssues

const mapStateToProps = (state)=>{
    const {} = state.commonReducer;
    return {
    }
  }
  
  const mapDispatchToProps = (dispatch) =>{
    return {
      updateNotResolvedMessageCount:(count)=>{
        dispatch({
          type:CommonActions.UPDTE_NOT_RESOLVED_COUNT,
          payload:{
            count
          }
        })
      }
    }
  }
  
  export default connect(mapStateToProps,mapDispatchToProps)(withRouter(CustomerIssues));