import React, { Component } from 'react'
import { Link } from "react-router-dom";
import foodIcon from '../../assets/food-icon.svg'

import IconButton from "@material-ui/core/IconButton";

import './CategoryItem.scss'

export default class CategoryItem extends Component {
  toUpper = (name) => {
    let up = String(name)
    return up.toUpperCase()
  }
  render() {
    const { restaurantId, categoryId } = this.props;

    return (
      <div>
        <div className="category-item">
          <Link to={`/restaurant/${restaurantId}/${categoryId}`}>
            <div className="item">
              {/* <div className="icon-button" style={{ backgroundColor: this.props.bgColorCode }}>
                <IconButton>
                  <img src={this.props.thumbnailUrl} />
                </IconButton>
              </div> */}
              <div className="icon-button1">
                <IconButton>
                  <img src={foodIcon} />
                </IconButton>
              </div>
              <div className="name">{this.toUpper(this.props.name)}</div>
            </div>
          </Link>
        </div>
      </div>
    )
  }
}
