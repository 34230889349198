import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import "./SimpleButton.scss";

export default class SimpleButton extends Component {

  render() {
    return (
      <div className="simple">
        <Button
          type={this.props.type}
          disabled={this.props.disabled}
          onClick={this.props.onFunction}
          variant={this.props.variant || 'contained'}
          color="primary"
        >
          {this.props.text}
        </Button>
      </div>
    );
  }
}
