import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import React from 'react';
import style from './Alert.module.scss'
const CustomAlert = ({
    title,description,open,type,onOkClick,okButtonText,cancelButtonText,onCancelClick
}) => {
    return(
    <Dialog open={open} >
        <DialogTitle className={type ==='error'?style.error:null}>{title}</DialogTitle>
        <DialogContent>
            <DialogContentText>
                {description}
            </DialogContentText>
        </DialogContent>
        { !!onOkClick &&
            <DialogActions>
                <Button variant="contained" onClick={onOkClick} color="primary">
                    {!!okButtonText?okButtonText:'OK'}
                </Button>
                { 
                    !!onCancelClick &&
                    <Button onClick={onCancelClick} color="primary">
                        {!!cancelButtonText?cancelButtonText:'CANCEL'}
                    </Button>
                }


            </DialogActions>
        }

    </Dialog>
)};

export default CustomAlert;
