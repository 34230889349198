import React, { PureComponent } from 'react';
import { 
    Button, 
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogContentText, 
 } from '@material-ui/core';
import CustomDatePicker from '../../../../shared/DatePicker/DatePicker';

export class DateRangePicker extends PureComponent {
    render() {
        const { 
            setStartDate,
            setEndDate,
            startDate,
            endDate,
            openDatePicker,
            onSubmit,
        } = this.props;
        return (
            <Dialog open={openDatePicker}>
                <DialogContent>
                    <DialogContentText>Select Date Range</DialogContentText>

                    <CustomDatePicker
                        onChange={setStartDate}
                        value={startDate}
                        label="Start Date"
                    />
                    <CustomDatePicker 
                        onChange={setEndDate}
                        value={endDate}
                        label="End Date"
                    />
                </DialogContent>
                <DialogActions>
                
                    <Button 
                        color="primary" 
                        variant="contained" 
                        onClick={onSubmit}
                    >
                        Submit
                    </Button>

                    {/* <Button color="primary" onClick={onClose}>
                        Cancel
                    </Button> */}
                </DialogActions>
                
            </Dialog>
        )
    }
}

export default DateRangePicker
