import React, { createRef, PureComponent } from 'react'
import {
    Button,
    Dialog,
    DialogContent,
    DialogContentText,
    Grid,
    Typography,
} from '@material-ui/core';
import masterApi from '../../../../../core/api/master-api/master-api';
import exportIcon from "../../../img/external-link.svg";
import style from "../Sidebar.module.scss";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import DriverShiftApi from '../../../../../core/api/driver-Shift-api/DriverShiftApi';
import moment from 'moment';
import { checkDriverIsActive } from '../../Utils/common';
import CustomAlert from '../../../../../shared/Alert/Alert';
import DriverReportPrint from '../../../../EPOS/DriverFinanceReport';
import restaurantApi from '../../../../../core/api/restaurant-api/restaurant-api';

export default class DriverCheckIn extends PureComponent {
    driverPrintRef = createRef();
    state = {
        dialogOpen: false,
        restaurants: [],
        loading: false,
        confirmBox: false,
        selectedDriver: null,
        btnType: null,
        driverReport: null
    }

    componentDidMount() {
        this.fetchDrivers();
    }
    async fetchDrivers() {
        const restaurants = await masterApi.getDrivers();
        // console.log("???",restaurants)
        this.setState({
            restaurants: restaurants,
            loading: false
        })
    }

    toggleDialogOpen = () => {
        // console.log("Handle Kitchen Status");
        this.fetchDrivers();
        this.setState({ dialogOpen: !this.state.dialogOpen })
    }

    handleSubmit = async () => {
        const { selectedDriver, btnType } = this.state;
        // console.log(selectedDriver);
        const currentDate = moment().utc().format('YYYY-MM-DDTHH:mm:ss');
        if (btnType === 'checkIn') {
            await DriverShiftApi.checkIn(selectedDriver.driverId, currentDate);
        } else {
            await DriverShiftApi.checkOut(selectedDriver.driverId, currentDate);
            await this.printShiftReceipt();
        }
        this.fetchDrivers();
        this.resetConfirmBox();
    };

    printShiftReceipt = async () => {
        const { selectedDriver } = this.state;
        const startDateTime = moment.utc(selectedDriver.checkIn).toISOString();
        const endDateTime = moment().toISOString();

        const reportData = await restaurantApi.getDriverReport(startDateTime, endDateTime, selectedDriver.driverId);

        const driverReport = {
            startDateTime: moment(startDateTime).format('DD/MM/yyyy hh:mm a'),
            endDateTime: moment(endDateTime).format('DD/MM/yyyy hh:mm a'),
            ...reportData
        }

        this.setState({
            driverReport,
        }, () => {
            this.driverPrintRef.current.Print();
        });
    }

    handleButtonClick = (driver, btnType) => {
        this.setState({
            selectedDriver: driver,
            btnType,
            confirmBox: true
        })
    }

    resetConfirmBox = () => {
        this.setState({
            selectedDriver: null,
            CheckIn: null,
            confirmBox: false
        })
    }

    listItemRenderer = (driver) => (
        <ListItem key={driver.driverId}>
            <div className={style.listContainer}>
                <div style={{ width: '27%' }}>{driver.driverName}</div>
                <div style={{ width: '27%' }}>
                    {
                        checkDriverIsActive(driver) ?
                            <Button
                                onClick={() => this.handleButtonClick(driver, 'checkOut')}
                                variant="contained"
                                color="primary"
                                style={{ width: '90%' }}
                            >Check Out
                            </Button>
                            :
                            <Button
                                onClick={() => this.handleButtonClick(driver, 'checkIn')}
                                variant="contained"
                                color="primary"
                                style={{ width: '90%' }}
                            >Check In
                            </Button>
                    }
                </div>

                <div style={{ width: '45%' }}>
                    {
                        checkDriverIsActive(driver) &&
                        <div>Checked in at {moment.utc(driver.checkIn).local().format('h:mm a DD/MM/YYYY')}</div>
                    }

                </div>
            </div>
        </ListItem>
    )



    render() {
        const {
            dialogOpen,
            restaurants,
            loading,
            confirmBox,
            selectedDriver,
            btnType,
            driverReport
        } = this.state;
        // console.log(restaurants)
        return (
            <div>
                <div className={style.navItem} onClick={this.toggleDialogOpen}>
                    <SyncAltIcon style={{ marginRight: "20px", height: "25px" }} /> Driver Check In

                </div>
                <Dialog
                    open={dialogOpen}
                    fullWidth
                    onClose={this.toggleDialogOpen}
                >
                    <DialogContent>
                        <DialogContentText>Working Drivers</DialogContentText>
                        <List
                            sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                            subheader={loading && <ListSubheader style={{ textAlign: 'center' }}>Updating driver status please wait.....</ListSubheader>}
                        >
                            <div>
                                {
                                    restaurants.map((res) => {
                                        return this.listItemRenderer(res)
                                    })

                                }
                            </div>

                        </List>
                    </DialogContent>

                </Dialog>

                <CustomAlert
                    open={confirmBox}
                    title="Are you sure?"
                    description={`Do you want to ${btnType === 'checkIn' ? 'check in' : 'check out'} ${selectedDriver ? selectedDriver.driverName : ''}.`}
                    onOkClick={this.handleSubmit}
                    onCancelClick={this.resetConfirmBox}
                    okButtonText="YES"
                    cancelButtonText="NO"
                />
                <DriverReportPrint
                    ref={this.driverPrintRef}
                    data={driverReport ? driverReport : null}
                    driverShift={true}
                />
            </div>
        )
    }
}
