import httpApi from "../http-api/http-api";

class RestaurantApi {
  restaurantId = 1;
  restaurant_token = null;
  restaurant = null;
  isLoggedIn = false;

  constructor() {
    this.restaurant_token = localStorage.getItem("restaurant_token") || "";
    let restaurant = JSON.parse(localStorage.getItem("restaurant"));
    this.restaurant = restaurant || "";
    if (this.restaurant_token && this.restaurant) {
      this.isLoggedIn = true;
      this.restaurantId = restaurant.resturantId || 1;
    }
  }

  async login(data) {
    const resp = await httpApi.sendPostRequest(
      "/RestaurantOrder/restaurantLogin",
      data
    );

    const { resturantId, errorMessage, token } = resp;

    if (errorMessage) {
      console.log(errorMessage);
      throw new Error(errorMessage);
    }

    this.restaurantId = resturantId;
    this.isLoggedIn = true;
    this.restaurant = resp;
    this.restaurant_token = token;
    httpApi.setToken(token);
    localStorage.setItem("restaurant_token", token);
    localStorage.setItem("restaurant", JSON.stringify(resp));
  }

  logout = async (avoidRedirect) => {
    this.isLoggedIn = false;
    this.restaurant = null;
    this.restaurant_token = null;
    this.restaurantId = 1;

    localStorage.removeItem("restaurant_token");
    localStorage.removeItem("restaurant");
    localStorage.removeItem("restaurantId");

    if (!avoidRedirect) {
      window.location.href = "/kitchen";
    }
  };

  getOrderHistory = async () => {
    const result = await httpApi.sendGetRequest(
      `/RestaurantOrder/GetOrderCookingById/${this.restaurantId}`
    );
    return result;
  };

  getOrderHistoryById = async (orderId) => {
    const result = await httpApi.sendGetRequest(
      `/RestaurantOrder/GetOrderHistoryById/${orderId}`
    );
    return result;
  };

  getRestaurantOrderDetails = async (orderId) => {
    const result = await httpApi.sendGetRequest(
      `/RestaurantOrder/GetRestaurantOrderDetail/${orderId}`
    );
    return result;
  };

  setRestaurantHoldState = async (restaurantId, value) => {
    const result = await httpApi.sendGetRequest(
      `/RestaurantOrder/HoldOrder/${restaurantId}/${value}`
    );
    return result;
  };

  updateOrderState = async (orderId, state) => {
    const result = await httpApi.sendPutRequest(
      `/RestaurantOrder/${orderId}/ChangeOrderStatus?orderStatus=${state}`
    );
    return result;
  };

  updateRestaurantPrepTime = async (restaurantId, time) => {
    const result = await httpApi.sendGetRequest(
      `​/RestaurantOrder/UpdatePrepTime/${restaurantId}/${time}`
    );
    return result;
  };

  getFinanceReport = async (restaurantId, fromDate,toDate,includeDeliveryDetails) => {
    const url = `/RestaurantOrder/SalesSummaryReport?restaurantId=${restaurantId}&fromOrderDate=${fromDate}
                  &toOrderDate=${toDate}&includeDeliveryDetails=${includeDeliveryDetails}`;
    const result = await httpApi.sendGetRequest(url);
    return result;
  };
  getPosReport = async (restaurantId, fromDate,toDate) => {
    const result = await httpApi.sendPostRequest(
      `/Report/pos/order/sales/${restaurantId}`,
      {
        "fromDateTime": fromDate,
        "toDateTime": toDate
      }
    );
    return result;
  };

  getDriverReport = async (fromDateTime, toDateTime,driverId) => {
    const sendObj={
      fromDateTime,
      toDateTime
    };
    const res = await httpApi.sendPostRequest(
      `/Report/driver/${driverId}`,sendObj
    );
    return res;
  }

  getFullKitchenOrderData = async (orderId) => {
    const res = await httpApi.sendGetRequest(`/RestaurantOrder/PrintTicket/${this.restaurantId}/${orderId}`);
    return res;
  }
}

export default new RestaurantApi();
