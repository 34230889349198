import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import React, { Component } from 'react';
import style from './ConfirmAlertBox.module.scss';
import ConfirmAlertBoxManager from './ConfirmAlertBoxManager';

export function showConfrimBox({
    title='',
    description='',
    type,
    onOkClick=()=>{},
    okButtonText='Okay',
    cancelButtonText='Cancel',
    onCancelClick=()=>{}
}) {
    const ref = ConfirmAlertBoxManager.getDefault();
    if (!!ref) {
        ref.showConfirmBox({
            title,description,type,onOkClick,okButtonText,cancelButtonText,onCancelClick
        });
    }
}

export function closeConfrimBox() {
    const ref = ConfirmAlertBoxManager.getDefault();
    if (!!ref) {
        ref.closeConfirmBox();
    }
}


export default class ConfirmAlertBox extends Component {
    constructor(props) {
      super(props);
      this.state = {
        title:'',
        description:'',
        open:false,
        type:null,
        onOkClick:()=>{},
        okButtonText:'Okay',
        cancelButtonText:'Cancel',
        onCancelClick:()=>{}
      };
    }
  
      componentDidMount(){
        ConfirmAlertBoxManager.register(this);
      }
  
      componentWillUnmount() {
        ConfirmAlertBoxManager.unregister(this);
      }
  
      showConfirmBox = ({
        title='',
        description='',
        type,
        onOkClick=()=>{},
        okButtonText='Okay',
        cancelButtonText='Cancel',
        onCancelClick=()=>{}
      } = {}) => {
          this.setState({
            title,description,type,onOkClick,okButtonText,cancelButtonText,onCancelClick
          }, () => {
              this.setState({ open: true });
          });
      }
  
      closeConfirmBox = () => {
          this.setState({ open: false });
      }
  
    render() {
        const {
            title,description,open,type,onOkClick,okButtonText,cancelButtonText,onCancelClick
      } = this.state;
      return (
        <Dialog open={open} >
        <DialogTitle className={type ==='error'?style.error:null} style={{paddingBottom:0}}>{title}</DialogTitle>
        {
            description !== '' &&
            <DialogContent>
                <DialogContentText>
                    {description}
                </DialogContentText>
            </DialogContent>
        }
        { !!onOkClick &&
            <DialogActions className={style.dialogActions}>
                <Button variant="contained" onClick={()=>{
                    this.closeConfirmBox();
                    onOkClick && onOkClick();
                }} color="primary">
                    {!!okButtonText?okButtonText:'OK'}
                </Button>
                { 
                    !!onCancelClick &&
                    <Button onClick={()=>{
                        this.closeConfirmBox();
                        onCancelClick && onCancelClick();
                    }} color="primary">
                        {!!cancelButtonText?cancelButtonText:'CANCEL'}
                    </Button>
                }
            </DialogActions>
        }

    </Dialog>
      );
    }
  }

// const CustomAlert = ({
//     title,description,open,type,onOkClick,okButtonText,cancelButtonText,onCancelClick
// }) => {
//     return(
//     <Dialog open={open} >
//         <DialogTitle className={type ==='error'?style.error:null}>{title}</DialogTitle>
//         <DialogContent>
//             <DialogContentText>
//                 {description}
//             </DialogContentText>
//         </DialogContent>
//         { !!onOkClick &&
//             <DialogActions>
//                 <Button variant="contained" onClick={onOkClick} color="primary">
//                     {!!okButtonText?okButtonText:'OK'}
//                 </Button>
//                 { 
//                     !!onCancelClick &&
//                     <Button onClick={onCancelClick} color="primary">
//                         {!!cancelButtonText?cancelButtonText:'CANCEL'}
//                     </Button>
//                 }


//             </DialogActions>
//         }

//     </Dialog>
// )};

// export default CustomAlert;
