import React, { PureComponent } from 'react';
import { 
    Button, 
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogContentText,
    TextField,
 } from '@material-ui/core';
import  './UserMessage.scss';
import time from '../../../core/lib/time';
import {messageType} from '../../../core/contants'

export default function UserMessage({
    open,
    data={},
    close=()=>{}
}) {


    const orderMessageRender = (
        <>                    
            <DialogContentText>
                <div className="headingContainer">
                    <span>#{data?.['orderNo']} - {data?.['typeofIssue']}</span>
                    {/* <span>{time.timestampToDateString(data?.parentMessageDateTime)}</span> */}
                </div>
            </DialogContentText>
            <div className="subjectText">{data?.['parentMessageSubject']}</div>
            {
                    data && data?.['message'] &&            
                <>
                    <div className="msgHead"><b>Reply :</b></div>
                    <div className='date'>({time.timestampToDateString(data?.createdOn)})</div>

                    <div className="messageText">{data?.['message']}</div>
                </>
            }

            <div  className="msgHead"><b>Issue :</b></div>
            <div className='date'>({time.timestampToDateString(data?.parentMessageDateTime)})</div>
            <div className="messageText">{data?.['parentMessageBody']}</div>
    </>
    )

    const broadCastMessageRender = (
        <>
            <DialogContentText>
                <div className="headingContainer"> 
                <span>Promotion</span>
                </div>
            </DialogContentText>
            <div className="subjectText">{data?.['subject']}</div>
            <div className="messageText">{data?.['message']}</div>
        </>
    )
  return (
    <>
        <Dialog open={open} fullWidth className="modalContainer">
                <DialogContent>
                    {data && data?.['messageType'] === messageType.orderMessage && orderMessageRender}
                    {data && data?.['messageType'] === messageType.broadcast && broadCastMessageRender}

                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={close}>
                        Cancel
                    </Button>
                </DialogActions>
                
            </Dialog>
    </>
  )
}
