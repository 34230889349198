import React, { PureComponent } from 'react';
import { 
    Button, 
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogContentText, 
    Snackbar, 
    TextField 
} from "@material-ui/core";
import CustomAlert from '../../../../shared/Alert/Alert';
import SimpleButton from '../../../../shared/SimpleButton/SimpleButton';
import orderApi from '../../../../core/api/order-api/order-api';
import MuiAlert from '@material-ui/lab/Alert';

class OrderCancel extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
        cancelDialogOpen:false,
        cancelNote:'',
        confirmCancel:false,
        errorMsg:'',
        successBar:false

    };
  }


  handleConfirm = async () => {
    const { cancelNote } = this.state;
    const {orderId,fetchOrderDetails} = this.props;
    if(cancelNote === ''){
        this.setState({errorMsg:"Cancellation Note' must not be empty."});
        return
    }
    this.handleConfirmClose();
    const sentObj = {
        "orderId": orderId,
        "cancellationNote": cancelNote
    }
    let orderCancelRef = await orderApi.cancelOrder(sentObj);
    if(!!orderCancelRef && orderCancelRef.status === 5){
        fetchOrderDetails();
        this.setState({successBar:true})
    }
    console.log("Cancel Order",cancelNote,orderId,orderCancelRef)
    
    }

    handleOpen = () => {
        this.setState({
            cancelDialogOpen: true,
            cancelNote:'',
            errorMsg:''
        })
    }
    handleChange = (event) => {
        event.persist();

        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    handleClose = () => {
        this.setState({
            cancelDialogOpen: false,
            cancelNote: ''
        })
    }
    handleConfirmClose = () => {
        this.setState({
            confirmCancel:false
        })
    }

    handleSubmit =() =>{
        const { cancelNote } = this.state;
        if(cancelNote === ''){
            this.setState({errorMsg:"Cancellation Note' must not be empty."});
            return
        }
        this.setState({
            cancelDialogOpen: false,
            confirmCancel:true
        })
    }

    handleCloseSuccessBar = ()=>{
        this.setState({successBar:false})
    }

  render() {
      const {
        cancelDialogOpen,
        cancelNote,
        confirmCancel,
        errorMsg,
        successBar
      } = this.state;
      const {outlineButton} = this.props;
    return (
        <div>
            {  
                !outlineButton &&
                <Button
                        variant="contained"
                        onClick={this.handleOpen}
                        color="primary"
                        style={{
                            textTransform:'capitalize',
                            width:'fit-content'
                        }}
                    >
                            Cancel Order
                    </Button>
            }
            { 
                outlineButton &&
                <SimpleButton
                    size="small"
                    variant="outlined"
                    color="primary"
                    type="button"
                    text="Cancel Order"
                    onFunction={this.handleOpen}
                >
                </SimpleButton>
            }

            <CustomAlert 
                title="Are you sure?"
                description="You want to cancel this order."
                okButtonText="YES"
                onOkClick={this.handleConfirm}
                cancelButtonText="CANCEL"
                onCancelClick={this.handleConfirmClose}
                open={confirmCancel}
            />

            <Dialog open={cancelDialogOpen} >
                <DialogContent>
                    <DialogContentText>
                        Enter note about the order cancel.
                    </DialogContentText>

                    <div style={{ width: 500 }}>
                        <TextField
                            autoFocus
                            multiline
                            rows={3}
                            label="note"
                            name="cancelNote"
                            value={cancelNote}
                            fullWidth
                            onChange={this.handleChange}
                            error
                            helperText={errorMsg}
                        />
                    </div>
                </DialogContent>

                <DialogActions>
                    <Button color="primary" variant="contained" onClick={this.handleSubmit}>
                        Submit
                    </Button>

                    <Button color="primary" onClick={this.handleClose}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar open={successBar} autoHideDuration={4000} onClose={this.handleCloseSuccessBar}>
                    <Alert onClose={this.handleCloseSuccessBar} severity="success">
                        Order Cancelled Successfully.
                    </Alert>
            </Snackbar>
        </div>
    );
  }
}

export default OrderCancel;

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }