import React, { Component } from 'react';
import TopBar from '../../shared/TopBar/TopBar';
import './Tnc.scss'
export default class TnC extends Component {

  render() {
    return (
        <div className="tnc-container">

            <div className="section">
                {/* <div className="top">
                    <TopBar />
                </div> */}
                <h1>Terms and Conditions</h1>

                <b>Terms</b>
                <ul>
                    <li>Minimum Terms of End-User Licence Agreement AND Privacy Policy</li> 
                </ul>
                <ul className="ul-circle">
                    <li>PART 1 - Terms of Use</li>
                    <li>PART 2 - Privacy Policy</li>
                </ul>
                <b>PART 1 – Terms of Use</b>
                <p>AVAILABILITY AND DELIVERY</p>
                <p>We will do our best to fulfil any order within the delivery time specified during the checkout process. Unfortunately, things do not always go to plan and factors, such as weather and traffic conditions, may occasionally prevent us from achieving this.</p>
                
                <p>CANCELLATION</p>
                <p>Orders may be cancelled up to the point the order has started being prepared. Once delivery of an order is made we cannot cancel or refund the order. Cancellations must be made by telephoning our call centre. See the Contact Page for the number. We reserve the right to cancel any order, before or after acceptance, and will notify you immediately of any such cancellation. You will not be charged for cancelled orders made in accordance with these cancellation provisions.</p>

                <p>COMPLAINTS</p>
                <p>
                    All complaints will be investigated on an individual basis. We will take appropriate action as a result of our findings and any refund will be made at our discretion.
                </p>
                <p>PRICE AND PAYMENT</p>
                <p>
                    The price of any Products will be as quoted on our website and mobile app may vary from time to time, except in cases of obvious error. Prices include VAT. Prices are liable to change at any time, but changes will not affect orders placed that you have already paid for or where you have already checked-out and been presented with the confirmation screen in an online order. Should you choose to pay by credit or debit card via the websites or mobile app, once your order has been placed we will confirm acceptance of your order. Until confirmation of your order has been made, no payment will be taken from your card.
                </p>
                
                <p>ORDERS</p>
                <p>
                    When you place an order it needs to be accepted by us or the Partner before it is confirmed. You will receive confirmation if your order has been accepted.
                </p>
                <p>
                    The contract for the supply of any Item you have ordered via PFH only comes into existence when you receive confirmation that your order/s has been accepted. All Items are subject to availability and this contract will be between you and the Partner Restaurant.
                </p>
                <p>
                    You are responsible for paying for all Items ordered using your account, and for related delivery charges, and for complying with these Terms, even if you have ordered the Item for someone else. 
                </p>
                <p>
                    It is solely your responsibility to provide and update PFH with all the correct and relevant information when placing orders. This includes but is not limited to contact details, addresses and delivery instructions.
                </p>
                <p>
                    You will still be charged for the total cost of all order/s and for delivery costs in the event of a failed delivery, if you have caused such failure for any reason. Therefore, we reserve the right to leave your premises if you do not open the door or answer your phone within 10 minutes of our delivery drivers reaching your address. 
                </p>    

                <b>PART 2 – Privacy Policy</b>
                <p>
                    By visiting and/or ordering services on the App (as such term is defined below) you agree and where required you consent to the collection, use and transfer of your information as set out in this policy
                </p>
                <p>
                    SCOPE OF POLICY
                </p>
                <p>
                    This policy (together with our end-user licence agreement (EULA)) and any additional terms of use incorporated by reference into the EULA, together Our Terms of Use) applies to your use of:
                </p>
                <p>
                    This policy sets out the basis on which any personal data We collect from you, or that you provide to us, will be processed by us.
                </p>

                <p>INFORMATION WE MAY COLLECT FROM YOU</p>
                <p>
                    We may collect and process the following data about you:
                </p>
                <ul>
                    <li>
                        Submitted Information: information that you provide by filling in forms on Our Sites, including the Device's telephone number. This includes information provided at the time of registering to use the App or any of the Services, subscribing to any of the Services, posting material or requesting further services. We may also ask you for information when you enter a competition or promotion sponsored by Pizzeria Plaza, and when you report a problem with any of Our Sites.
                    </li>
                    <li>
                        Additional information:
                    </li>
                    <ul>
                        <li>
                            if you contact us, We may keep a record of that correspondence;
                        </li>
                        <li>
                            We may also ask you for your views on our Services and to notify you occasionally about important changes or developments to the App or our or our Services
                        </li>
                        <li>
                            details of transactions you carry out through any of Our Sites and of the fulfilment of your orders or reservations; and
                        </li>
                        <li>
                            details of your visits to any of Our Sites including, but not limited to, traffic data, social media accounts, location data, weblogs and other communication data, whether this is required for our own billing purposes or otherwise and the resources that you access.
                        </li>
                    </ul>
                    <li>
                        Device information: We may collect information about the Device or any computer you may use to download or stream a copy of the App onto your Device, including, where available, the Device's or computer's unique Device identifiers, operating system, browser type and mobile network information as well as the Device's telephone number, for system administration and to 
                        report aggregate information to our advertisers. We may associate Device information with Submitted Information and will treat the combined information as personal data in accordance with this policy for as long as it is combined.
                    </li>
                    <li>
                        Location information: when you use one of our location-enabled Services, We may collect and process information about your actual location. Some of these Services require your personal data for the feature to work. If you wish to use the particular feature, you will be asked to consent to your data being used for this purpose. You can withdraw your consent at any time by using the contact information included below or updating your profile within the App
                    </li>
                    <li>
                        Log information: when you use the Services or view content on Our Sites, We may automatically collect and store certain information in server logs, including but not limited to internet protocol (IP) addresses, internet service provider (ISP), clickstream data, browser type and language, viewed and exit pages and date or time stamps.
                    </li>
                    <li>
                        Unique application numbers: when you install or uninstall a Service containing a unique application number or when such a Service searches for automatic updates, that number and information about your installation, for example, the type of operating system, may be sent to us.
                    </li>
                </ul>

                <p>
                    COOKIES
                </p>
                <p>
                    Our Sites use cookies to distinguish you from other users of Our Sites. This helps us to provide you with a good experience when you use the App or browse Our Sites and also allows us to improve the App and Our Sites. For detailed information on the cookies
                </p>

                <ul>
                    <li>
                        Strictly necessary cookies. These are cookies that are required for the operation of the App. They include, for example, cookies that enable you to log into secure areas of the App or use a shopping cart.
                    </li>
                    <li>
                        Analytical/performance cookies. They allow us to recognise and count the number of visitors and to see how visitors move around our website when they are using it. This helps us to improve the way our App works, for example, by ensuring that users are finding what they are looking for easily.
                    </li>
                    <li>
                        Targeting cookies. These cookies record your visit to our App, the pages you have visited and the links you have followed. We will use this information to make our App and the advertising displayed on it more relevant to your interests. We may also share this information with third parties for this purpose.
                    </li>
                </ul>
                <p>
                    You block cookies by activating the setting on your browser that allows you to refuse the setting of all or some cookies.
                </p>

                <p>
                    If cookies are already on your system, you can delete them - For more information on cookies and deletion of cookies please visit: http://www.allaboutcookies.org/manage-cookies.
                </p>

                <p>
                    Please be aware that deleting certain cookies can result in difficulties when navigating around sites.
                </p>

                <p>
                    WHERE WE STORE YOUR PERSONAL DATA
                </p>
                
                <p>
                    The data that We collect from you may be transferred to, and stored at, a destination outside the European Economic Area (EEA). It may also be processed by staff operating outside the EEA who work for us or for one of our suppliers. These staff may be engaged in the fulfilment of your request, order or reservation, the processing of your payment details and the provision of support services. By submitting your personal data, you agree to this transfer, storing or processing. We will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this privacy policy.
                </p>

                <p>
                    All information you provide to us is stored on our secure servers. Any payment transactions carried out by our chosen third-party provider of payment processing services will be encrypted. Where We have given you (or where you have chosen) a password that enables you to access certain parts of Our Sites, you are responsible for keeping this password confidential. We ask you not to share a password with anyone.
                </p>

                <p>
                    Unfortunately, the transmission of information via the internet is not completely secure. Although We will do our best to protect your personal data, We cannot guarantee the security of your data transmitted to Our Sites; any transmission is at your own risk. Once We have received your information, We will use strict procedures and security features to try to prevent unauthorised access.
                </p>

                <p>
                    We may collect and store personal data on your Device using application data caches and browser web storage and other technology.
                </p>

                <p>
                    USES MADE OF THE INFORMATION
                </p>

                <p>
                    We use the information we collect for:
                </p>

                <ul>
                    <li>
                        Processing your order or reservation and providing customer care support;
                    </li>
                    <li>
                        Planning and managing our business activities, including analysing customers' shopping/reservations habits and preferences and administering the App;
                    </li>
                    <li>
                        Monitoring customer traffic patterns and App usage to help us develop and improve the design and layout of the App;
                    </li>
                    <li>
                        Providing you with information about the products and services we offer and for cross marketing purposes by providing you with offers from limited third parties which we believe will be of genuine interest to you.
                    </li>
                </ul>

                <p>
                    DISCLOSURE OF YOUR INFORMATION
                </p>
                <p>
                    We may disclose your personal information to any member of our group, which means our subsidiaries, our ultimate holding company and its subsidiaries, as defined in section 1159 of the Companies Act 2006 and to Pizzeria Plaza.
                </p>

                <p>
                    We may disclose your personal information to third parties:
                </p>

                <ul>
                    <li>
                        In the event that We sell or buy any business or assets, in which case We may disclose your personal data to the prospective seller or buyer of such business or assets.
                    </li>
                    <li>
                        If We are under a duty to disclose or share your personal data in order to comply with any legal or regulatory obligation or request.
                    </li>
                    <li>
                        In order to:
                    </li>
                    <ul>
                        <li>
                            enforce or apply the EULA, Our Terms of Use and other agreements or to investigate potential breaches; or
                        </li>
                        <li>
                            protect the rights, property or safety of us or Pizzeria Plaza, our customers, or others. This includes exchanging information with other companies and organisations for the purposes of fraud protection and credit risk reduction.
                        </li>
                    </ul>
                </ul>

                <p>
                    YOUR RIGHTS
                </p>

                <p>
                    Our Sites may, from time to time, contain links to and from the websites of our partner networks, advertisers and affiliates (including, but not limited to, websites on which the App or the Services are advertised). If you follow a link to any of these websites, please note that these websites and any services that may be accessible through them have their own privacy policies and that We do not accept any responsibility or liability for these policies or for any personal data that may be collected through these websites or services, such as contact and location data. Please check these policies before you submit any personal data to these websites or use these services.
                </p>

                <p>
                    You have the right to see the information we hold about you (Access Request) and to ask us to make any changes to ensure that it is accurate and up to date. If you wish to do this, please contact us using the contact details set out in section 8 above.
                </p>

                <p>
                    CHANGES TO PRIVACY POLICY
                </p>

                <p>
                    Any changes We may make to our privacy policy in the future will be posted on this page and, where appropriate, notified to you when you next start the App or log onto one of Our Sites. The new terms may be displayed on-screen and you may be required to read and accept them to continue your use of the App or the Services.
                </p>
            </div>
        </div>
    );
  }
}
