import React, { useEffect } from "react";
import "./App.scss";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";

import Home from "./pages/HomePage/Home";
import Login from "./pages/LoginPage/Login";
import SignUp from "./pages/SignUpPage/SignUp";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import MyProfile from "./pages/MyProfile/MyProfile";
import OrderItem from "./pages/OrderItem/OrderItem";
import EditOrderItem from "./pages/EditOrderItem/EditOrderItem";
import SingleCategory from "./pages/SingleCategory/SingleCategory";
import OrderPageOne from "./pages/OrderPageOne/OrderPageOne";
import OrderPageTwo from "./pages/OrderPageTwo/OrderPageTwo";
import MyDetails from "./pages/MyDetails/MyDetails";
import AddOrderItem from "./pages/AddOrderItem/AddOrderItem";
import OrderList from "./pages/OrderList/OrderList";
import SearchResult from "./pages/SearchResult/SearchResult";
import OrderDetails from './pages/OrderDetails/OrderDetails';
import PaymentInformationPage from './pages/PaymentInformation/PaymentInformation';
import OrderPlaced  from "./pages/OrderPlaced/OrderPlaced";

import KitchenLogin from "./pages/Kitchen/KitchenLogin/KitchenLogin";
import KitchenOrderList from "./pages/Kitchen/OrderList/KitchenOrderList";
import KitchenOrderItem from "./pages/Kitchen/OrderItem/KitchenOrderItem";
import KitchenDashboard from "./pages/Kitchen/KitchenDashboard/KitchenDashboard";

import CSLogin from "./pages/CustomerSupport/CSLogin/CSLogin"
import CSDashboard from "./pages/CustomerSupport/CSDashboard/CSDashboard";
import CSOrderHistory from "./pages/CustomerSupport/CSOrderHistory/CSOrderHistory";
import CSOrderDetails from "./pages/CustomerSupport/CSOrderDetails/CSOrderDetails";
import CSTakeOrder from "./pages/CustomerSupport/CSTakeOrder/CSTakeOrder";
import TnC from "./pages/TnC/TnC";
import ThermalPrinter2 from "./pages/EPOS/ThermalPrinter2";
import SumupPayment from "./pages/PaymentInformation/SumupPayment";
import { isAndroid, isIOS } from "react-device-detect";
import ConfirmAlertBox from "./shared/ComfirmAlertBox/ConfirmAlertBox";
import CustomToastAlert from "./shared/ToastAlert/ToastAlert";
import Promotions from "./pages/CustomerSupport/Promotions/Promotions";
import UserInbox from "./pages/UserInbox/UserInbox";
import PaylandsPayment from "./pages/PaymentInformation/PaylandsPayment";
import PaylandsPaymentStatus from "./pages/PaymentInformation/PaylandsPaymentStatus";
import PaymentGateway from "./pages/PaymentInformation/PaymentGateway";

const defTheme = "light";



const theme = createMuiTheme({
  palette: {
    type: defTheme,
    primary: {
      main: "#d9211d",
    },
  },
});


// const checkIsMobile = ()=>{
//   if(isAndroid){
//     window.open('https://play.google.com/store/apps/details?id=com.pizzeriaplazafoodhub.mobileapp')
//   }
//   else if(isIOS){
//     window.open('https://apps.apple.com/gb/app/gibfoodhub/id1566842240')
//   }
// }
// checkIsMobile();
function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ConfirmAlertBox/>
      <CustomToastAlert />
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/reset" component={ForgotPassword} />
          <Route exact path="/signup" component={SignUp} />
          <Route exact path="/profile" component={MyProfile} />
          <Route exact path="/order-item/:productId" component={OrderItem} />
          <Route exact path="/edit-order-item/:productId" component={EditOrderItem} />
          <Route exact path="/restaurant/:restaurantId/:categoryId" component={SingleCategory} />
          <Route exact path="/restaurant/:restaurantId" component={SingleCategory} />
          <Route exact path="/search/:key" component={SearchResult} />
          <Route exact path="/order-page-one" component={OrderPageOne} />
          <Route exact path="/order-page-two" component={OrderPageTwo} />
          <Route exact path="/my-details" component={MyDetails} />
          <Route exact path="/add-order-item" component={AddOrderItem} />
          <Route exact path="/order-list" component={OrderList} />
          <Route exact path="/order-details/:orderId" component={OrderDetails} />
          <Route exact path="/payment-info" component={PaymentInformationPage} />
          <Route exact path="/payments" component={PaymentGateway} />
          <Route exact path="/sumupPayment" component={SumupPayment} />
          <Route exact path="/paylandsPayment" component={PaylandsPayment} />
          <Route exact path="/paylandsPayment/response/:status" component={PaylandsPaymentStatus} />
          <Route exact path="/order-placed/:status" component={OrderPlaced} />
          <Route exact path="/user-inbox" component={UserInbox} />

          <Route exact path="/kitchen" component={KitchenLogin} />
          <Route exact path="/kitchenDashboard" component={KitchenDashboard} />
          <Route exact path="/kitchen/order-list" component={KitchenOrderList} />
          <Route exact path="/kitchen/order-item/:restaurantId/:orderId" component={KitchenOrderItem} />

          <Route exact path="/customer-support" component={CSLogin} />
          <Route exact path="/customer-support/dashboard" component={CSDashboard} />
          <Route exact path="/customer-support/order-history" component={CSOrderHistory} />
          <Route exact path="/customer-support/order-details/:orderId" component={CSOrderDetails} />
          <Route exact path="/customer-support/take-order" component={CSTakeOrder} />
          <Route exact path="/customer-support/promotions" component={Promotions} />

          <Route exact path = "/tnc" component={TnC} />
          <Route exact path="/printer" component={ThermalPrinter2} />
        </Switch>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;