import React, { Component } from "react";
import { Link } from "react-router-dom";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import Button from "@material-ui/core/Button";
import "./BackButton.scss";

export default class BackButton extends Component {
  render() {
    return (
      <div className="back">
        <Link to={this.props.link}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<NavigateBeforeIcon />}
          >
            {this.props.text}
          </Button>
        </Link>
      </div>
    );
  }
}
