import React, { Component } from "react";
import { Link } from "react-router-dom";

import Grid from "@material-ui/core/Grid";
import "./SmallItemCard.scss";
import Paper from "@material-ui/core/Paper";

export default class SmallItemCard extends Component {
  render() {
    return (
      <div className="item-card-small">
        <Link to={`/order-item/${this.props.productId}`}>
          <Paper elevation={5}>
            <div className="item-cover" style={{
              backgroundImage: `url(${this.props.productThumbnailURL})`
            }}>
            </div>
          </Paper>
          <div className="details">
            <div className="single-item-name">{this.props.productName}</div>
          </div>
        </Link>
      </div >
    );
  }
}
