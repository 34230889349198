import { OrderActions } from "../constants/actions";

const defaultState = {
  deliveryStatusButton: true,
  pickUpStatusButton: false,
  walkInStatusButton: false,
};

const alert = (state = defaultState, action) => {
  if (action && action.payload) {
    switch (action.type) {
      case OrderActions.ORDER_TYPE:
        const { orderType } = action.payload;

        switch (orderType) {
          case 'delivery':
            return {
              deliveryStatusButton: true,
              pickUpStatusButton: false,
              walkInStatusButton: false,
            }

          case 'pickup':
            return {
              deliveryStatusButton: false,
              pickUpStatusButton: true,
              walkInStatusButton: false,
            }

          case 'walk-in':
            return {
              deliveryStatusButton: false,
              pickUpStatusButton: false,
              walkInStatusButton: true,
            }

          default:
            return { ...defaultState }
        }

      default:
        return { ...state };
    }
  }

  return state;
};

export default alert;
