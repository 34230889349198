import React, { Component } from 'react';
import style from './OrderList.module.scss';
import classnames from 'classnames';

import { withRouter } from "react-router";
import masterApi from '../../../../../core/api/master-api/master-api';
import customerOrderApi from '../../../../../core/api/customer-order-api/customer-order.api';
import time from '../../../../../core/lib/time';
import Constant, { orderStatus } from '../../../../../core/contants';

import filterIcon from '../../../img/filter.svg';

// import orderApi from '../../../../../core/api/order-api/order-api';
import DriverPrint from '../../../../EPOS/DriverPrint';
import sound from '../../../../../assets/sound.mp3'
import CustomAlert from '../../../../../shared/Alert/Alert';
import { Snackbar } from '@material-ui/core';
import orderApi from '../../../../../core/api/order-api/order-api';
import * as OC from './components';
import { filterDescription } from '../../../shared/Utils/common';

let lastFilter = null;

class OrderList extends Component {
    PosPrintRef =  React.createRef();

    state = {
        orderHistory: [],
        selectedOrderId: null,
        selectedOrderDetails:null,
        newOrders:[],
        soundAlert:false,
        newOrderAlert:false
    }

    componentDidMount() {
        this.fetchOrderHistory(false);
        this.intervalRef = setInterval(() => {
            this.fetchOrderHistory(true);
        }, 10000);

        var context = new AudioContext();
            if(context.state !== 'running'){
                this.setState({soundAlert:true})
            }

    }

    componentWillUnmount(){
        clearInterval(this.intervalRef)
    }

    componentDidUpdate() {
        const { filter } = this.props;

        if (filter !== lastFilter) {
            this.fetchOrderHistory();
        }
    }

    enableSound(){
        var context = new AudioContext();
        context.resume().then(() => {
            console.log('Playback resumed successfully');
        });
        this.setState({soundAlert:false});
    }

    playSound(){
        let audio = new Audio(sound);
          audio.play()
          
    }

    async fetchOrderHistory(isInterval) {
        const { filter,onGetNewOrder } = this.props;
        const { newOrders} = this.state;
        // console.log("FIlter",filter)
        let filter2 = filter === Constant.ReadyCollectionOrder?Constant.ReadyOrder:filter;
        const orderHistory = await customerOrderApi.getOrderHistory(filter2);
        lastFilter = filter;
        // For new order Alert
            const newOrderHistory = await customerOrderApi.getOrderHistory(0);
            let isNewOrder = false;
            if(isInterval && newOrderHistory.length > 0 && newOrderHistory.length > newOrders.length){
                this.playSound();
                onGetNewOrder();
                isNewOrder = true;
            }
        //**** END */

        this.setState({
            orderHistory,
            newOrders:newOrderHistory,
            newOrderAlert:isNewOrder
        });

    
    }

    fetchOrderByIdAndPrintReceipt = async (selectedOrderId,doPrint=true) =>{
        const orderDetails = await orderApi.getOrderDetails(selectedOrderId);
        // console.log(orderDetails)
        this.setState({
            selectedOrderDetails:orderDetails
        },()=>{
            if(doPrint)
            this.PosPrintRef.current.Print(false);
        });
    }

    markCollected = async (orderId) => {
        await customerOrderApi.markCollected(orderId);
        this.fetchOrderHistory();
    }

    markAcknowledge = async (orderId) => {
        await customerOrderApi.updateOrderState(orderId, 1);
        this.fetchOrderByIdAndPrintReceipt(orderId);
        this.fetchOrderHistory();
    }

    isDanger(order) {
        const { filter } = this.props;

        if (filter === 0) {
            const duration = time.getDuration(order.orderPlacedOn) / time.timeUnits.Minute;

            if (duration > 10) {
                return true;
            }
        }

        if (filter === 2) {
            // const duration = Date.now() - new Date(order.orderPlacedOn).getTime() / time.timeUnits.Minute;

            // if (duration > 15) {
            //     return true;
            // }
        }

        return false;
    }

    routeOrderDetails = (orderId) => {
        this.props.history.push(`/customer-support/order-details/${orderId}`);
    }

    handleCloseNewOrderAlert(){
        this.setState({newOrderAlert:false})
    }

    render() {
        const { 
            selectedOrderDetails,
            soundAlert,
            newOrderAlert,
            orderHistory,
        } = this.state;
        const { filter } = this.props;
 
        return (
            <>
                <CustomAlert 
                    title="Alert!"
                    description="Please click enable to start alert sound."
                    open={soundAlert}
                    okButtonText="Enable"
                    onOkClick={()=>this.enableSound()}
                />
                <Snackbar
                    open={newOrderAlert}
                    onClose={()=>this.handleCloseNewOrderAlert()}
                    message="New Order Available"
                    key="GrowTransition"
                    autoHideDuration={6000}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                />
                <div className={style.container}>
                    <div className={(filter === Constant.ReadyOrder || filter === Constant.ReadyCollectionOrder)?style.deliveryStyle:style.title}>
                        Order List <img src={filterIcon} alt="" />  <span className={style.filterLabel}> {filterDescription[filter]} </span>
                    </div>

                    <div>
                        {     
                            filter === null &&  
                            <OC.AllOrdersList 
                                orderHistory={orderHistory}
                                filter={filter}
                                markAcknowledge={this.markAcknowledge}
                                routeOrderDetails={this.routeOrderDetails}
                                isDanger={(order)=>this.isDanger(order)}
                            />
                        }
                        {     
                            filter === Constant.NewOrder &&  
                            <OC.NewOrderList 
                                orderHistory={orderHistory}
                                filter={filter}
                                markAcknowledge={this.markAcknowledge}
                                routeOrderDetails={this.routeOrderDetails}
                                isDanger={(order)=>this.isDanger(order)}
                                fetchOrderByIdAndPrintReceipt={this.fetchOrderByIdAndPrintReceipt}
                            />
                        }
                        {     
                            filter === Constant.AcknowledgedOrder &&  
                            <OC.AckOrderList 
                                orderHistory={orderHistory}
                                filter={filter}
                                routeOrderDetails={this.routeOrderDetails}
                                isDanger={(order)=>this.isDanger(order)}
                            />
                        }
                        {     
                            filter === Constant.CookingOrder &&  
                            <OC.CookingOrderList 
                                orderHistory={orderHistory}
                                filter={filter}
                                routeOrderDetails={this.routeOrderDetails}
                                isDanger={(order)=>this.isDanger(order)}
                            />
                        }
                        {     
                            filter === Constant.ReadyOrder &&  
                            <OC.ReadyOrderList 
                                orderHistory={orderHistory}
                                filter={filter}
                                markCollected={this.markCollected}
                                fetchOrderByIdAndPrintReceipt={this.fetchOrderByIdAndPrintReceipt}
                                routeOrderDetails={this.routeOrderDetails}
                                isDanger={(order)=>this.isDanger(order)}
                                fetchOrderHistory = {()=>this.fetchOrderHistory()}
                                print ={()=>this.PosPrintRef.current.Print(false)}
                                setSelectedOrderDetails = {(order)=>this.setState({selectedOrderDetails:order})}
                            />
                        }
                        {     
                            filter === Constant.ReadyCollectionOrder &&  
                            <OC.ReadyOrderCollectionList 
                                orderHistory={orderHistory}
                                filter={filter}
                                markCollected={this.markCollected}
                                fetchOrderByIdAndPrintReceipt={this.fetchOrderByIdAndPrintReceipt}
                                routeOrderDetails={this.routeOrderDetails}
                                isDanger={(order)=>this.isDanger(order)}
                                print ={()=>this.PosPrintRef.current.Print(false)}
                                fetchOrderHistory = {()=>this.fetchOrderHistory()}
                                selectedOrderDetails={selectedOrderDetails}
                            />
                        }
                        {     
                            filter === Constant.CollectedOrder &&  
                            <OC.CompletedOrderList 
                                orderHistory={orderHistory}
                                filter={filter}
                                routeOrderDetails={this.routeOrderDetails}
                                isDanger={(order)=>this.isDanger(order)}
                                fetchOrderByIdAndPrintReceipt={this.fetchOrderByIdAndPrintReceipt}

                            />
                        }
                        {     
                            filter === orderStatus.PendingOrder &&  
                            <OC.PendingOrderList 
                                orderHistory={orderHistory}
                                filter={filter}
                                routeOrderDetails={this.routeOrderDetails}
                                fetchOrderHistory = {()=>this.fetchOrderHistory(true)}
                            />
                        }
                        
                    </div>

                </div>
                <DriverPrint 
                    ref={this.PosPrintRef} 
                    data={selectedOrderDetails} 
                />


            </>
        )
    }
}

export default withRouter(OrderList);
