import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'
import React from 'react'

export default function CustomSelect({
    label="No Label",
    onChange=()=>{},
    value="",
    name="",
    options=[{value:'',label:''}],
    disabled=false,
    style={width:'100%'},
    required=false,
    error=false,
    variant=''
}) {
  return (
    <FormControl 
        style={style} 
        margin="normal" 
        required={required}
        error={error}
        variant={variant}
    >
        <InputLabel id="select-outlined-label">{label}</InputLabel>
        <Select
            name = {name}
            value={value}
            onChange={onChange}
            disabled={disabled}
            label={label}
        >
            {
                !!options.length && options.map((res,i)=>(
                    <MenuItem value={res['value']} key={i}>{res['label']}</MenuItem>
                ))
            }
    
        </Select>
    </FormControl>
  )
}
