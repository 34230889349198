
const setOrderPrintData =(orderRef,paymentMethod,description)=>{
    localStorage.setItem('OrderPrintData',JSON.stringify({
        orderDetails:orderRef,
        paymentMethod:paymentMethod,
        description:description
    }));
}


export {
    setOrderPrintData
}