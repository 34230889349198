import React, { Component } from "react";
import { connect } from "react-redux";
import "./MyDetails.scss";
import { CartActions } from "../../core/constants/actions";

import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Checkbox from "@material-ui/core/Checkbox";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Select from "@material-ui/core/Select";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import { LinearProgress } from "@material-ui/core";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from '@material-ui/core/DialogTitle';

import "date-fns";
import {
    MuiPickersUtilsProvider,
    KeyboardDateTimePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import SideMenu from "../../shared/SideMenu/SideMenu";
import TopBar from "../../shared/TopBar/TopBar";
import SimpleButton from "../../shared/SimpleButton/SimpleButton";

import SmallItemCard from "../../shared/SmallItemCard/SmallItemCard";

import orderApi from "../../core/api/order-api/order-api";
import authApi from "../../core/api/auth-api/auth-api";
import cartApi from "../../core/api/cart-api/cart-api";
import accountApi from "../../core/api/account-api/account-api";
import masterApi from "../../core/api/master-api/master-api";
import time from "../../core/lib/time";
import { validateHalfPizza } from "../../utils/SideMenuUtils";
import CustomAlert from "../../shared/Alert/Alert";
import moment from "moment";
import { paymentMethod as payMethod, paymentGateway } from "../../core/contants";
import { MainUtils } from "../../utils";
import { setOrderPrintData } from "./Utils";
import { showConfrimBox } from "../../shared/ComfirmAlertBox/ConfirmAlertBox";

class MyDetails extends Component {
    state = {
        authenticated: false,

        user: null,
        isLoading: false,
        isHold: false,
        isClosedChecker: false,
        isClosedState: false,
        email: this.props.walkInStatusButton?"noaccount@pizzeriaplaza.gi":"",
        firstName: this.props.walkInStatusButton?"POS":"",
        lastName: this.props.walkInStatusButton?"Order":"",
        phoneNumber: "",
        addressLine1: "",
        addressLine2: "",
        city: "Gibraltar",
        postCode: "GX11 1AA",
        addressID: null,
        selectedAddress: 10,
        userId: "",
        deliverySlot: 0,
        description: "",
        cartID: "",
        paymentMethod: payMethod.card,
        todayChecked: true,
        deliveryDate: null,
        discount: 0,
        usePoints: false,
        couponCode: "string",
        redeemValue: 0,

        addresses: null,

        emailError: false,
        firstNameError: false,
        lastNameError: false,
        phoneNumberError: false,
        addressLine1Error: false,
        addressLine2Error: false,
        cityError: false,
        postCodeError: false,
        emailErrorText: "",
        firstNameErrorText: "",
        lastNameErrorText: "",
        phoneNumberErrorText: "",
        addressLine1ErrorText: "",
        addressLine2ErrorText: "",
        cityErrorText: "",
        postCodeErrorText: "",
        scrollRef: React.createRef(),
        isCartEmpty: false,

        foodHubPoints: 0,
        randomProducts: [],
        zoneName: 0,
        locationName: 0,
        restaurantZones: [],
        zoneNames: [],
        locationNames: [],
        maxPrepTime: 0,
        maxDelay: 0,
        isAmountLow:false,

        customAlertFlag:false,
        customAlertMsg:"",
        cardConfirmBox:false,
        isOnlineOrderAllowed:false,
        isOnlinePaymentAllowed:true
    };

    componentDidMount = () => {
        this.isCartEmptyChecker();
        this.fetchUserDetails();
        // console.log("ddd",this.props.cartFinalTotal,localStorage.getItem('totalCartAmount'));
    };

    setLoadingFalse =()=>{
        this.setState({isLoading:false});
    }

    setCustomAlert(open,message){
        this.setState({
            customAlertFlag:open,
            customAlertMsg:message
        })
    }

    isCartEmptyChecker = () => {
        if (this.props.cart.length === 0) {
            this.setState({
                isCartEmpty: true,
            });
            this.props.history.push("/order-page-one");
        } else {
            this.setState({
                isCartEmpty: false,
            });
        }
    };

    fetchUserDetails = async () => {
        if (await authApi.isAuthenticated()) {
            this.setState({
                authenticated: true,
                isLoading: true,
            });

            const user = authApi.getUser();

            let today = new Date();

            if (user.roleDescription === "POS") {
                this.setState({
                    user,
                    userId: user.userId,
                    deliveryDate: today,
                    isLoading: false,
                }, () => {
                    this.fetchLoyaltyPoints();
                });
            } else {  // for customer 
                const isOnlinePaymentAllowed =  await cartApi.isOnlinePaymentAllowed();

                this.setState({
                    user,
                    userId: user.userId,
                    email: user.email,
                    firstName: user.firstName,
                    lastName: user.lastName,
                    phoneNumber: user.phoneNumber,
                    deliveryDate: today,
                    isLoading: false,
                    paymentMethod:isOnlinePaymentAllowed?payMethod.card:payMethod.cash,
                    isOnlinePaymentAllowed:isOnlinePaymentAllowed
                }, () => {
                    this.fetchLoyaltyPoints();
                });
            }

            this.fetchUserAddresses();
        }
        else {
            this.setState({
                paymentMethod: 1
            })
        }

        const cart = await cartApi.getCartDetails();
        const isOnlineOrderAllowed = await masterApi.getOnlineOrderAllowed();
        this.setState({
            cartID: cart.cart[0].cartID,
            maxPrepTime: cart.cart[0].maxPrepTimeMins,
            maxDelay: cart.cart[0].maxKitchenDaley,
            deliveryDate: time.getMinRequestTime(cart.cart[0].maxPrepTimeMins, cart.cart[0].maxKitchenDaley),
            isOnlineOrderAllowed
        }, () => {
            this.fetchRandomProduct();
            this.fetchRestaurantZone();
        })
    };

    fetchRandomProduct = async () => {
        const { cartID } = this.state;
        const randomProducts = await cartApi.getRandomProducts(cartID);
        this.setState({
            randomProducts: randomProducts,
        })
    }

    fetchRestaurantZone = async () => {
        const restaurantZones = await masterApi.getRestaurantZone();
        const zoneNames = [...new Set(restaurantZones.map(item => item.zoneName))];
        let locationNames = restaurantZones.filter(function (i) {
            return i.zoneName === zoneNames[0]
        });
        locationNames = [...new Set(locationNames.map(item => item.locationName))];
        this.setState({
            restaurantZones: restaurantZones,
            zoneNames: zoneNames,
            locationNames: locationNames
        })
    }

    fetchLoyaltyPoints = async () => {
        const { user } = this.state;
        const foodHubPoints = await accountApi.getPoints(user.userId);
        this.setState({
            foodHubPoints
        })
    }

    fetchUserAddresses = async () => {
        const { userId } = this.state;
        const addresses = await accountApi.getAddresses(userId);

        if (addresses && addresses.length) {
            this.setState(
                {
                    addresses,
                },
                () => {
                    const activeAddress = addresses.find((i) => i.isActive);

                    if (activeAddress) {
                        this.setState({
                            addressID: activeAddress.id,
                            addressLine1: activeAddress.addressLine1,
                            addressLine2: activeAddress.addressLine2,
                            city: activeAddress.city,
                            postCode: activeAddress.postCode,
                            isLoading: false,
                        });
                    }
                }
            );
        }
    };

    componentWillReceiveProps = (nextProps) => {
        this.scrollToTop();
        if (nextProps.cart.length === 0) {
            this.setState({
                isCartEmpty: true,
            });
            this.props.history.push("/order-page-one");
        } else {
            this.setState({
                isCartEmpty: false,
            });
        }
    };

    fetchDeliveryCharge = async () => {
        return await masterApi.getDeliveryCharge()
    }

    getCartTotal = async() => {
        const { cart, } = this.props;
        let total = 0;
        cart.map((item) => (total = total + item.quantity * item.unitPrice));

        const deliveryCharge = await this.fetchDeliveryCharge();
        return total+Number(deliveryCharge);
    };

    handleProceedToPayClick = () =>{
        const {paymentMethod} = this.state;
        const { walkInStatusButton} = this.props;
        this.setState({isLoading:true})

        if(paymentMethod === payMethod.card && !walkInStatusButton){
            this.toggleCardConfirmBox();
        }else{
            this.handleProceedToPay();
        }
    };

    handleProceedToPay = async () => {
        const { cart,deliveryStatusButton,walkInStatusButton } = this.props;
        const { deliveryDate } = this.state;

        // Half and half pizza validation
        const halfPizzaValidate = validateHalfPizza(cart);
        // console.log(halfPizzaValidate)
        if(!halfPizzaValidate.success){
            this.setState({isLoading:false},()=>{
                this.setCustomAlert(true,halfPizzaValidate.errMessage);
            })
            return;
   
        }
        //******End */
        // const cartTotal = await this.getCartTotal()
        // if( cartTotal< 10 && deliveryStatusButton){
        //     this.setState({isAmountLow:true,isLoading:false},()=>{
        //         setTimeout(() => this.setState({ isAmountLow: false }), 3000)
        //     });
        //     return
        // }

        //Toda:if future order amount is less than 50
        var iscurrentDate = moment(deliveryDate).isSame(new Date(), "day");
            if(!iscurrentDate && this.getCartTotal() < 50) {
                // console.log("today....",iscurrentDate)
                this.setState({isLoading:false},()=>{
                    this.setCustomAlert(true,'You can only order for future dates for orders of £50')
                })
                return

            }
        //********ENd ***** */

        if(!walkInStatusButton){
            await this.fetchHoldState()
        }

        const { isHold } = this.state

        if (!isHold) {
            this.orderCheckout()
        }
        else {
            setTimeout(() => this.setState({ isHold: false,isLoading:false }), 3000)
        }
    }

    fetchHoldState = async () => {
        const { cart } = this.props
        const restaurants = await masterApi.getRestaurants();
        const selectedRestaurants = []
        const mapNames = new Map();
        for (const item of cart) {
            if (!mapNames.has(item.restaurantId)) {
                mapNames.set(item.restaurantId, true);
                selectedRestaurants.push(item.restaurantId);
            }
        }
        for (let restaurant of restaurants) {
            if (selectedRestaurants.includes(restaurant.id)) {
                if (!restaurant.isAllowOrder) {
                    // console.log(restaurant)
                    this.setCustomAlert(!restaurant.isAllowOrder,`${restaurant.name} is closed, please check later.`)
                    this.setState({ isHold: !restaurant.isAllowOrder })
                    break;
                }

            }
        };
    }

    fetchKitchenCloseState = async (reqTime) => {
        const { cart } = this.props
        reqTime =time.getCurrentDateTimeWithDate(reqTime);
        const available = await masterApi.checkRestaurantOpen(cart[0].restaurantId, reqTime)
        this.setState({
            isClosedChecker: !available,
            isClosedState: !available
        })
        setTimeout(() => this.setState({ isClosedChecker: false }), 3000);
        return available

    }

    orderCheckout = async () => {
        const {
            authenticated,
            email,
            firstName,
            lastName,
            phoneNumber,
            addressLine1,
            addressLine2,
            city,
            postCode,
        } = this.state;
        const {
            addressID,
            deliverySlot,
            description,
            foodHubPoints,
            paymentMethod,
            cartID,
            usePoints,
            user,
            todayChecked,
            zoneName,
            locationName,
            restaurantZones,
            zoneNames,
            locationNames,
            maxPrepTime,
            maxDelay
        } = this.state;
        let { deliveryDate } = this.state
        const { deliveryStatusButton, walkInStatusButton, pickUpStatusButton } = this.props

        let emailErrorText = "";
        let firstNameErrorText = "";
        let lastNameErrorText = "";
        let phoneNumberErrorText = "";
        let addressLine1ErrorText = "";
        let addressLine2ErrorText = "";
        let cityErrorText = "";
        let postCodeErrorText = "";

        let emailError = false;
        let firstNameError = false;
        let lastNameError = false;
        let phoneNumberError = false;
        let addressLine1Error = false;
        let addressLine2Error = false;
        let cityError = false;
        let postCodeError = false;

        if (!email) {
            emailError = true;
            emailErrorText = "Email cannot be empty";
        }

        if (!firstName) {
            firstNameError = true;
            firstNameErrorText = "First Name cannot be empty";
        }

        if (!lastName) {
            lastNameError = true;
            lastNameErrorText = "Last Name cannot be empty";
        }

        if (!phoneNumber && !walkInStatusButton) {
            phoneNumberError = true;
            phoneNumberErrorText = "Phone number cannot be empty";
        }

        if (deliveryStatusButton) {
            if (!addressLine1) {
                addressLine1Error = true;
                addressLine1ErrorText = "Address Line 1 cannot be empty";
            }

            if (!addressLine2) {
                addressLine2Error = true;
                addressLine2ErrorText = "Address Line 2 cannot be empty";
            }

            if (!city) {
                cityError = true;
                cityErrorText = "City cannot be empty";
            }
            if (!postCode) {
                postCodeError = true;
                postCodeErrorText = "Post code cannot be empty";
            }
        }



        let pattern = new RegExp(
            /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
        );

        if (!pattern.test(email) && !walkInStatusButton) {
            emailError = true;
            emailErrorText = "Please enter valid email address.";
        }
        const minDate = time.getMinRequestTime(maxPrepTime, maxDelay)
        deliveryDate = todayChecked ? minDate
            : deliveryDate.getTime() > minDate.getTime() ? deliveryDate
                : minDate;

        // const isRestOpen = await this.fetchKitchenCloseState(deliveryDate);
        // if(!isRestOpen)
        //     return

        let data = {
            addressID: !!addressID ? addressID : '',
            addressLine1: addressLine1,
            addressLine2: addressLine2,
            city: city,
            postCode: postCode,
            firstName: firstName,
            lastName: lastName,
            contactNo: phoneNumber?phoneNumber:'0000000000',
            deliverySlot: deliverySlot,
            paymentMethod: paymentMethod,
            email: email?email:'na@na.com',
            description: description,
            deliveryDate: deliveryDate,
            discount: 0,
            couponCode: "string",
            foodHubPoints: usePoints,
            redeemValue: usePoints ? foodHubPoints : 0,
            cartId: cartID,
            ramdomCardId: authenticated ? null : cartApi.getRandomCartId(),
            isNeedOfAddress: deliveryStatusButton ? true : false,
            deliveryZoneLocation: deliveryStatusButton ? {
                restaurantId: restaurantZones[0]?.restaurantId,
                zoneName: zoneNames[zoneName],
                locationName: locationNames[locationName],
            } : null,
            orderType: deliveryStatusButton ? 0 : pickUpStatusButton ? 1 : 2,
            amount:this.props.cartFinalTotal
        };
        if (
            !emailError &&
            !firstNameError &&
            !lastNameError &&
            !phoneNumberError &&
            !addressLine1Error &&
            !cityError &&
            !postCodeError
        ) {
            try {
                if (walkInStatusButton) {
                    data = { ...data, restaurantId: user.restaurantId }
                    const res = await orderApi.posCheckout(data);
                    this.setLoadingFalse();
                    this.getAndSetPrintData(res.orderId,data.paymentMethod,data.description);
             
                }
                else {
                    const res = await orderApi.checkout(data);
                    console.log("CHeckout Response", res);
                    this.setLoadingFalse();

                    if(res && res.orderId && paymentMethod !== 1){
                        this.getAndSetPrintData(res.orderId,data.paymentMethod,data.description);
                    }
                    else if (paymentMethod === 1) {
                        if(res.success){
                            // console.log(responseDecode)
                            // localStorage.setItem("cartOrder", JSON.stringify(data));
                            localStorage.setItem("orderId", JSON.stringify(res.orderId));
                            if(res.gateway === paymentGateway.sumup){                                
                                const responseDecode = JSON.parse(res.responseMessage);
                                localStorage.setItem("checkout_id",responseDecode.id);
                                this.props.history.push(`/sumupPayment?orderId=${res.orderId}&checkoutId=${responseDecode.id}`);
                            }
                            else if(res.gateway === paymentGateway.paylands){
                                localStorage.setItem("checkout_id",res.checkoutId);
                                this.props.history.push(`/paylandsPayment?orderId=${res.orderId}&checkoutId=${res.checkoutId}`);
                            }
                        }else{
                            // console.warn("Failed",responseDecode)
                        }
                    }
                }

                this.props.usePoints(false, foodHubPoints)

            } catch (err) {
                this.setLoadingFalse();
                const errorMsg = err.response && err.response.data?err.response.data?.['Message']:'Interal Server Error, Please try again.'
                showConfrimBox({
                    title:errorMsg,
                    onCancelClick:false
                })
                // console.error(err.response,err?.response?.data);
            }
        } else {
            this.setState({
                emailError: emailError,
                emailErrorText: emailErrorText,
                firstNameError: firstNameError,
                firstNameErrorText: firstNameErrorText,
                lastNameError: lastNameError,
                lastNameErrorText: lastNameErrorText,
                phoneNumberError: phoneNumberError,
                phoneNumberErrorText: phoneNumberErrorText,
                addressLine1Error: addressLine1ErrorText,
                addressLine2Error: addressLine2ErrorText,
                cityError: cityError,
                postCodeError: postCodeError,
                addressLine1ErrorText: addressLine1ErrorText,
                addressLine2ErrorText: addressLine2ErrorText,
                cityErrorText: cityErrorText,
                postCodeErrorText: postCodeErrorText,
                isLoading:false
            });
            this.scrollToTop();
        }
    };

    //TODO:This function used to get and set Order Details and redirect to confirm order screen
    async getAndSetPrintData(orderId,paymentMethod,description){
        const orderRef = await orderApi.getOrderDetails(orderId)
        setOrderPrintData(orderRef,paymentMethod,description)
        localStorage.removeItem("checkout_id");
        this.props.history.push("/order-placed/success");
    }

    isAddressSelectDisabled() {
        const { addresses } = this.state;
        return !(addresses && addresses.length);
    }

    getAddresses() {
        const { addresses } = this.state;

        const view = [
            <MenuItem value={null} key={0}>
                New Address
            </MenuItem>,
        ];

        if (addresses && addresses.length) {
            for (let address of addresses) {
                view.push(
                    <MenuItem value={address.id} key={address.id}>
                        {address.addressLine1}
                    </MenuItem>
                );
            }
        }

        return view;
    }

    handleChange = (event) => {
        event.persist();

        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    handlePhoneChange = (event) => {
        event.persist();
        const onlyNums = event.target.value.replace(/[^0-9]/g, '');
        // if (onlyNums.length < 12) {
        this.setState({ [event.target.name]: onlyNums });
        // }

    };

    handleZoneChange = (event) => {
        const { restaurantZones, zoneNames } = this.state
        event.persist();
        let locationNames = []

        if (event.target.value !== "") {
            locationNames = restaurantZones.filter(function (i) {
                return i.zoneName === zoneNames[event.target.value]
            });
            locationNames = [...new Set(locationNames.map(item => item.locationName))];
        }
        this.setState({
            [event.target.name]: event.target.value,
            locationNames: locationNames,
            locationName: 0,
        });
    };

    handleTickChange = (event) => {
        this.setState({
            [event.target.name]: event.target.checked,
        });
    };

    handleUsePoints = (event) => {
        const { foodHubPoints } = this.state;
        const usePoints = event.target.checked;

        this.setState({
            usePoints: event.target.checked,
        });

        this.props.usePoints(usePoints, foodHubPoints)
    };

    handleDateChange = (date) => {
        const { maxPrepTime, maxDelay } = this.state
        const reqDate = new Date(date)
        const minDate = time.getMinRequestTime(maxPrepTime, maxDelay)
        if (reqDate.getTime() > minDate.getTime()) {
            this.setState({
                deliveryDate: reqDate,
            });
            // this.fetchKitchenCloseState(reqDate)
        }
        else {
            this.setState({
                deliveryDate: minDate,
            });
            // this.fetchKitchenCloseState(minDate)
        }

    };

    handlePaymentChange = (event) => {
        let pay = 0;
        event.persist();
        console.log(event.target.value)
        this.setState({
            paymentMethod: Number(event.target.value),
        });
    };

    handleAddressChange = (event) => {
        event.persist();

        if (event.target.value) {
            const { addresses } = this.state;
            const selectedAddress = addresses.find((i) => event.target.value === i.id);
            if (selectedAddress) {
                this.setState({
                    addressID: selectedAddress.id,
                    addressLine1: selectedAddress.addressLine1,
                    addressLine2: selectedAddress.addressLine2,
                    city: selectedAddress.city,
                    postCode: selectedAddress.postCode,
                });
            }
        } else {
            this.setState({
                addressID: null,
                addressLine1: "",
                addressLine2: "",
                city: "Gibraltar",
                postCode: "GX11 1AA",
            });
        }
    };

    scrollToTop = () => {
        if (this.state.scrollRef.current) {
            this.state.scrollRef.current.scrollTo(0, 0);
        }
    };

    renderRoomForMore() {
        const { randomProducts } = this.state
        return randomProducts.map((i, j) => {
            if(MainUtils.isPosOnlyProduct(i.productName))
            return (
                <Grid item md={4} lg={2} key={j} >
                    <SmallItemCard productName={i.productName} productId={i.productId} productThumbnailURL={i.productThumbnailURL} />
                </Grid>
            )
        })
    }

    renderLocationToolTip() {
        const { locationNames } = this.state
        return locationNames.map((location, i) => {
            return (
                    <div key={i}>
                        {location}
                    </div>
            )
        })
    }

    toggleCardConfirmBox = () =>{
        this.setState({cardConfirmBox:!this.state.cardConfirmBox})
    }

    isCashEnable(){
        const {authenticated} = this.state;
        const { walkInStatusButton, pickUpStatusButton, deliveryStatusButton} = this.props;
        return (authenticated || authApi.isCS_guest_user()) &&  !pickUpStatusButton;
    }

    render() {
        const {
            authenticated,
            paymentMethod,
            addressLine1,
            addressLine2,
            city,
            postCode,
            addressID,
            // selectedAddress,
            foodHubPoints,
            deliveryDate,
            todayChecked,
            isLoading,
            scrollRef,
            isCartEmpty,
            isClosedState,
            usePoints,
            // locationName,
            zoneName,
            // locationNames,
            zoneNames,
            isHold,
            isClosedChecker,
            isAmountLow,
            description,
            customAlertFlag,
            customAlertMsg,
            cardConfirmBox,
            user,
            isOnlineOrderAllowed,
            isOnlinePaymentAllowed
        } = this.state;
        const { walkInStatusButton, pickUpStatusButton, deliveryStatusButton,cartFinalTotal} = this.props;
        return (
            <div className="my-details-container">
                <div className="section">
                    <div className="top">
                        <TopBar />
                    </div>
                    {isLoading && !user? (
                        <LinearProgress />
                    ) : (
                        <div className="child-1" ref={scrollRef}>
                            <form onSubmit={this.orderCheckout}>
                                <div className="form-section">
                                    <div className="title">My Details</div>
                                    <div className="details">
                                        {deliveryStatusButton ? (
                                            <div>
                                                <div className="field-title">DELIVER TO</div>

                                                {
                                                    authenticated && (
                                                        <FormControl variant="outlined">
                                                            <Select
                                                                onChange={this.handleAddressChange}
                                                                disabled={
                                                                    this.isAddressSelectDisabled() ||
                                                                    walkInStatusButton
                                                                }
                                                                name="addressID"
                                                                fullWidth
                                                                value={walkInStatusButton ? null : addressID}
                                                            >
                                                                {/* <MenuItem value={null}> New Address </MenuItem> */}
                                                                {this.getAddresses()}
                                                            </Select>
                                                        </FormControl>
                                                    )
                                                }

                                                <div className="split-form">
                                                    <div className="left">
                                                        <TextField
                                                            onChange={this.handleChange}
                                                            error={this.state.addressLine1Error}
                                                            helperText={this.state.addressLine1ErrorText}
                                                            value={walkInStatusButton ? "" : addressLine1}
                                                            name="addressLine1"
                                                            placeholder="Address line 1"
                                                            variant="outlined"
                                                            disabled={!!addressID || !deliveryStatusButton}
                                                            fullWidth
                                                        />
                                                    </div>

                                                    <div className="right">
                                                        <TextField
                                                            onChange={this.handleChange}
                                                            error={this.state.addressLine2Error}
                                                            helperText={this.state.addressLine2ErrorText}
                                                            value={walkInStatusButton ? "" : addressLine2}
                                                            disabled={!!addressID || !deliveryStatusButton}
                                                            name="addressLine2"
                                                            placeholder="Address line 2"
                                                            variant="outlined"
                                                            fullWidth
                                                        />
                                                    </div>

                                                    <div className="left">
                                                        <TextField
                                                            onChange={this.handleChange}
                                                            error={this.state.cityError}
                                                            helperText={this.state.cityErrorText}
                                                            value={walkInStatusButton ? "" : city}
                                                            disabled={!!addressID || !deliveryStatusButton}
                                                            name="city"
                                                            placeholder="City"
                                                            variant="outlined"
                                                            fullWidth
                                                            inputProps={{readOnly:true}}
                                                        />
                                                    </div>

                                                    <div className="right">
                                                        <TextField
                                                            className="half"
                                                            onChange={this.handleChange}
                                                            error={this.state.postCodeError}
                                                            helperText={this.state.postCodeErrorText}
                                                            value={walkInStatusButton ? "" : postCode}
                                                            disabled={!!addressID || !deliveryStatusButton}
                                                            name="postCode"
                                                            placeholder="Postal code"
                                                            variant="outlined"
                                                            fullWidth
                                                            inputProps={{readOnly:true}}
                                                        />
                                                    </div>

                                                    <div className="left">
                                                        <div>
                                                            <div className="field-title">
                                                                Delivery Zone
                                                            </div>
                                                            <div className="deliveryZone">
                                                                <FormControl variant="outlined">
                                                                    <Select
                                                                        onChange={this.handleZoneChange}
                                                                        value={zoneName}
                                                                        name="zoneName"
                                                                        fullWidth
                                                                        disabled={!deliveryStatusButton || zoneNames.length === 0}
                                                                    >
                                                                        {
                                                                            zoneNames.map((zone, i) => (
                                                                                <MenuItem value={i} key={i}>{zone}</MenuItem>
                                                                            ))
                                                                        }
                                                                    </Select>
                                                                </FormControl>
                                                                <Tooltip
                                                                    title={
                                                                        <React.Fragment>
                                                                            <div><b>Locations within the Zone :</b></div>
                                                                            {this.renderLocationToolTip()}
                                                                        </React.Fragment>
                                                                    }
                                                                    placement="right-start"
                                                                >
                                                                    <InfoOutlinedIcon fontSize='small' htmlColor='#6495ED' />
                                                                </Tooltip>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <div></div>
                                        )}

                                        {!walkInStatusButton ? (
                                            <div>
                                                <div className="field-title">
                                                    WHEN WOULD YOU LIKE {pickUpStatusButton ? "TO COLLECT" : "YOUR DELIVERY"}?
                                                </div>
                                                <div className="split-form">
                                                    <div className="left">
                                                        <div className="split-form delivery">
                                                            <div className="left">
                                                                <Grid>
                                                                    <Checkbox
                                                                        // onChange={this.handleTickChange}
                                                                        name="todayChecked"
                                                                        // disabled={walkInStatusButton}
                                                                        checked={todayChecked}
                                                                    />
                                                                    ASAP
                                                                </Grid>
                                                            </div>
                                                            <div className="right">
                                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                    <KeyboardDateTimePicker
                                                                        variant="inline"
                                                                        autoOk={true}
                                                                        ampm={false}
                                                                        label="Select Date"
                                                                        value={deliveryDate}
                                                                        disabled={walkInStatusButton || todayChecked}
                                                                        onChange={this.handleDateChange}
                                                                        disablePast
                                                                        format="dd/MM/yyyy HH:mm"
                                                                    />
                                                                </MuiPickersUtilsProvider>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="right"></div>
                                                </div>
                                            </div>
                                        ) : (
                                            <div></div>
                                        )}
                                        <div>
                                            <div className="field-title">MY DETAILS</div>
                                            <div className="split-form">
                                                <div className="left">
                                                    <TextField
                                                        onChange={this.handleChange}
                                                        error={this.state.firstNameError}
                                                        helperText={this.state.firstNameErrorText}
                                                        value={this.state.firstName}
                                                        name="firstName"
                                                        placeholder="First Name"
                                                        variant="outlined"
                                                        fullWidth
                                                    />
                                                </div>

                                                <div className="right">
                                                    <TextField
                                                        onChange={this.handleChange}
                                                        value={this.state.lastName}
                                                        error={this.state.lastNameError}
                                                        helperText={this.state.lastNameErrorText}
                                                        name="lastName"
                                                        placeholder="Last Name"
                                                        variant="outlined"
                                                        fullWidth
                                                    />
                                                </div>

                                                <div className="left">
                                                    <TextField
                                                        onChange={this.handleChange}
                                                        error={this.state.emailError}
                                                        helperText={this.state.emailErrorText}
                                                        value={this.state.email}
                                                        name="email"
                                                        placeholder="Email"
                                                        variant="outlined"
                                                        fullWidth
                                                    />
                                                </div>

                                                <div className="right">
                                                    <TextField
                                                        onChange={this.handlePhoneChange}
                                                        error={this.state.phoneNumberError}
                                                        helperText={this.state.phoneNumberErrorText}
                                                        value={this.state.phoneNumber}
                                                        name="phoneNumber"
                                                        placeholder="Contact number"
                                                        variant="outlined"
                                                        fullWidth
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="field-title">SPECIAL NOTES</div>
                                            <TextField
                                                onChange={this.handleChange}
                                                name="description"
                                                className="multiline-textbox"
                                                multiline
                                                rows={5}
                                                placeholder="Extra information for the delivery driver"
                                                variant="outlined"
                                                fullWidth
                                            />
                                        </div>
                                        <div>
                                            <div className="field-title">PAYMENT METHOD</div>
                                            <FormControl component="fieldset">
                                                <RadioGroup
                                                    row
                                                    aria-label="position"
                                                    name="position"
                                                    value={paymentMethod}
                                                    onChange={this.handlePaymentChange}
                                                >
                                                    {
                                                        this.isCashEnable() &&
                                                        <FormControlLabel
                                                            value={payMethod.cash}
                                                            control={<Radio color="primary" />}
                                                            label="Cash"
                                                        />
                                                    }
                                                    {
                                                        (isOnlinePaymentAllowed || walkInStatusButton) &&
                                                        <FormControlLabel
                                                            value={payMethod.card}
                                                            control={<Radio color="primary" />}
                                                            label="Card"
                                                        />
                                                    }
                                                </RadioGroup>
                                            </FormControl>
                                        </div>
                                    </div>
                                    <Divider variant="fullWidth" />

                                    {
                                        authenticated && !walkInStatusButton && (
                                            <>
                                                <div className="sub-details">
                                                    <div className="name">FOOD HUB POINTS</div>
                                                    <div> {foodHubPoints} </div>
                                                </div>

                                                <Divider variant="fullWidth" />

                                                <div className="sub-details">
                                                    <div className="name">REDEEM VALUE</div>
                                                    <div>£{(foodHubPoints / 100).toFixed(2)}</div>
                                                </div>

                                                <Divider variant="fullWidth" />
                                                <div className="sub-details">
                                                    <div className="name">USE POINTS</div>
                                                    <div>
                                                        <Checkbox
                                                            onChange={this.handleUsePoints}
                                                            name="usePoints"
                                                            checked={usePoints}
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    }


                                </div>

                                <div className="row-item">
                                    <div className="title">ROOM FOR MORE?</div>

                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="space-evenly"
                                        alignItems="flex-start"
                                    >
                                        {this.renderRoomForMore()}
                                    </Grid>
                                </div>


                                {
                                    isOnlineOrderAllowed && 
                                    <div className="actions">
                                        { (!isOnlinePaymentAllowed && pickUpStatusButton) &&
                                            <div className="field-title-error">
                                                Pick up order is not available now.
                                            </div>
                                        }
                                        <SimpleButton
                                            disabled={isCartEmpty || isClosedState || isLoading || (!isOnlinePaymentAllowed && pickUpStatusButton)}
                                            onFunction={this.handleProceedToPayClick}
                                            text={ walkInStatusButton ? "COMPLETE ORDER" : "PROCEED TO PAYMENT"}
                                        />
                                    </div>
                                }

                            </form>
                        </div>
                    )}
                </div>
                <div className="side-menu-container">
                    <SideMenu />
                </div>
                <Dialog open={isClosedChecker}>
                    <DialogTitle >Alert</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Restaurant has not opened yet,  Please try after some time
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
                <Dialog open={isAmountLow}>
                    <DialogTitle >Alert</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Minimum order value for delivery is £10.00
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
                <CustomAlert
                    open={customAlertFlag}
                    title="Alert!"
                    description={customAlertMsg}
                    onOkClick={()=>{
                        this.setCustomAlert(false,'')
                    }}
                />
                <CustomAlert
                    open={cardConfirmBox}
                    title="You are about to pay by card for your order."
                    description="Do not close the app until the app shows a screen to confirm your order has been created."
                    okButtonText="Continue"
                    onOkClick={()=>{
                        this.toggleCardConfirmBox();
                        this.handleProceedToPay();
                    }}
                    onCancelClick={()=>{
                        this.setLoadingFalse();
                        this.toggleCardConfirmBox();
                    }}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { walkInStatusButton, pickUpStatusButton, deliveryStatusButton, } = state.orderReducer;
    const { cart,cartFinalTotal } = state.cartReducer;

    return {
        walkInStatusButton,
        pickUpStatusButton,
        deliveryStatusButton,
        cart,
        cartFinalTotal
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        usePoints: (usePoints, points) => {
            dispatch({
                type: CartActions.USE_POINTS,
                payload: {
                    usePoints,
                    points
                },
            });
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyDetails);
