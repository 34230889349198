import httpApi from "../http-api/http-api";
import authApi from "../auth-api/auth-api";

class CustomerOrderApi {
  getOrderHistory = async (status,data) => {
    const sentObj = data ? data : {status};
    const props = { authToken: authApi.getCSToken() };

    const itemsPerCategory = await httpApi.sendPostRequest(
      `/api/CustomerOrder/GetOrderHistory`,
      sentObj,props
    );

    return itemsPerCategory;
  };

  async assignDriver(orderId, driverId) {
    const response = await httpApi.sendPostRequest(
      `/api/CustomerOrder/${orderId}/AssignDriverToOrder/${driverId}`,
      null, {
      authToken: authApi.getCSToken()
    }
    );
    return response
    console.log(response);
  }

  async markCollected(orderId) {
    const response = await httpApi.sendPostRequest(
      `/api/CustomerOrder/${orderId}/MarkOrderToCollected`,
      null, {
      authToken: authApi.getCSToken()
    }
    );
    console.log(response);
  }

  async getOrderDetails(id) {
    const response = await httpApi.sendGetRequest(
      `/api/CustomerOrder/GetOrderDetail/${id}`, {
      authToken: authApi.getCSToken()
    }
    );
    return response;
  }

  async getUserByMobile(mobile) {
    const response = await httpApi.sendGetRequest(
      `/api/CustomerOrder/GetUser/${mobile}`, {
      authToken: authApi.getCSToken()
    }
    );
    return response;
  }

  async addNote(id, note) {
    const response = await httpApi.sendGetRequest(
      `/api/CustomerOrder/${id}/UpdateNote/${note}`, {
      authToken: authApi.getCSToken()
    }
    );
    console.log(response);
  }

  async updateOrderState(id, state) {
    const response = await httpApi.sendPutRequest(
      `/api/CustomerOrder/${id}/ChangeOrderStatus?orderStatus=${state}`, {
      authToken: authApi.getCSToken()
    }
    );
  }
}

export default new CustomerOrderApi();
