/**
 * Stop propagation will avoid flowing click event to the parent element which will avoid the closing dialog
 * when dialog content is clicked
 * 
 * https://stackoverflow.com/questions/1369035/how-do-i-prevent-a-parents-onclick-event-from-firing-when-a-child-anchor-is-cli
 */

import React, { Component } from 'react';
import style from './Dialog.module.scss';

export default class Dialog extends Component {
    onClose = () => {
        const { onClose } = this.props;

        if (onClose) {
            onClose();
        }
    }

    render() {
        const { isOpen } = this.props;

        if (isOpen) {
            return (
                <div className={style.dialogOverlay} onClick={this.onClose}>
                    <div className={style.dialogContent} onClick={e => e.stopPropagation()}>
                        {this.props.children}
                    </div>
                </div>
            )
        }
        
        return null;
    }
}
