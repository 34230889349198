import { AuthApi, CommonApi } from "..";
import httpApi from "../http-api/http-api"

class ProductApi {
    addItem = async (cartItem) => {
        await httpApi.sendPostRequest('/Cart/AddCartItem', cartItem);
    }

    getProduct = async (productId) => {
        const product = await httpApi.sendPostRequest(`/Product/GetProduct?productId=${productId}`);
        return product;
    }

    searchByProductName = async (productName) => {
        let url = `/Product/SearchProducts?productName=${productName}`;
        const isPOS = AuthApi.isPosUser();
        if(isPOS){
            const restaurantId = CommonApi.posRestaurantId();
            url =  url+`&restaurantId=${restaurantId}`;
        }
        const result = await httpApi.sendPostRequest(url);
        return result;
    }

}

export default new ProductApi()