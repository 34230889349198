import React, { Component } from 'react';
import style from './PaymentInformation.module.scss';
import orderApi from '../../core/api/order-api/order-api';
import Button from "@material-ui/core/Button";
import { showConfrimBox } from '../../shared/ComfirmAlertBox/ConfirmAlertBox';
import { CommonApi } from '../../core/api';
import { paymentGateway } from '../../core/contants';
import httpApi from '../../core/api/http-api/http-api';

export default class SumupPayment extends Component {
    state = {
        checkoutId:null,
        amount:null,
        currency:null,
        isLoading:true,
        showBackButton:false,
        orderId:null,
        isMobile:false //used to detect is site working in webview
    }


    componentDidMount() {
        //this.triggerCard();
        let query = this.getQuery();
        const checkoutId=query.get('checkoutId')
        const orderId=query.get('orderId')
        const isMobile=query.get('isMobile');
        const token = query.get('token');
        if(token)
            httpApi.setToken(token);
        this.setData(checkoutId,isMobile,orderId);
    }

    async setData(checkoutId,isMobile,orderId){
        // console.log("Retrive checkout",checkoutId)
        const orderCheckoutDetails = await orderApi.retrieveOrderCheckout(orderId);
        if(orderCheckoutDetails.gateway != paymentGateway.sumup){
            this.props.history.push(`/payments/${orderId}?isMobile=${isMobile}`);
        }
        
        localStorage.setItem("orderId", JSON.stringify(orderId));
        const retrieveSumupCheckoutRef = await orderApi.retrieveSumupCheckout(checkoutId);
        const responseDecode = JSON.parse(retrieveSumupCheckoutRef.responseMessage);
        // console.log("responseDecode",responseDecode)

        const amount = String(responseDecode.amount);
        const currency = responseDecode.currency;
        this.setState({checkoutId,amount,currency,isMobile,orderId:retrieveSumupCheckoutRef?.['orderId']},()=>{
            this.startSumUpCheckout();
        });
        // console.log("on payment page ",responseDecode)
    }
    getQuery=()=> {
        const {location} = this.props;
        return new URLSearchParams(location.search);
    }

    startSumUpCheckout=()=>{
        const {checkoutId,currency,amount,orderId,isMobile} = this.state;
        // console.log(checkoutId,currency,amount);
        const callback = () =>{
            if(isMobile){
                this.props.history.push('/order-placed/order-success');
            }else{
                this.props.history.push('/order-placed/success');
            }
        }
        const setState =(obj)=>{
            this.setState(obj);
        }
       
        window.SumUpCard.mount({
            checkoutId: checkoutId,
            amount:amount,
            currency:currency,
            onResponse: async (type, body)=> {
                // console.log('Type', type);
                // console.log('Body', body);
                CommonApi.auditLog({cartId:null,orderId:orderId,message:`Webview: Sumup SDK Reponse : Type:${JSON.stringify(type)} && Body: ${JSON.stringify(body)}`})
                if(type === 'success' && body && (body.status === 'PAID')){
                    callback();
                }
                else if(type === 'success' && body && body.status === "FAILED"){
                    localStorage.removeItem("orderId");
                    this.displayStatusFailedAlert()
                    // setState({showBackButton:true});
                }
                else if(type === 'error'){
                    this.displayErrorAlert(body)
                }
                else if(type === 'sent'){
                    setState({showBackButton:false});
                }   
            },
            onLoad:function(){
                setState({isLoading:false,showBackButton:true});
            }
        });
    }

    displayStatusFailedAlert =async ()=>{
        const {checkoutId} = this.state;
        // console.log("show confirm box error");
        orderApi.retrieveSumupCheckout(checkoutId);

        showConfrimBox({
            title:'Payment failed!',
            description:'The payment was unsuccessful due to an abnormality. Please try again.',
            onOkClick:()=>{
                this.props.history.push('/my-details');
            },
            onCancelClick: false
        })
    }

    displayErrorAlert = (body='')=>{
        showConfrimBox({
            title:'Payment failed!',
            description:JSON.stringify(body),
            onOkClick:()=>{ },
            onCancelClick: false
        })
        this.setState({showBackButton:true});
    }

    cancelPayment = (order)=>{
        showConfrimBox({
            title:'Are you sure you want to go back to cart?',
            description:'',
            okButtonText:"YES",
            onOkClick:async ()=>{
                if(this.state.isMobile){
                    this.props.history.push('/cancel-payment');
                }else{
                    await orderApi.cancelOrderPayment(this.state.orderId);
                    this.props.history.push('/order-page-one');
                }
            }
        })
    }
    render() {
        const {isLoading,showBackButton} = this.state;
        return (
            <div className={style.container}>
               
                <div className={style.section}>
                    <div className={style.displayBackButton}>
                        <div className={style.backbutton} >
                            {
                                showBackButton && 
                                <Button className={style.cancelBtn} variant="contained" color="primary" onClick={this.cancelPayment}>
                                    Back
                                </Button>
                            }
                        </div>
                    </div>
                   
                    <div id="sumup-card"></div>
                    {
                       isLoading && <h5 style={{textAlign:'center'}}>Loading please wait....</h5>
                    }
                </div>
            </div>
        )
    }
}
