import { Button,FormControl, InputLabel, MenuItem, Radio, Select, TextField } from '@material-ui/core';
import React from 'react';
import style from '../OrderList.module.scss';
import Dialog from '../../../../../../shared/Dialog/Dialog';
import CustomAlert from '../../../../../../shared/Alert/Alert';
import { paymentMethod } from '../../../../../../core/contants';
import { convertToLast3digit } from '../../../../../../utils/MainUtils';
const CollectionOrderDialoge = ({
    params,
    isDialogOpen,
    toggleDialogOpen,
    orderData,
    confirmCollectionHandle,
    
}) => {
    const [paymentOption, setPaymentOption] = React.useState(null);
    const [confirmCollecetion, setConfirmCollecetion] = React.useState(false);
    const [error, setError] = React.useState(null);

    const handleChange = (event) => {
        setPaymentOption(event.target.value);
        setError(null)
    };

    const handleCollectionConfirm = () =>{
        setConfirmCollecetion(true)

        // if(paymentOption || orderData.paymentMethod === 1){
        //     setConfirmCollecetion(true)
        // }
        // else{
        //     setError("Please Select Payment Method.")
        // }
    }

    const onConfirmCollection = () =>{
        setConfirmCollecetion(false);
        confirmCollectionHandle(paymentOption);

    }

    if(!orderData)
        return <></>
    return (
        <Dialog isOpen={isDialogOpen}>
            <div className={style.dialogBox}>
                <div className={style.title}>
                    Collection Order
                </div>

                <div className={style.form}>
                        <TextField 
                            id="1" 
                            label="Order Id" 
                            variant="standard"
                            value={`#${convertToLast3digit(orderData.orderNumber)}`}
                            fullWidth
                            inputProps={{ readOnly: true }}
                        />
                        <TextField 
                            id="1" 
                            label="Customer Name" 
                            variant="standard"
                            value={orderData.userName}
                            fullWidth
                            inputProps={{ readOnly: true }}
                            style={{marginTop:15}}
                        />

                        <TextField 
                            id="1" 
                            label="Cost" 
                            variant="standard"
                            value={`£${orderData.totalPrice.toFixed(2)}`}
                            fullWidth
                            inputProps={{ readOnly: true }}
                            style={{marginTop:15}}
                        />
                        <p className={style.radioTitle}>Payment Method</p>
                        <p className={style.title2}>{orderData.paymentMethod === paymentMethod.card?"Paid Online":"Pay by cash on collection"}</p>
                       {/* { orderData.paymentMethod === 0 &&
                            <div className={style.radioContainer}>
                                <Radio
                                    checked={paymentOption === 'card'}
                                    onChange={handleChange}
                                    value="card"
                                    label
                                    name="radio-buttons"
                                    style={{ marginLeft: "-11px"}}
                                />
                                <span>Card</span>
                                <Radio
                                    checked={paymentOption === 'cash'}
                                    onChange={handleChange}
                                    value="cash"
                                    name="radio-buttons"
                                    style={{marginLeft: 10}}
                                />
                                <span>Cash</span>
                            </div>
                        } */}
                        {
                            !!error &&
                            <p style={{color:"red"}}>{error}</p>
                        }
                </div>

                <div className={style.tools}>
                    <Button
                        variant="contained"
                        onClick={handleCollectionConfirm}
                        color="primary"
                        style={{fontSize: "smaller"}}
                    >
                        Collection Confirmed
                    </Button>
                    <Button
                        variant="contained"
                        onClick={()=>{
                            toggleDialogOpen();
                            setPaymentOption(null);
                            setError(null)
                        }}
                        color="primary"
                        style={{fontSize: "smaller",marginLeft: "15px"}}
                    >
                        Cancel
                    </Button>
                </div>

            </div>
            <CustomAlert 
                open={confirmCollecetion}
                title="Are you sure?"
                description="You want to complete this order."
                onOkClick={onConfirmCollection}
                onCancelClick={()=>setConfirmCollecetion(false)}
                okButtonText="YES"
                cancelButtonText="NO"
            />
        </Dialog>
    )
};

export default CollectionOrderDialoge;
