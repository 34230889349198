import React, { Component } from "react";
import "./SearchResult.scss";

import SideMenu from "../../shared/SideMenu/SideMenu";
import TopBar from "../../shared/TopBar/TopBar";
import BackButton from "../../shared/BackButton/BackButton";
import TermsBar from "../../shared/TermsBar/TermsBar";
import LinearProgress from "@material-ui/core/LinearProgress";

import MediumItemCard from "../../shared/MediumItemCard/MediumItemCard";

import productApi from "../../core/api/product-api/product-api";
import { MainUtils } from "../../utils";

export default class SearchResult extends Component {
  state = {
    key: "",
    itemsPerCategory: null,
    searchError: ''
  };

  componentDidMount = () => {
    if (!!this.props.match.params.key) {
      this.setState({
        key: this.props.match.params.key,
      });
    }
    this.fetchProducts();
  };

  componentDidUpdate() {
    if (this.state.key !== this.props.match.params.key) {
      this.fetchProducts();
    }
  }

  fetchProducts = async () => {
    const key = this.props.match.params.key;
    const itemsPerCategory = await productApi.searchByProductName(key);
    const filterdItemsPerCategory = itemsPerCategory.filter((res)=>MainUtils.isPosOnlyProduct(res.productName)); 

    this.setState({
      key: key,
      itemsPerCategory: filterdItemsPerCategory,
      isLoading: false,
    });
  };

  render() {
    const { itemsPerCategory, key, isLoading } = this.state;
    return (
      <div className="search-result-container">
        <div className="section">
          <div className="top">
            <TopBar searchKey={key} />
          </div>
          <div className="child-1">
            <div className="row-1">
              <div className="left">
                {itemsPerCategory && itemsPerCategory.length > 0 && (
                  <div className="title">Search result of "{key}"</div>
                )}
                {itemsPerCategory && itemsPerCategory.length == 0 && !isLoading &&  (
                  <div className="subtitle">no results found, search again</div>
                )}
              </div>
              <div className="right">
                <div className="back">
                  <BackButton text="BACK TO HOME" link="/order-page-one" />
                </div>
              </div>
            </div>
            <div className="row-2">
              {itemsPerCategory && itemsPerCategory.map((item, i) => (
                    <MediumItemCard
                      key={i}
                      productId={item.productId}
                      productName={item.productName}
                      productDescription={item.Description}
                      productThumbnailURL={item.productThumbnailURL}
                      price={item.price}
                    />
                  ))}
            </div>
          </div>
          <div className="footer">
            <TermsBar />
          </div>
        </div>
        <div className="side-menu-container">
          <SideMenu />
        </div>
      </div>
    );
  }
}
