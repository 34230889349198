import React, { Component } from "react";
import "./TopBar.scss";
import authApi from "../../core/api/auth-api/auth-api";
import { connect } from "react-redux";
import { CommonActions, OrderActions } from "../../core/constants/actions";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import logo from "../../assets/logo.png";
import options from "../../assets/options.png";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import MailIconOutline from "@material-ui/icons/MailOutlineOutlined";
import AssignmentTurnedInOutlinedIcon from '@material-ui/icons/AssignmentTurnedInOutlined';
import PersonIcon from "@material-ui/icons/PersonOutlineOutlined";
import SimpleButton from "../SimpleButton/SimpleButton";
import { Badge, Grid } from "@material-ui/core";
import masterApi from "../../core/api/master-api/master-api";
import { CustomerMessages } from "../../core/api";
import cartApi from "../../core/api/cart-api/cart-api";

class TopBar extends Component {
  state = {
    open: false,
    searchKey: "",
    isPOS: false,
    searchError: false,
    isAllowOnlineOrder:false,
    isOnlinePaymentAllowed:false
  };

  componentDidMount() {
    this.fetchUserDetails();
  }

  fetchUserDetails = async () => {
    const user = await authApi.getUser();
    const isAllowOnlineOrder = await masterApi.getOnlineOrderAllowed();
    const isOnlinePaymentAllowed =  await cartApi.isOnlinePaymentAllowed();
    const notReadMessageCount = await CustomerMessages.getNotReadCount();
    let searchKey = "";

    this.props.updateNotReadMessageCount(notReadMessageCount?notReadMessageCount['count']:0)

    this.setState({isAllowOnlineOrder:isAllowOnlineOrder,isOnlinePaymentAllowed})

    if (!!this.props.searchKey) {
      searchKey = this.props.searchKey;
      this.setState({
        searchKey: searchKey,
      });
    }

    if (user.roleDescription === "POS") {
      this.setState(
        {
          isPOS: true,
        },
        () => {
          this.props.toggleWalkIn();
        }
      );
    }
  };

  handleDrawerOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleDrawerClose = () => {
    this.setState({
      open: false,
    });
  };

  handleChange = (event) => {
    event.persist();

    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleLogout = () => {
    authApi.logout();
  };

  onSearchSubmitHandler = (e) => {
    e.preventDefault();
    const { searchKey } = this.state;

    if (searchKey) {
      this.props.history.push(`/search/${searchKey}`);

      this.setState({
        searchError: false
      })
    } else {
      this.setState({
        searchError: true
      })
    }
  };

  render() {
    const {
      deliveryStatusButton,
      pickUpStatusButton,
      walkInStatusButton,
      label='',
      notReadMessageCount
    } = this.props;

    const { searchKey, isPOS, searchError,isAllowOnlineOrder,isOnlinePaymentAllowed } = this.state;

    return (
      <div className="top-bar-container">
        <div className="top-bar">
          <div className="logo">
            <Link to={"/order-page-one"}>
              <img src={logo} alt="logo" />
            </Link>
          </div>
         {<div className="title">{label}</div>}
          <form onSubmit={this.onSearchSubmitHandler}>
            <TextField
              name="searchKey"
              onChange={this.handleChange}
              className="search-bar"
              variant="outlined"
              disabled={!isAllowOnlineOrder}
              fullWidth
              value={searchKey}
              placeholder="Search"
              error={searchError}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={this.onSearchSubmitHandler}
                      type="submit"
                    >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </form>
          <div className="simple">
            {!isPOS &&
              <SimpleButton
                onFunction={() => {
                  this.props.toggleDelivery();
                }}
                selected={deliveryStatusButton}
                variant={deliveryStatusButton?'contained':'outlined'}
                text="DELIVERY"
              />
            }

            {
              !isPOS && isOnlinePaymentAllowed &&
              <SimpleButton
                onFunction={() => {
                  this.props.togglePickup();
                }}
                selected={pickUpStatusButton}
                variant={pickUpStatusButton?'contained':'outlined'}
                text="PICKUP"
              />
            }

            {isPOS ? (
              <SimpleButton
                onFunction={() => {
                  this.props.toggleWalkIn();
                }}
                selected={walkInStatusButton}
                text="WALK-IN"
              />
            ) : (
              <div></div>
            )}
          </div>
          <Link to="/user-inbox" className="mailCount">
            <Badge color="primary" badgeContent={notReadMessageCount} >
              <MailIconOutline color="primary" />
            </Badge>
          </Link>


          <div className="options">
            <IconButton onClick={this.handleDrawerOpen}>
              <img src={options} alt="option" />
            </IconButton>
          </div>
        </div>
        <Drawer variant="persistent" anchor="right" open={this.state.open}>
          <div className="drawer-container">
            <div>
              <IconButton onClick={this.handleDrawerClose}>
                <NavigateNextIcon />
              </IconButton>
            </div>
            <Divider />

            {authApi.authenticated ? (
              <List>
                <Link to="/profile">
                  <ListItem button>
                    <Grid
                      container
                      direction="row"
                      justify="space-between"
                      alignItems="center"
                    >
                      {/* <img src={UserIcon} alt="userIcon" /> */}
                      <PersonIcon color="primary" />
                      <div className="item-name">MY PROFILE</div>
                      <NavigateNextIcon />
                    </Grid>
                  </ListItem>
                </Link>
                <Divider />
                {!isPOS ? (
                  <>
                    <Link to="/order-list">
                      <ListItem button>
                        <Grid
                          container
                          direction="row"
                          justify="space-between"
                          alignItems="center"
                        >
                          {/* <img src={UserIcon} alt="userIcon" /> */}
                          <AssignmentTurnedInOutlinedIcon color="primary" />
                          <div className="item-name">MY ORDERS</div>
                          <NavigateNextIcon />
                        </Grid>
                      </ListItem>
                    </Link>
                    <Divider />
                    <Link to="/user-inbox">
                      <ListItem button>
                        <Grid
                          container
                          direction="row"
                          justify="space-between"
                          alignItems="center"
                        >
                          <MailIconOutline color="primary"/>
                          <div className="item-name">INBOX</div>
                          <NavigateNextIcon />
                        </Grid>
                      </ListItem>
                    </Link>
                  </>

                ) : (
                  <div></div>
                )}
                <Divider />
              </List>
            ) : (
              <div className="no-login">Please login to see your orders</div>
            )}
          </div>

          <div className="logout">
            {authApi.authenticated ? (
              <Button
                variant="contained"
                onClick={this.handleLogout}
                color="primary"
                endIcon={<ExitToAppIcon />}
              >
                LOGOUT
              </Button>
            ) : (
              <Button
                variant="contained"
                onClick={this.handleLogout}
                color="primary"
                endIcon={<ExitToAppIcon />}
              >
                LOGIN
              </Button>
            )}
          </div>
        </Drawer>

        <Divider variant="fullWidth" />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    deliveryStatusButton,
    pickUpStatusButton,
    walkInStatusButton,
  } = state.orderReducer;
  const {notReadMessageCount} = state.commonReducer

  return {
    deliveryStatusButton,
    pickUpStatusButton,
    walkInStatusButton,
    notReadMessageCount
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleDelivery: () => {
      dispatch({
        type: OrderActions.ORDER_TYPE,
        payload: {
          orderType: "delivery",
        },
      });
    },
    togglePickup: () => {
      dispatch({
        type: OrderActions.ORDER_TYPE,
        payload: {
          orderType: "pickup",
        },
      });
    },
    toggleWalkIn: () => {
      dispatch({
        type: OrderActions.ORDER_TYPE,
        payload: {
          orderType: "walk-in",
        },
      });
    },
    updateNotReadMessageCount:(count)=>{
      dispatch({
        type:CommonActions.UPDTE_READ_COUNT,
        payload:{
          count
        }
      })
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TopBar));
