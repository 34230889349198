import moment from 'moment';
import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { convertToLast3digit } from '../../utils/MainUtils';
import './Epos.scss'

const PosPrint = forwardRef((props,ref) => {
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
    });
  
    useImperativeHandle(ref, () => ({

        Print() {
          handlePrint()
        }
    
      }));

    return (
      <div>
           <div style={{ display: "none" }}>
            <ComponentToPrint ref={componentRef} data={props.data} />
           </div>
        {/* <button onClick={handlePrint}>Print this out!</button> */}
      </div>
    );
  });

export default PosPrint

export class ComponentToPrint extends React.PureComponent {
    render() {
    const data = this.props.data;
    const orderDetails = data.posOrderDetails;

    if(!orderDetails){
        return <div></div>
    }
      return (
        <div style={{
                    padding:'2%',
                    display:'flex',
                    justifyContent:'center',
                    flexDirection:'column',
                    paddingLeft:'7%'
                }}>
            <h2 style={{margin:0}}>TAKE AWAY</h2>
            <h3 style={{margin:0}}>{ orderDetails.userName}</h3>
            {/* <h3 style={{margin:0}}>{data.deliveryAddress}</h3> */}
            {/* <h3 style={{margin:0}}>{data.postCode}</h3> */}
            <h4 style={{margin:0}}>Order No: {convertToLast3digit(orderDetails.orderNumber)}</h4>
            <table>
                <tbody>
                    <tr>
                        <td colSpan="3"> <hr /></td>
                    </tr>
                    {
                        orderDetails.cartItems.length &&
                        orderDetails.cartItems.map((p,i)=>(
                            <>
                                <tr>
                                    <th>{p.quantity}</th>
                                    <th>x {p.productName}</th>
                                    <td>£{p.totalPrice.toFixed(2)}</td>
                                </tr>
                                <tr key={i}>
                                    <td></td>
                                    <td colSpan="2">
                                        Size : {p.productSizeVarientName}
                                    </td>
                                </tr>
                                {
                                    p.productPreferenceOptions.map((prf,i)=>(
                                        <tr key={i}>
                                            <td></td>
                                            <td colSpan="2">
                                                {prf.productPreference} : {prf.productPreferenceOption}
                                            </td>
                                        </tr>
                                    ))
                                
                                }
                                {
                                    !!p.addedProductIngredients && !!p.addedProductIngredients.length &&
                                    <tr>
                                        <td></td>
                                        <td colSpan="2">Added Ingredients :</td>
                                    </tr>
                                }
                                {
                                    p.addedProductIngredients.map((ing,i)=>(
                                        <tr key={i}>
                                            <td></td>
                                            <td colSpan="2"><span>&nbsp;&nbsp;</span> + {ing.ingredient}</td>
                                        </tr>
                                    ))
                                
                                }
                                {
                                    !!p.deletedProductIngredients && !!p.deletedProductIngredients.length &&
                                    <tr>
                                        <td></td>
                                        <td colSpan="2">Removed Ingredients :</td>
                                    </tr>
                                }
                                {
                                    p.deletedProductIngredients.map((ing,i)=>(
                                        <tr key={i}>
                                            <td></td>
                                            <td colSpan="2"><span>&nbsp;&nbsp;</span>  - {ing.ingredient}</td>
                                        </tr>
                                    ))
                                
                                }
                            </>
                        ))
                    }
                    <tr>
                        <td colSpan="3"> <hr /></td>
                    </tr>
                    <tr>
                        <td colSpan="2">Subtotal</td>
                        <td>£{orderDetails.totalPrice.toFixed(2)}</td>
                    </tr>
                    {/* <tr>
                        <td colSpan="2">Delivery</td>
                        <td>£{data.deliveryCharge}</td>
                    </tr> */}
                    <tr>
                        <td colSpan="2">Total</td>
                        <td>£{orderDetails.totalPrice.toFixed(2)}</td>
                    </tr>
                    <tr>
                        <td colSpan="3"> <hr /></td>
                    </tr>
                    <tr>
                        <td colSpan="3"> Paid by {data.paymentMethod === 0?'Cash':'Card'}</td>
                    </tr>
                    <tr>
                        <td colSpan="3">{moment().format("DD.MM.YYYY HH:mm") }</td>
                    </tr>
                </tbody>
            </table>
            {
                !!data.description &&
                <p><b>Special Note :</b> {data.description}</p>
            }
        </div>
      );
    }
  }

 