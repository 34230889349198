// ORDER STATE

const NewOrder = 0;
const AcknowledgedOrder = 1;
const CookingOrder = 2;
const ReadyOrder = 3;    
const CollectedOrder = 4;
const PaymentPending = 6;
const ReadyCollectionOrder = 5; //TODO: only used in web to seperate collection and delivery orders

const customerIssue = 11; //TODO: only for UI used.

export default {
    NewOrder,
    AcknowledgedOrder,
    CookingOrder,
    ReadyOrder,
    CollectedOrder,
    PaymentPending,
    ReadyCollectionOrder,
    customerIssue
}


const paymentMethod ={
    cash:0,
    card:1,
    worldPay:2
}

const OrderTypes ={
    "Delivery":0,
    "Collection":1,
    "WalkIn":2
}

const OrderTypesByNumber ={
    0:"Delivery",
    1:"Collection",
    2:"WalkIn"
}

const orderStatus = {
    "NewOrder":0,
    "AcknowledgedOrder":1,
    "CookingOrder":2,
    "ReadyOrder":3,
    "CollectedOrder":4,
    "CancelledOrder":5,
    "PendingOrder":6,
    "PaymentFailed":7,
    "PaymentCancelled":8
}

const userRoles = {
    "Customer":0,
    "Admin":1,
    "Kitchen":2,
    "POS":3,
    "CustomerService":4,
    "Delivery":5
}

const messageType = {
    broadcast : 0,
    orderMessage : 1
}

const paymentGateway ={
    sumup:0,
    paylands:1
}

export {
    paymentMethod,
    OrderTypes,
    orderStatus,
    userRoles,
    OrderTypesByNumber,
    messageType,
    paymentGateway
}