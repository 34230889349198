import React, { Component } from 'react';
import style from './PaymentInformation.module.scss';
import TopBar from "../../shared/TopBar/TopBar";
import SideMenu from "../../shared/SideMenu/SideMenu";
import TextField from "@material-ui/core/TextField";
import SimpleButton from "../../shared/SimpleButton/SimpleButton";
import orderApi from '../../core/api/order-api/order-api';
import axios from 'axios';
import ConfirmDialog from '../../shared/ConfirmDialog/ConfirmDialog';
import { connect } from "react-redux";

class PaymentInformationPage extends Component {
    state = {
        cardNumber: '',
        cardCVV: '',
        expiryMonth: undefined,
        expiryYear: undefined,

        eCardNumber: '',
        eCardCVV: '',
        eCardExpiryMonth: '',
        eCardExpiryYear: '',
        
        houseNumber: '',
        streetName: '',
        city: '',
        postCode: '',

        eHouseNumber: '',
        eStreetName: '',
        eCity: '',
        ePostCode: '',

        isError: false,
        processPaymentUrl: '',
        is3DConfirmDialogOpen: false,
        md: '',
        paRes: ''
    }

    componentDidMount() {
        //this.triggerCard();
    }

    handleChange = (property) => (e) => {
        const value = e.target.value;

        this.setState({
            [property]: value
        })
    }

    handleSubmit = async (e) => {
        e.preventDefault();

        const { cardNumber, cardCVV, expiryMonth, expiryYear, houseNumber, streetName, city, postCode } = this.state;

        let eCardNumber = '';
        let eCardCVV = '';
        let eCardExpiryYear = '';
        let eCardExpiryMonth = '';
        let eHouseNumber = '';
        let ePostCode = '';


        if (!cardNumber) {
            eCardNumber = 'Required';
        }

        if (!cardCVV) {
            eCardCVV = 'Required';
        }

        if (isNaN(expiryMonth) || expiryMonth < 0 || expiryMonth > 12) {
            eCardExpiryMonth = 'Invalid';
        }

        if (isNaN(expiryYear) || expiryYear < 21 || expiryYear > 100) {
            eCardExpiryYear = 'Invalid';
        }

        if (!houseNumber) {
            eHouseNumber = 'Required'
        }

        if (!postCode) {
            ePostCode = 'Required'
        }

        this.setState({
            eCardNumber,
            eCardCVV,
            eCardExpiryMonth,
            eCardExpiryYear,
            eHouseNumber,
            ePostCode
        })

        if (!eCardNumber && !eCardCVV && !eCardExpiryMonth && !eCardExpiryYear && !ePostCode && !eHouseNumber) {
            const data = JSON.parse(localStorage.getItem("cartOrder"));

            const details = await orderApi.checkout({
                ...data,
                "amount": this.getCartTotal().toFixed(2) * 100,
                "cardNumber": cardNumber,
                "cardCVV": cardCVV,
                "cardExpiryMonth": expiryMonth,
                "cardExpiryYear": expiryYear,
                cardAddress: [houseNumber, streetName, city].join(" ").trim().replace( /\s\s+/g, ' ' ),
                cardPostCode: postCode
            })

            const { processPaymentUrl, paRes, md } = details;

            if (details.suceess) {
                if (details.responseMessage.includes('<html>') !== -1) {
                    this.setState({
                        is3DConfirmDialogOpen: true,
                        processPaymentUrl,
                        paRes,
                        md
                    })
                } else {
                    this.props.history.push('/order-placed/success');
                }
            } else {
                this.setState({
                    isError: true
                })
    }
        }
    }

    triggerCard = async () => {
        const data = JSON.parse(localStorage.getItem("cartOrder"));

        const details = await orderApi.checkout({
            ...data,
            "addressLine1": "16",
            "postCode": "155",
            "amount": 1203,
            "cardNumber": '4012001037141112',
            "cardCVV": "083",
            "cardExpiryMonth": 12,
            "cardExpiryYear": 21
        })

        const { processPaymentUrl, paRes, md } = details;

        if (details.suceess) {
            if (details.responseMessage.includes('<html>') !== -1) {
                this.setState({
                    is3DConfirmDialogOpen: true,
                    processPaymentUrl,
                    paRes,
                    md
                })
            } else {

            }
        } else {
            this.setState({
                isError: true
            })
        }
    }

    handleConfirm = async (confirmed) => {
        const { processPaymentUrl, paRes, md } = this.state;
        const data = JSON.parse(localStorage.getItem("cartOrder"));
        if (confirmed) {
            const apiRef = await axios.post(processPaymentUrl, {
                ...data,
                md,
                paRes,
            })

            console.log(apiRef);

            if (apiRef.status === 200 && apiRef.data['statusCode'] === 200) {
                const resValue = apiRef.data['value'];

                if (resValue && resValue['orderId']) {
                    this.props.history.push('/order-placed/success');
                } else if (resValue && !resValue['success']) {
                    this.setState({
                        isError: true,
                        is3DConfirmDialogOpen: false,
                    })
                }
            } else {
                this.setState({
                    isError: true,
                    is3DConfirmDialogOpen: false,
                })
            }
        } else {
            this.setState({
                isError: true,
                is3DConfirmDialogOpen: false,
            })
        }
    }

    getCartTotal = () => {
        const { cart, } = this.props;
        let total = 0;
        cart.map((item) => (total = total + item.quantity * item.unitPrice));

        return total;
    };

    render() {
        return (
            <div className={style.container}>
                <div className={style.section}>
                    <div className={style.topBar}>
                        <TopBar />
                    </div>

                    <div className={style.body}>
                        <form className={style.form} onSubmit={this.handleSubmit}>
                            <div className={style.title}>
                                Card Information
                            </div>

                            <div className={style.formGroup}>
                                <div className={style.formField}>
                                    <div className={style.fieldTitle}> Card Number </div>

                                    <div className={style.formControl}>
                                        <TextField
                                            className="half"
                                            size="small"
                                            onChange={this.handleChange('cardNumber')}
                                            error={!!this.state.eCardNumber}
                                            helperText={this.state.eCardNumber}
                                            value={this.state.cardNumber}
                                            name="cardNumber"
                                            placeholder="Card Number"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </div>
                                </div>

                                <div className={style.row2}>
                                    <div className={style.formField}>
                                        <div className={style.fieldTitle}> CVV </div>

                                        <div className={style.formControl}>
                                            <TextField
                                                className="half"
                                                size="small"
                                                onChange={this.handleChange('cardCVV')}
                                                error={!!this.state.eCardCVV}
                                                helperText={this.state.eCardCVV}
                                                value={this.state.cardCVV}
                                                name="cardNumber"
                                                // placeholder="Card Number"
                                                variant="outlined"
                                                fullWidth
                                            />
                                        </div>
                                    </div>

                                    <div className={style.formField}>
                                        <div className={style.fieldTitle}> Expiry Date </div>

                                        <div className={style.expiryDate}>
                                            <div className={style.formControl}>
                                                <TextField
                                                    className="half"
                                                    size="small"
                                                    onChange={this.handleChange('expiryMonth')}
                                                    error={!!this.state.eCardExpiryMonth}
                                                    helperText={this.state.eCardExpiryMonth}
                                                    value={this.state.expiryMonth}
                                                    name="cardNumber"
                                                    placeholder="MM"
                                                    variant="outlined"
                                                    fullWidth
                                                />
                                            </div>

                                            <div className={style.formControl}>
                                                <TextField
                                                    className="half"
                                                    size="small"
                                                    onChange={this.handleChange('expiryYear')}
                                                    error={!!this.state.eCardExpiryYear}
                                                    helperText={this.state.eCardExpiryYear}
                                                    value={this.state.expiryYear}
                                                    name="cardNumber"
                                                    placeholder="YY"
                                                    variant="outlined"
                                                    fullWidth
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className={style.doubleRow}>
                                    <div className={style.formField}>
                                        <div className={style.fieldTitle}>
                                            House Number
                                        </div>

                                        <div className={style.formControl}>
                                            <TextField
                                                className="half"
                                                size="small"
                                                onChange={this.handleChange('houseNumber')}
                                                error={!!this.state.eHouseNumber}
                                                helperText={this.state.eHouseNumber}
                                                value={this.state.houseNumber}
                                                variant="outlined"
                                                fullWidth
                                            />
                                        </div>
                                    </div>

                                    <div className={style.formField}>
                                        <div className={style.fieldTitle}>
                                            Street Name
                                        </div>

                                        <div className={style.formControl}>
                                            <TextField
                                                className="half"
                                                size="small"
                                                onChange={this.handleChange('streetName')}
                                                error={!!this.state.eStreetName}
                                                helperText={this.state.eStreetName}
                                                value={this.state.streetName}
                                                variant="outlined"
                                                fullWidth
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className={style.doubleRow}>
                                    <div className={style.formField}>
                                        <div className={style.fieldTitle}>
                                            City
                                        </div>

                                        <div className={style.formControl}>
                                            <TextField
                                                className="half"
                                                size="small"
                                                onChange={this.handleChange('city')}
                                                error={!!this.state.eCity}
                                                helperText={this.state.eCity}
                                                value={this.state.city}
                                                variant="outlined"
                                                fullWidth
                                            />
                                        </div>
                                    </div>

                                    <div className={style.formField}>
                                        <div className={style.fieldTitle}>
                                            Post Code
                                        </div>

                                        <div className={style.formControl}>
                                            <TextField
                                                className="half"
                                                size="small"
                                                onChange={this.handleChange('postCode')}
                                                error={!!this.state.ePostCode}
                                                helperText={this.state.ePostCode}
                                                value={this.state.postCode}
                                                name="House Number"
                                                variant="outlined"
                                                fullWidth
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {
                                this.state.isError && (
                                    <div className={style.errorAlert}>
                                        Error while processing the payment request.
                            </div>
                                )
                            }

                            <div className={style.actions}>
                                <SimpleButton
                                    type="submit"
                                    text="PROCEED TO PAYMENT"
                                />
                            </div>
                        </form>
                    </div>
                </div>

                <div className={style.sideMenuContainer}>
                    <SideMenu />
                </div>

                <ConfirmDialog isOpen={this.state.is3DConfirmDialogOpen} onClose={this.handleConfirm}>
                    Do you want to continue with 3D Payment Verification?
                </ConfirmDialog>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { cart, usePoints, points } = state.cartReducer;
    const { walkInStatusButton, pickUpStatusButton, deliveryStatusButton } = state.orderReducer;
    return {
        cart, usePoints, points, walkInStatusButton,
        pickUpStatusButton,
        deliveryStatusButton,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PaymentInformationPage);