import { Snackbar } from '@material-ui/core';
import React, { Component } from 'react';
import ToastManager from './ToastAlert.manager';
import MuiAlert from '@material-ui/lab/Alert';

// const Color = 'success' | 'info' | 'warning' | 'error';

export function showToast({
    title='',
    type="success",
    onCancelClick=()=>{}
}) {
    const ref = ToastManager.getDefault();
    if (!!ref) {
        ref._showToast({
            title,type,onCancelClick
        });
    }
}

export function closeToast() {
    const ref = ToastManager.getDefault();
    if (!!ref) {
        ref._closeToast();
    }
}


export default class CustomToastAlert extends Component {
    constructor(props) {
      super(props);
      this.state = {
        title:'',
        open:false,
        type:'success',
        onCancelClick:()=>{}
      };
    }
  
      componentDidMount(){
        ToastManager.register(this);
      }
  
      componentWillUnmount() {
        ToastManager.unregister(this);
      }
  
      _showToast = ({
        title='',
        type='success',
        onCancelClick=()=>{}
      } = {}) => {
          this.setState({
            title,type,onCancelClick
          }, () => {
              this.setState({ open: true });
          });

          setTimeout(()=>{
            this._closeToast()
          },4000)
      }
  
      _closeToast = () => {
          this.setState({ open: false });
      }
  
    render() {
        const {
            title,open,type,onCancelClick
      } = this.state;
      return (
        <Snackbar open={open} >
            <Alert onClose={this._closeToast} severity={type}>
               {title}
            </Alert>
        </Snackbar>
      );
    }
  }

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
