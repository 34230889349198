import httpApi from "../http-api/http-api"

class DriverShiftApi {

    checkIn = async (driverId,checkInDate) => {
        const product = await httpApi.sendPostRequest(`/api/DriverShift/driver/${driverId}/checkin?checkin=${checkInDate}`);
        return product;
    }

    checkOut = async (driverId,checkOutDate) => {
        const result = await httpApi.sendPostRequest(`/api/DriverShift/driver/${driverId}/checkout?checkout=${checkOutDate}`);
        return result;
    }

}

export default new DriverShiftApi()