import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./ForgotPassword.scss";

import logo from "../../assets/logo.png";

import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import PersonIcon from "@material-ui/icons/Person";
import LockIcon from "@material-ui/icons/Lock";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import TermsBar from "../../shared/TermsBar/TermsBar";

import authApi from "../../core/api/auth-api/auth-api";

export default class Login extends Component {
  state = {
    userid:'',
    showPassword: false,
    email: "",
    currentpassword:"",
    password: "",
    cPassword: "",
    isMailSent: false,
    code: '',

    emailError: false,
    currentPasswordError:false,
    passwordError: false,
    cPasswordError: false,
    emailErrorText: "",
    passwordErrorText: "",
    cPasswordErrorText: "",
    currentPasswordErrorText:"",
    successMessage: "",
  };

  handleUpdate = async () => {
    let {userid,currentpassword, cPassword, password } = this.state;
    let currentPasswordErrorText='';
    let cPasswordErrorText = "";
    let passwordErrorText = "";
    let currentPasswordError =false;
    let passwordError = false;
    let cPasswordError = false;
    if(!userid)
      return;
    if (!currentpassword) {
      currentPasswordError = true;
      currentPasswordErrorText = "This field cannot be empty";
    }
    if (!cPassword) {
      cPasswordError = true;
      cPasswordErrorText = "This field cannot be empty";
    }

    if (!password) {
      passwordError = true;
      passwordErrorText = "This field cannot be empty";
    }

    if (password != cPassword) {
      passwordError = true;
      passwordErrorText = "Password did not match";
      cPasswordError = true;
      cPasswordErrorText = "Password did not match";
    }

    let data = {
      password: currentpassword,
      newPassword: password,
      conformPassword: cPassword
    }

    if (!cPasswordError && !passwordError) {
      try {
        //reset password here

        // await authApi.login(data);
         const user = await authApi.UpdatePassword(userid,data);
         this.setState({successMessage:"Password is updated"});
        // if (user.roleDescription === "POS") {
        //   this.props.history.push(`/restaurant/${user.restaurantId}`);
        // } else {
        //   this.props.history.push("/order-page-one");
        // }
      } catch (err) {
        this.setState({
          cPasswordError: true,
          passwordError: true,
          errorMessage: err.message,
          password: "",
          passwordErrorText: err.message,
          successMessage:"",
        });
      }
    } else {
      this.setState({
        cPasswordError: cPasswordError,
        cPasswordErrorText: cPasswordErrorText,
        passwordError: passwordError,
        passwordErrorText: passwordErrorText,
        successMessage:"",
      });
    }
  };

  sendMail = async() => {
    const { email } = this.state
    let emailErrorText = "";
    let emailError = false;

    if (!email) {
      emailError = true;
      emailErrorText = "This field cannot be empty";
    }

    let pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );

    if (!pattern.test(email)) {
      emailError = true;
      emailErrorText = "Please enter valid email address.";
    }

    if (!emailError) {
      try {
        //send mail and get code

        // if (user.roleDescription === "POS") {
        //   this.props.history.push(`/restaurant/${user.restaurantId}`);
        // } else {
        //   this.props.history.push("/order-page-one");
        // }
        const user = await authApi.forgotPassword(email)
        this.setState({
          isMailSent: true,
          userid:user.userId,
          successMessage:"Password is send to email!..",
        })
      } catch (err) {
        this.setState({
          emailError: true,
          emailErrorText: err.message,
          successMessage:"",
        });
      }
    } else {
      this.setState({
        emailError: emailError,
        emailErrorText: emailErrorText,
        successMessage:"",
      });
    }

  }

  handleClickShowPassword = () => {
    const { showPassword } = this.state;
    this.setState({
      showPassword: !showPassword,
    });
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  handleChange = async(event) => {
    event.persist();
    this.setState({
      [event.target.name]: event.target.value,
      ssMessage:"",
    });
  };

  render() {
    const {successMessage, email, currentpassword, password, cPassword, isMailSent } = this.state;

    return (
      <div className="reset-container">
        <Paper className="paper">
          <img src={logo} />

          <Divider variant="middle" />
          
          <div className="title">Reset Password</div>
          <form onSubmit={this.handleLogin}>
            {
              isMailSent ? <div className="login-form">
                <div className="form-item">
                  <div className="name">CURRENT PASSWORD</div>
                  <TextField
                    onChange={this.handleChange}
                    error={this.state.currentpasswordError}
                    helperText={this.state.currentpasswordErrorText}
                    name="currentpassword"
                    variant="outlined"
                    value={currentpassword}
                    fullWidth
                    type={this.state.showPassword ? "text" : "password"}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton disabled>
                            <LockIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={this.handleClickShowPassword}
                            onMouseDown={this.handleMouseDownPassword}
                            edge="end"
                          >
                            {this.state.showPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>                
                <div className="form-item">
                  <div className="name">New PASSWORD</div>
                  <TextField
                    onChange={this.handleChange}
                    error={this.state.passwordError}
                    helperText={this.state.passwordErrorText}
                    name="password"
                    variant="outlined"
                    value={password}
                    fullWidth
                    type={this.state.showPassword ? "text" : "password"}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton disabled>
                            <LockIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={this.handleClickShowPassword}
                            onMouseDown={this.handleMouseDownPassword}
                            edge="end"
                          >
                            {this.state.showPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>

                <div className="form-item">
                  <div className="name"> CONFIRM PASSWORD</div>
                  <TextField
                    onChange={this.handleChange}
                    error={this.state.cPasswordError}
                    helperText={this.state.cPasswordErrorText}
                    name="cPassword"
                    variant="outlined"
                    value={cPassword}
                    fullWidth
                    type={this.state.showPassword ? "text" : "password"}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton disabled>
                            <LockIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={this.handleClickShowPassword}
                            onMouseDown={this.handleMouseDownPassword}
                            edge="end"
                          >
                            {this.state.showPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </div>
                :
                <div className="login-form">
                  <div className="form-item">
                    <div className="name">EMAIL</div>
                    <TextField
                      onChange={this.handleChange}
                      error={this.state.emailError}
                      helperText={this.state.emailErrorText}
                      value={email}
                      name="email"
                      variant="outlined"
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton disabled>
                              <PersonIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>

                </div>
            }

            <div className="actions">
              {
                isMailSent
                  ? <Button
                    onClick={this.handleUpdate}
                    variant="contained"
                    color="primary"
                  >
                    Update Password
              </Button>
                  : <Button
                    onClick={this.sendMail}
                    variant="contained"
                    color="primary"
                  >
                    Send Link
              </Button>
              }
            </div>
            <div>
              {
              successMessage? <div className="success-message">{successMessage}</div>:''
              }
            </div>
          </form>
        </Paper>
        <TermsBar />
      </div>
    );
  }
}
