import React, { Component } from "react";
import "./OrderPageTwo.scss";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";

import SideMenu from "../../shared/SideMenu/SideMenu";
import TopBar from "../../shared/TopBar/TopBar";
import TermsBar from "../../shared/TermsBar/TermsBar";

import SmallItemCard from "../../shared/SmallItemCard/SmallItemCard";
import CategoryItem from '../../shared/CategoryItem/CategoryItem'

import categoryApi from "../../core/api/category-api/categoty-api";
import masterApi from '../../core/api/master-api/master-api';

export default class OrderPageTwo extends Component {

  state = {
    categories: [],
    specials: []
  };

  componentDidMount = () => {
    
    this.getCategories()
    this.getSpecials()
  }

  getCategories = async () => {
    const categories = await categoryApi.getCategories()

    this.setState({
      categories: categories
    })
  }

  getSpecials = async () => {
    const specials = await categoryApi.getSpecials()

    this.setState({
      specials: specials
    })
  }

  render() {
    const { categories, specials } = this.state
    return (
      <div className="orderPageTwo-container">
        <div className="section">
          <div className="section-1">
            <div className="top">
              <TopBar />
            </div>
          </div>
          <div className="section-2">
            <div className="title">Welcome to Gibraltar Food Hub</div>
            <div className="subtitle">
              For the best user experience, download the app for Android or iOS by clicking button below
            </div>
            <Paper>
              <TextField
                className="cover-search-bar"
                variant="outlined"
                placeholder="Search dishes or restaurants"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Paper>
          </div>

          <div className="child-1">
            <div className="row-category">
              {categories.map((item, i) => (
                <CategoryItem
                  key={i}
                  name={item.name}
                  thumbnailUrl={item.thumbnailUrl}
                  restaurantId={item.restaurantId}
                  categoryId={item.categoryId}
                  bgColorCode={item.bgColorCode}
                />
              ))}
            </div>
            <Divider variant="fullWidth" />
            <div className="row-item">
              <div className="title">SPECIALS</div>
              <div
                className="specials-list"
              >
                {specials.map((item, i) => (
                  <SmallItemCard
                    key={i}
                    productName={item.productName}
                    categoryId={item.categoryId}
                    productId={item.productId}
                    productThumbnailURL={item.productThumbnailURL}
                  />
                ))}
              </div>
            </div>
          </div>
          <div className="footer">
            <TermsBar />
          </div>
        </div>
        <div className="side-menu-container">
          <SideMenu />
        </div>
      </div>
    );
  }
}
