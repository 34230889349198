import React, { PureComponent } from 'react';
import { 
    Button, 
    Checkbox, 
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogContentText,
    FormControlLabel,
    TextField, 
 } from '@material-ui/core';
import CustomDatePicker from '../../../../shared/DatePicker/DatePicker';
import moment from 'moment';
import style from '../Promotions.module.scss'
import { CustomSelect } from '../../../../shared/Forms';
import { PromotionApi } from '../../../../core/api';
import { showToast } from '../../../../shared/ToastAlert/ToastAlert';
import { showConfrimBox } from '../../../../shared/ComfirmAlertBox/ConfirmAlertBox';

export class AddEditPromotion extends PureComponent {
    state = {
        name:'',
        restaurantId:'',
        startDate:moment().format(),
        endDate:moment().format(),
        discount:'',
        minimumAmount:'',
        active:true,
        discountType:0,
        id:null,
        brodcastMessage:'',
        brodcastNow:false
        // isFormValid:true
    };
    discountTypes=[
        {value:0,label:'%'},
        {value:1,label:'£'},
    ]


    componentWillReceiveProps(){
        // console.log("receive props",this.props.data);
        if(this.props.data){
            const data = this.props.data;
            this.setState({
                name:data['name'],
                restaurantId:data['restaurantId'],
                startDate:data['startDate'],
                endDate:data['endDate'],
                discount:data['discount'],
                discountType:data['discountType'],
                minimumAmount:data['minimumAmount'],
                active:data['active'],
                id:data['id'],
                brodcastMessage:data['brodcastMessage']
            })
        }else{
            this.setState({
                name:'',
                restaurantId:'',
                startDate:moment().format(),
                endDate:moment().format(),
                discount:'',
                minimumAmount:'',
                active:true,
                discountType:0,
                id:null
            })
        }
    }


    handleChange = (event) => {
        event.persist();
        // console.log(event.target.name,event.target.value);
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    handleChecked = (event) => {
        this.setState({[event.target.name]: event.target.checked });
    };

    onSubmit= async () =>{
        const {id,name,restaurantId,discount,minimumAmount,brodcastMessage,brodcastNow} = this.state;

        if(name === '' || restaurantId === '' || discount === '' || minimumAmount === ''){
            this.setState({isFormValid:false})
            return;
        }else{
            this.setState({isFormValid:true})
        }

        if(this.props.data){
            PromotionApi.updatePromotion(id,this.state)
            .then(()=>{
                this.props.onCancel(true)
                showToast({
                    title:'Promotion Updated.'
                });
            })
            .catch(this.errorHandler)
        }else{
            this.addPromotion(false)
            // if(brodcastMessage !== ''){
            //     showConfrimBox({
            //         title:`Are you sure you want to broadcast promotion to all users?`,
            //         description:``,
            //         okButtonText:'Yes',
            //         cancelButtonText:'No',
            //         onOkClick:()=>this.addPromotion(true),
            //         onCancelClick: ()=>this.addPromotion(false)
            //     })
            // } 

            // console.log("add new");
        }
    }

    addPromotion = (broadcast) =>{
        let formData = this.state;
        formData.brodcastNow = broadcast;
        PromotionApi.addPromotion(this.state)
        .then(()=>{
            this.props.onCancel(true);
            showToast({
                title:'New Promotion Added.'
            });
        })
        .catch(this.errorHandler)
    }

    errorHandler = (error) =>{
        let errorMessage = 'Something went wrong!'
        if (error.response && error.response.data) {
            errorMessage = error.response.data?.['Message']
        } else {
            errorMessage = error.message
        }
        showToast({
            title:errorMessage,
            type:'error'
        });
    }


    render() {
        const { 
            openAddEditModal,
            data,
            onCancel,
            restaurants
        } = this.props;
        const {
            startDate,endDate,
            restaurantId,
            name,
            discount,
            discountType,
            minimumAmount,
            active,
            isFormValid,
            brodcastMessage
        } = this.state;

        return (
            <Dialog open={openAddEditModal}>
                <DialogContent>
                    <DialogContentText>Add Promotion</DialogContentText>
                    <TextField
                        id="filled-basic" 
                        label="Promotion Name"
                        name="name"
                        onChange={this.handleChange}  
                        value={name}
                        type="text"
                        className={style.textInput}
                        margin="normal"
                        required
                        error={!isFormValid && name === ''}
                    />
                    <div className={style.discountContainer}>
                        <TextField
                            id="filled-basic" 
                            label="Discount"
                            name="discount"
                            onChange={this.handleChange}  
                            value={discount}
                            type="number"
                            className={style.discountTextInput}
                            margin="normal"
                            required
                            error={!isFormValid && discount === ''}
                        />
                        <CustomSelect
                            name='discountType'
                            value={discountType}
                            onChange={this.handleChange}
                            options={this.discountTypes}
                            label="Discount Type"
                            style={{width:'25%'}}
                            
                        />
                    </div>

                    <TextField
                        id="filled-basic" 
                        label="Minimum amount (£)"
                        name="minimumAmount"
                        onChange={this.handleChange}  
                        value={minimumAmount}
                        type="number"
                        className={style.textInput}
                        margin="normal"
                        required
                        error={!isFormValid && minimumAmount === ''}
                    />

                    <CustomSelect
                        name='restaurantId'
                        value={restaurantId}
                        onChange={this.handleChange}
                        options={restaurants}
                        label="Restaurant"
                        required={true}
                        error={!isFormValid && restaurantId === ''}
                    />
                    <div className={style.dateContainer}>
                        <CustomDatePicker
                            onChange={(res)=>this.setState({startDate:res})}
                            value={startDate}
                            label="Start Date"
                            style={{width:'45%'}}
                        />
                        <CustomDatePicker 
                            onChange={(res)=>this.setState({endDate:res})}
                            value={endDate}
                            label="End Date"
                            style={{width:'45%'}}
                            minDate={startDate}
                        />


                    </div>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={active}
                                onChange={this.handleChecked}
                                name="active"
                                color="primary"
                            />
                        }
                        label="Active"
                    />
                    <div className={style.brodcastMessage}>
                        <TextField
                            name="brodcastMessage"
                            label="Broadcast message"
                            multiline
                            rows={4}
                            value={brodcastMessage}
                            variant="outlined"
                            fullWidth
                            onChange={this.handleChange}  
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button 
                        color="primary" 
                        variant="contained" 
                        onClick={this.onSubmit}
                    >
                        Submit
                    </Button>
                    <Button color="primary" onClick={onCancel}>
                        Cancel
                    </Button>
                </DialogActions>
                
            </Dialog>
        )
    }
}

export default AddEditPromotion
