import React, { PureComponent } from 'react';
import style from "../Sidebar.module.scss";
import exportIcon from "../../../img/external-link.svg";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from "@material-ui/core";
import { KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import masterApi from '../../../../../core/api/master-api/master-api';
import { createRef } from 'react';
import moment from 'moment';
import DriverReportPrint from '../../../../EPOS/DriverFinanceReport';
import customerOrderApi from '../../../../../core/api/customer-order-api/customer-order.api';
import restaurantApi from '../../../../../core/api/restaurant-api/restaurant-api';

export default class DriverReport extends PureComponent {
    driverPrintRef = createRef();
    constructor(props) {
        super(props);
        this.state = {
            openDialog: false,
            selectedDriverId: "none",
            selectedDate: moment().format(),
            startTime: moment().format(),
            endTime: moment().format(),
            drivers: [],
            driverReport: null
        }
    }


    componentDidMount() {
        this.fetchData();
    }

    async fetchData() {
        const drivers = await masterApi.getDrivers();
        this.setState({ drivers })
    }
    handleClick = () => {
        this.setState({ openDialog: true });
    }

    handleSubmit = async () => {
        const {
            selectedDriverId,
            startTime,
            endTime
        } = this.state;

        const startDate = new Date(startTime).toISOString();
        const endDate = new Date(endTime).toISOString();

        const driverReport = await restaurantApi.getDriverReport(startDate, endDate, selectedDriverId);

        this.setState({
            driverReport,
        }, () => {
            this.driverPrintRef.current.Print();
            setTimeout(this.handleClose, 3000)
        });

    }

    handleClose = () => {
        this.setState({
            openDialog: false
        })
    }
    handleChange = (event) => {
        console.log(event.target.value)
        this.setState({ selectedDriverId: event.target.value })
    };

    handleDateChange = (date) => {
        const { startTime, endTime } = this.state;
        const date1 = moment(date)
        const startTime1 = moment(startTime);
        const endTime1 = moment(endTime);

        const startTimeDate = date1.set({
            hour: startTime1.get('hour'),
            minute: startTime1.get('minute'),
            second: startTime1.get('second')
        }).format();

        const endTimeDate = date1.set({
            hour: endTime1.get('hour'),
            minute: endTime1.get('minute'),
            second: endTime1.get('second')
        }).format();

        this.setState({
            selectedDate: date1.format(),
            startTime: startTimeDate,
            endTime: endTimeDate
        })
    };

    render() {
        const {
            openDialog,
            selectedDriverId,
            selectedDate,
            startTime,
            endTime,
            drivers,
            driverReport
        } = this.state;
        const { button2, restaurantId } = this.props;
        return (
            <div>
                {
                    !button2 &&
                    <div className={style.navItem} onClick={this.handleClick}>
                        <img src={exportIcon} alt="" /> Driver Report
                    </div>
                }
                {
                    button2 &&
                    <Button
                        variant="contained"
                        onClick={this.handleClick}
                        color="primary"
                    >
                        Driver Report
                    </Button>
                }
                <Dialog open={openDialog} >
                    <DialogContent>
                        <DialogContentText>
                            Driver Report
                        </DialogContentText>
                        <FormControl style={{ width: '100%' }}>
                            <InputLabel id="demo-simple-select-label">Driver Name</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selectedDriverId}
                                onChange={this.handleChange}
                                disabled={!!restaurantId}
                            >
                                <MenuItem value="none">
                                    <em>Select Driver</em>
                                </MenuItem>
                                {
                                    !!drivers.length && drivers.map((res, i) => (
                                        <MenuItem value={res.driverId} key={i}>{res.driverName}</MenuItem>
                                    ))
                                }

                            </Select>
                        </FormControl>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                margin="normal"
                                id="date-picker-dialog"
                                label="Date"
                                format="dd/MM/yyyy"
                                value={selectedDate}
                                onChange={this.handleDateChange}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                style={{
                                    marginTop: "20px",
                                    width: "-webkit-fill-available"
                                }}
                            />

                            <div className={style.timeRangeFlex}>
                                <KeyboardTimePicker
                                    margin="normal"
                                    id="time-picker"
                                    label="Start Time"
                                    value={startTime}
                                    onChange={(date) => this.setState({ startTime: date })}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change time',
                                    }}
                                    style={{ width: '45%' }}
                                />
                                <KeyboardTimePicker
                                    margin="normal"
                                    id="time-picker"
                                    label="End Time"
                                    value={endTime}
                                    onChange={(date) => this.setState({ endTime: date })}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change time',
                                    }}
                                    style={{ width: '45%' }}

                                />
                            </div>
                        </MuiPickersUtilsProvider>

                    </DialogContent>

                    <DialogActions>
                        <Button color="primary" variant="contained" onClick={this.handleSubmit}>
                            Submit
                        </Button>

                        <Button color="primary" onClick={this.handleClose}>
                            Cancel
                        </Button>
                    </DialogActions>
                    <DriverReportPrint
                        ref={this.driverPrintRef}
                        data={driverReport ? {
                            selectedDate,
                            startTime,
                            endTime,
                            ...driverReport
                        } : null}
                    />
                </Dialog>
            </div>
        );
    }
}

