import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./MediumItemCard.scss";

import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";

import SimpleButton from "../SimpleButton/SimpleButton";

export default class MediumItemCard extends Component {
  render() {
    return (
      <div>
        <Link to={`/order-item/${this.props.productId}`}>
          <div className="item-card-medium">
            <Paper className={this.props.isSearch ? "search" : "category"} elevation={5}>
              <div
                className="item-cover"
                style={{
                  backgroundImage: `url(${this.props.productThumbnailURL})`,
                }}
              ></div>
              <div className="details">
                <div className="single-item-name">{this.props.productName}</div>
                <Divider variant="fullWidth" />
                {/* <div className="single-item-sub-details">
                      <div className="single-item-name-left">
                        <div className="item-size">Large 18"</div>
                        <div className="item-size">
                          £{this.props.price.toFixed(2)}
                        </div>
                      </div>

                      <div className="single-item-name-right">
                        <SimpleButton text="ADD" />
                      </div>
                    </div> */}
              </div>
            </Paper>
          </div>
        </Link>
      </div>
    );
  }
}
