import httpApi from "../http-api/http-api"

class CategoryApi {
    getItemsPerCategory = async (CategoryId) => {
        const itemsPerCategory = await httpApi.sendPostRequest(`/Category/ItemsPerCategory/${CategoryId}`);
        return itemsPerCategory;
    }

    getCategories = async () => {
        const categories = await httpApi.sendGetRequest('/Category/Category');
        return categories;
    }

    getSpecials = async () => {
        const specials = await httpApi.sendGetRequest('/Category/Special');
        return specials;
    }

    getCategoriesByRestaurant = async (restaurantId) => {
        const categories = await httpApi.sendGetRequest(`/Master/GetRestaurantCategory/${restaurantId}`);
        return categories;
    }
}

export default new CategoryApi()