import React, { PureComponent } from 'react';
import { 
    Button, 
    Checkbox, 
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogContentText,
    FormControlLabel,
    TextField,
 } from '@material-ui/core';
import style from '../CustomerIssues.module.scss';
import { CustomerMessages } from '../../../../../../core/api';
import { showToast } from '../../../../../../shared/ToastAlert/ToastAlert';
import { messageType } from '../../../../../../core/contants';
import time from "../../../../../../core/lib/time";

export class ViewMessage extends PureComponent {
    state = {
        replyMessage:'',
        showReplyBox:false,
        error:false,
        resolved:false

    };

    clearState = ()=>{
        this.setState({
            replyMessage:'',
            showReplyBox:false,
            resolved:false,
            error:false
        });
    }

    onSend= () =>{
        const {
            orderId,
            id,
            issueTypeId,
            subject
        } = this.props.data;

        if(this.state.replyMessage === ''){
            this.setState({error:true});
            return;
        }
        const sendData = {
            "orderId":orderId,
            "parentMessageId": id,
            "issueTypeId": issueTypeId,
            "subject": subject,
            "message": this.state.replyMessage,
            "messageType":messageType.orderMessage,
            "resolved":this.state.resolved
          }
          CustomerMessages.addMessage(sendData)
          .then(()=>{
                this.clearState()
                this.closeModal(true);
                showToast({
                    title:'Messaged sent successfully.'
                });
            })
            .catch(this.errorHandler)
    }

    // componentDidUpdate(){
    //     console.log("new Props receive");
    //     this.setState({
    //         replyMessage:'',
    //         showReplyBox:false,
    //         error:false,
    //         resolved:false
    //     })
    // }
    errorHandler = (err) =>{
        showToast({
            title:err.message,
            type:'error'
        });
    }

    cancelButtonHandler = () =>{
        this.clearState();
        this.closeModal()
    }

    onClickReply = () =>{
        this.setState({showReplyBox:true})
    }

    handleChecked = (event) => {
        this.setState({[event.target.name]: event.target.checked });
    };

    closeModal = (refresh = false) =>{
        this.props.close(refresh);
    }

    markAsResolved =(id)=>{
        CustomerMessages.markAsResolved(id)
        .then(()=>{
            this.closeModal(true)
            showToast({
                title:'Messaged updated successfully.'
            });
        })
    }

    render() {
        const { 
            openModel,
            data
        } = this.props;
        const {
            replyMessage,
            showReplyBox,
            error,
            resolved
        } = this.state;
        // console.log("data",data);
        return (
            <Dialog open={openModel} fullWidth className={style.modalContainer}>
                <DialogContent>
                    <DialogContentText>#{data?.['orderNo']} - {data?.['typeofIssue']}</DialogContentText>
                    {/* <div className={style.customerText}></div> */}
                    <div className={style.subjectText}>{data?.['subject']}</div>

                    {
                        data?.['replyMessage']  &&
                        <>
                            <div  className={style.msgHead}><b>Reply :</b></div>
                            <div className={style.date}>({time.timestampToDateString(data?.replyDateTime)})</div>
                            <div className={style.messageText}>{data?.['replyMessage']}</div>

                            <div  className={style.msgHead}><b>{data?.['customerName']} :</b></div>
                            <div className={style.date}>({time.timestampToDateString(data?.createdOn)})</div>
                            <div className={style.messageText}>{data?.['message']}</div>
                        </>
                    }

                    {/* {
                        data?.['replyMessage'] && !data?.['resolved'] &&
                        <>
                            <div  className={style.msgHead}><b>Reply :</b></div>
                            <div className={style.date}>({time.timestampToDateString(data?.replyDateTime)})</div>
                            <div className={style.messageText}>{data?.['replyMessage']}</div>
                        </>
                    } */}

                    {
                        !data?.['replyMessage'] && 
                        <>
                            <div  className={style.msgHead}><b>{data?.['customerName']} :</b></div>
                            <div className={style.date}>({time.timestampToDateString(data?.createdOn)})</div>
                            <div className={style.messageText}>{data?.['message']}</div>
                        </>
                    }



                    {
                        data?.['replyMessage'] && !data?.['resolved'] &&
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={resolved}
                                    onChange={()=>this.markAsResolved(data['id'])}
                                    name="resolved"
                                    color="primary"
                                />
                            }
                            label="Resolved"
                        />
                    }

                    {
                        showReplyBox && !data?.['parentMessageId'] &&
                        <>
                            <div className={style.replyBox}>
                                <TextField
                                    id="outlined-multiline-static"
                                    label="Reply"
                                    multiline
                                    rows={4}
                                    value={replyMessage}
                                    variant="outlined"
                                    fullWidth
                                    onChange={(e)=>this.setState({replyMessage:e.target.value})}
                                    error={error}
                                />
                            </div>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={resolved}
                                        onChange={this.handleChecked}
                                        name="resolved"
                                        color="primary"
                                    />
                                }
                                label="Resolved"
                            />
                        </>
                    }
                </DialogContent>
                <DialogActions>
                    {
                        !showReplyBox && !data?.['resolved'] && !data?.['replyMessage'] && (
                            <Button 
                                color="primary" 
                                variant="contained" 
                                onClick={this.onClickReply}
                            >
                                Reply
                            </Button>
                        )
                    }
                    {
                        showReplyBox && !data?.['resolved'] &&  !data?.['parentMessageId'] &&(
                            <Button 
                                color="primary" 
                                variant="contained" 
                                onClick={this.onSend}
                            >
                                Send
                            </Button>
                        ) 
                    }
                    <Button color="primary" onClick={this.cancelButtonHandler}>
                        Cancel
                    </Button>
                </DialogActions>
                
            </Dialog>
        )
    }
}

export default ViewMessage
