import 'date-fns';

import React, { Component, PureComponent } from 'react';

import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardDatePickerProps } from '@material-ui/pickers';
import PropTypes from 'prop-types'


const CustomDatePicker = (props) => (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
            variant="inline"
            inputVariant="standard"
            id="date-picker-dialog"
            format="dd/MM/yyyy"
            margin="normal"
            autoOk={true}
            KeyboardButtonProps={{
                'aria-label': 'change date',
            }}
            style={{
                width: "-webkit-fill-available"
            }}
            {...props}

        />
    </MuiPickersUtilsProvider>
);


CustomDatePicker.propTypes = {
    onChange:PropTypes.func.isRequired,
    value:PropTypes.any.isRequired,
    format:PropTypes.string,
    inputVariant:PropTypes.oneOf(["outlined" , "standard" ,"filled"])
}

export default CustomDatePicker