import React, { Component } from 'react';
import { PureComponent } from 'react';
import style from "../Sidebar.module.scss";
import exportIcon from "../../../img/external-link.svg";
import { 
    Button, 
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogContentText, 
    FormControl, 
    InputLabel, 
    MenuItem, 
    Select, 
    TextField 
} from "@material-ui/core";
import { KeyboardDatePicker, KeyboardDateTimePicker, KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import masterApi from '../../../../../core/api/master-api/master-api';
import FinanceReportPrint from '../../../../EPOS/FinanceReportPrint';
import { createRef } from 'react';
import moment from 'moment';
import restaurantApi from '../../../../../core/api/restaurant-api/restaurant-api';
import { restaurantPasswords } from '../../Utils/common';



class FinanceReport extends PureComponent {
    financePrintRef = createRef();
    constructor(props){
        super(props);
        this.state={
            openDialog:false,
            selectedRestaurantId:this.props.restaurantId?this.props.restaurantId:1,
            fromDateTime:moment().format(),
            toDateTime:moment().format(),
            restaurants:[],
            financeReport:null,
            password:'',
            restaurantPassword:'',
            // selectedDate:moment().format(),
            // startTime:moment().format(),
            // endTime:moment().format(),
        }
    }


    componentDidMount(){
        this.fetchRestaurants();
        const {selectedRestaurantId} = this.state
        const restaurantPassword = restaurantPasswords.find(res=>Number(res.id) === Number(selectedRestaurantId))
        this.setState({restaurantPassword})
    }

    async fetchRestaurants(){
        const restaurants = await masterApi.getRestaurants();
        // console.log("???",restaurants)
        this.setState({
            restaurants:restaurants,
            selectedRestaurantId:this.props.restaurantId?this.props.restaurantId:restaurants[0].id
        })
    }
    handleClick =()=>{
   
        this.setState({
            openDialog:true
        })
    } 
    
    handleSubmit = async () => {
        const {selectedRestaurantId,fromDateTime,toDateTime,password,restaurantPassword} = this.state;
        const startDate = new Date(fromDateTime).toISOString();
        const endDate = new Date(toDateTime).toISOString();
        // console.log("handle submit",selectedRestaurantId,startDate,endDate,password,restaurantPassword);
        if(this.props.kitchen && restaurantPassword.password !== password){
            alert('Incorrect Password');
            return
        }
        const includeDeliveryDetails = selectedRestaurantId === 1 || selectedRestaurantId === 0 ? true : false;
        const financeReport = await restaurantApi.getFinanceReport(selectedRestaurantId,startDate,endDate,includeDeliveryDetails);
        const posReport = await restaurantApi.getPosReport(selectedRestaurantId,startDate,endDate);
        const finalFinanceReport = {
            ...financeReport,
            posReport,
            restaurantId:selectedRestaurantId,
            kitchen:this.props.kitchen?true:false
        }
        
        this.setState({
            financeReport:finalFinanceReport,
            password:''
        },()=>{
            this.financePrintRef.current.Print();
            setTimeout(this.handleClose,3000)
        });
    } 

    handleClose = () => {
        this.setState({
            openDialog:false,
            password:''
        })
    }

    handleChange = (event) => {
        console.log(event.target.value)
        const restaurantPassword = restaurantPasswords.find(res=>Number(res.id) === Number(event.target.value))
        this.setState({selectedRestaurantId:event.target.value,restaurantPassword})
    };

    // handleDateChange = (date) => {
    //     const {startTime,endTime} = this.state;
    //     const date1 = moment(date)
    //     const startTime1 = moment(startTime);
    //     const endTime1 = moment(endTime);
        
    //     const startTimeDate = date1.set({
    //         hour:   startTime1.get('hour'),
    //         minute: startTime1.get('minute'),
    //         second: startTime1.get('second')
    //     }).format();

    //     const endTimeDate = date1.set({
    //         hour:   endTime1.get('hour'),
    //         minute: endTime1.get('minute'),
    //         second: endTime1.get('second')
    //     }).format();

    //     this.setState({
    //         selectedDate:date1.format(),
    //         startTime:startTimeDate,
    //         endTime:endTimeDate
    //     })
    //   };
      handleDateChange =(date,name) =>{
        this.setState({
            [name]:date
        });
      }

      handleChange = (event) => {
        event.persist();

        this.setState({
            [event.target.name]: event.target.value,
        });
    };
    
  render() {
      const {
          openDialog,
          selectedRestaurantId,
        //   selectedDate,
        //   startTime,
        //   endTime,
          fromDateTime,
          toDateTime,
          restaurants,
          financeReport,
          password
        } = this.state;
        const {button2,restaurantId} = this.props;
    return (
      <div> 
            {    
                !button2 &&
                <div className={style.navItem} onClick={this.handleClick}>
                    <img src={exportIcon} alt="" /> Finance Report
                </div> 
            }
            {
                button2 &&
                <Button
                    variant="contained"
                    onClick={this.handleClick}
                    color="primary"
                >
                        Finance Report
                </Button>
            }
            <Dialog open={openDialog} >
                <DialogContent>
                    <DialogContentText>
                        Finance Report
                    </DialogContentText>
                    <FormControl style={{width:'100%'}}>
                        <InputLabel id="demo-simple-select-label">Restaurant</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            name="selectedRestaurantId"
                            value={selectedRestaurantId}
                            onChange={this.handleChange}
                            disabled={!!restaurantId}
                        >
                            {
                                !!restaurants.length && restaurants.map((res,i)=>(
                                    <MenuItem value={res.id} key={i}>{res.name}</MenuItem>
                                ))
                            }
                            {!this.props.kitchen && <MenuItem value={0} key='allRest'>All</MenuItem>}
                        </Select>
                    </FormControl>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDateTimePicker
                            margin="normal"
                            id="date-picker-dialog"
                            label="From Date Time"
                            format="dd/MM/yyyy hh:mm a"
                            value={fromDateTime}
                            onChange={(date)=>this.handleDateChange(date,'fromDateTime')}
                            name="fromDateTime"
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            style={{
                                marginTop: "20px",
                                width: "-webkit-fill-available"
                            }}
                        />
                        <KeyboardDateTimePicker
                            margin="normal"
                            id="date-picker-dialog"
                            label="To Date Time"
                            format="dd/MM/yyyy hh:mm a"
                            value={toDateTime}
                            onChange={(date)=>this.handleDateChange(date,'toDateTime')}
                            name="toDateTime"
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            style={{
                                marginTop: "20px",
                                width: "-webkit-fill-available"
                            }}
                        />
                    </MuiPickersUtilsProvider>
                    {
                        this.props.kitchen && 
                        <TextField 
                            id="filled-basic" 
                            label="Password"
                            name="password"
                            onChange={this.handleChange}  
                            value={password}
                            type="password"
                        />
                    }
                </DialogContent>

                <DialogActions>
                    <Button color="primary" variant="contained" onClick={this.handleSubmit}>
                        Submit
                    </Button>

                    <Button color="primary" onClick={this.handleClose}>
                        Cancel
                    </Button>
                </DialogActions>
                <FinanceReportPrint 
                    ref={this.financePrintRef} 
                    data={financeReport?{
                        ...financeReport,
                        fromDateTime:moment(fromDateTime).format('DD/MM/yyyy hh:mm a'),
                        toDateTime:moment(toDateTime).format('DD/MM/yyyy hh:mm a')
                     }:null
                    }
                />
            </Dialog>
      </div>
    );
  }
}

export default FinanceReport;
