import { combineReducers } from "redux";
import { createStore } from "redux";

import orderReducer from "./order.reducer";
import cartReducer from "./cart.reducer";
import commonReducer from "./common.reducer";

const rootReducer = combineReducers({
  orderReducer,
  cartReducer,
  commonReducer
});

const store = createStore(rootReducer);

export default store;
