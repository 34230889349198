import authApi from "../core/api/auth-api/auth-api";

const isPosOnlyProduct =(productName) =>{
    return !authApi.isPosUser() && productName.includes('POS Only')?false:true;
}

const convertToLast3digit = (number) =>{
    const stringNum = String(number);
    return stringNum.length > 3?stringNum.substring(stringNum.length-3):number; 
}

export  {
    isPosOnlyProduct,
    convertToLast3digit
}