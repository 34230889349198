import moment from 'moment';
import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { OrderTypes } from '../../core/contants';
import { convertToLast3digit } from '../../utils/MainUtils';
import './Epos.scss'

const HtmlToPrint = forwardRef((props, ref) => {
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    useImperativeHandle(ref, () => ({

        Print() {
            handlePrint()
        }

    }));

    return (
        <div>
            <div style={{ display: "none" }}>
            <ComponentToPrint ref={componentRef} data={props.data} />
            </div>
        </div>
    );
});

export default HtmlToPrint

export class ComponentToPrint extends React.PureComponent {
    
    render() {
        console.log(this.props.data)

        const data = this.props.data;
        if (!data) {
            return <div></div>
        }

        return (
            <div style={{
                padding: '2%',
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                paddingLeft: '7%'
            }}>
                <h2 style={{ margin: 0 }}>{data.orderType === OrderTypes.Delivery ? "DELIVERY" : "TAKE AWAY"}</h2>
                <h3 style={{ margin: 0 }}>{data.customerName}</h3>
                <h4 style={{ margin: 0 }}>{moment(data.timeSincePlaced).format("DD.MM.YYYY HH:mm")}</h4>
                {/* <h3 style={{margin:0}}>{data.deliveryAddress}</h3> */}
                {/* <h3 style={{margin:0}}>{data.deliveryZoneName}</h3> */}
                {/* <h4 style={{margin:0}}>Order No: {data.orderNumber}</h4> */}
                <table>
                    <tbody>
                        <tr>
                            <td colSpan="3"> <hr /></td>
                        </tr>
                        {
                            data.restaurants.map((restaurant, i) => (
                                <>
                                    <tr>
                                        <th colSpan="3" >{restaurant.restaurant} :</th>
                                    </tr>
                                    {
                                        (restaurant.orderSummary ? restaurant.orderSummary : data.orderSummary).map((p, i) => (
                                            <>
                                                <tr>
                                                    <th colSpan="3" className="productTitle">{p.quantity} x {p.productName}</th>
                                                </tr>
                                                <tr key={i}>
                                                    <td></td>
                                                    <td colSpan="2">
                                                        Size : {p.productSizeVarientName}
                                                    </td>
                                                </tr>
                                                {
                                                    p.productPreferenceOptions.map((prf, i) => (
                                                        <tr key={i}>
                                                            <td></td>
                                                            <td colSpan="2">
                                                                {prf.productPreference} : {prf.productPreferenceOption}
                                                            </td>
                                                        </tr>
                                                    ))

                                                }
                                                {
                                                    !!p.addedProductIngredients && !!p.addedProductIngredients.length &&
                                                    <tr>
                                                        <td></td>
                                                        <td colSpan="2">Added Ingredients :</td>
                                                    </tr>
                                                }
                                                {
                                                    p.addedProductIngredients.map((ing, i) => (
                                                        <tr key={i}>
                                                            <td></td>
                                                            <td colSpan="2"><span>&nbsp;&nbsp;</span> + {ing.ingredient}</td>
                                                        </tr>
                                                    ))

                                                }
                                                {
                                                    !!p.deletedProductIngredients && !!p.deletedProductIngredients.length &&
                                                    <tr>
                                                        <td></td>
                                                        <td colSpan="2">Removed Ingredients :</td>
                                                    </tr>
                                                }
                                                {
                                                    p.deletedProductIngredients.map((ing, i) => (
                                                        <tr key={i}>
                                                            <td></td>
                                                            <td colSpan="2"><span>&nbsp;&nbsp;</span>  - {ing.ingredient}</td>
                                                        </tr>
                                                    ))

                                                }

                                            </>
                                        ))
                                    }
                                    <tr><td colSpan="2"> <hr/></td></tr>
                                    <tr></tr>
                                    <tr></tr>
                                </>
                            ))
                        }
                        {/* <tr>
                            <td colSpan="2"> <hr /></td>
                        </tr> */}
                        <tr>
                            <td style={{ fontSize: 17 }}><b>{convertToLast3digit(data.orderNumber)}</b></td>
                            <td style={{ textAlign: 'right', fontSize: 17 }}> <b>{data.deliveryZoneName}</b> </td>
                        </tr>
                    </tbody>
                </table>
                {
                    !!data.specialNote &&
                    <p><b>Special Note :</b> {data.specialNote}</p>
                }
            </div>
        );
    }
}

