import { AuthApi } from "..";
import httpApi from "../http-api/http-api";

class MasterApi {
  async getTagsById(restaurantId) {
    const res = await httpApi.sendGetRequest(`/Master/GetTags/${restaurantId}`);
    return res;
  }

  async getTags() {
    const res = await httpApi.sendGetRequest(`/Master/GetTags`);
    return res;
  }

  async getProductsByTag(tagId) {
    const res = await httpApi.sendGetRequest(`/Master/GetTagProducts/${tagId}`);
    return res;
  }

  async getRestaurants() {
    const res = await httpApi.sendGetRequest("/Master/GetRestaurants");
    return res;
  }

  async getDrivers() {
    const res = await httpApi.sendGetRequest("/Master/GetRestaurantDrivers");
    return res;
  }

  async getRestaurantZone() {
    const res = await httpApi.sendGetRequest("/Master/GetRestaurantZone");
    return res;
  }

  async getDeliveryCharge() {
    const res = await httpApi.sendGetRequest("/Master/GetActiveDeliveryCharge");
    return res;
  }

  async checkRestaurantOpen(resId, date) {
    const data={
      dateTime:date
    };
    const res = await httpApi.sendPostRequest(
      `/Master/IsRestaurantIsOpen/${resId}`,data
    );
    return res;
  }

  async setOnlineOrderAllowed(onlineOrdersAllowed) {
    return await httpApi.sendPutRequest(
        `/System/OnlineOrdersAllowed/${onlineOrdersAllowed}`, {
      });
  }

  async _csGetOnlineOrderAllowed() {
    const res = await httpApi.sendGetRequest("/System/OnlineOrdersAllowed");
    return res;
  }


  async getOnlineOrderAllowed() {
    // console.log(AuthApi.isCS_guest_user(), AuthApi.isPosUser(), AuthApi.isCS_impersonate_user())
    if(AuthApi.isCS_guest_user() || AuthApi.isPosUser() || AuthApi.isCS_impersonate_user()){
      return true;
    }else{
      const res = await httpApi.sendGetRequest("/System/OnlineOrdersAllowed");
      return res;
    }
  }

}

export default new MasterApi();
