import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./KitchenLogin.scss";

import logo from "../../../assets/logo.png";

import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import PersonIcon from "@material-ui/icons/Person";
import LockIcon from "@material-ui/icons/Lock";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import TermsBar from "../../../shared/TermsBar/TermsBar";

import restaurantApi from "../../../core/api/restaurant-api/restaurant-api"

export default class Login extends Component {
    state = {
        showPassword: false,
        username: '',
        password: '',

        usernameError: false,
        passwordError: false,
        usernameErrorText: '',
        passwordErrorText: '',
    };

    handleLogin = async () => {
        let { username, password } = this.state

        let usernameErrorText = ''
        let passwordErrorText = ''

        let passwordError = false
        let usernameError = false

        if (!username) {
            usernameError = true
            usernameErrorText = 'This field cannot be empty'
        }

        if (!password) {
            passwordError = true
            passwordErrorText = 'This field cannot be empty'
        }

        let data = {
            userName: username,
            password: password
        }

        if (!usernameError && !passwordError) {
            try {
                await restaurantApi.login(data);
                // this.props.history.push('/kitchen/dashboard');
                this.props.history.push('/kitchenDashboard');
            } catch (err) {
                this.setState({
                    usernameError: true,
                    passwordError: true,
                    errorMessage: err.message,
                    password: '',
                    passwordErrorText: err.message
                })
            }
        } else {
            this.setState({
                usernameError: usernameError,
                usernameErrorText: usernameErrorText,
                passwordError: passwordError,
                passwordErrorText: passwordErrorText
            })
        }

    }

    handleClickShowPassword = () => {
        const { showPassword } = this.state;
        this.setState({
            showPassword: !showPassword,
        });
    };

    handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    handleChange = (event) => {
        event.persist();

        this.setState({
            [event.target.name]: event.target.value
        });
    }

    render() {
        const { username, password } = this.state;

        return (
            <div className="login-container">
                <Paper className="paper" elevation={5}>
                    <img src={logo} />

                    <Divider variant="middle" />

                    <div className="title"> Kitchen Login</div>
                    <form onSubmit={this.handleLogin}>
                        <div className="login-form">
                            <div className="form-item">
                                <div className="name">USER NAME</div>
                                <TextField
                                    onChange={this.handleChange}
                                    error={this.state.usernameError}
                                    helperText={this.state.usernameErrorText}
                                    value={username}
                                    name="username"
                                    variant="outlined"
                                    fullWidth
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <IconButton disabled>
                                                    <PersonIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </div>

                            <div className="form-item">
                                <div className="name">PASSWORD</div>
                                <TextField
                                    onChange={this.handleChange}
                                    error={this.state.passwordError}
                                    helperText={this.state.passwordErrorText}
                                    name="password"
                                    variant="outlined"
                                    value={password}
                                    fullWidth
                                    type={this.state.showPassword ? "text" : "password"}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <IconButton disabled>
                                                    <LockIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={this.handleClickShowPassword}
                                                    edge="end"
                                                >
                                                    {this.state.showPassword ? (
                                                        <Visibility />
                                                    ) : (
                                                        <VisibilityOff />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </div>
                        </div>

                        <div className="options">
                            <Grid container alignItems="center" justify="center">
                                <Link to="/reset">
                                    <p id="red-button">
                                        <u>FORGOTTEN PASSWORD?</u>
                                    </p>
                                </Link>
                            </Grid>
                        </div>

                        <div className="login">
                            <Button
                                onClick={this.handleLogin}
                                variant="contained"
                                color="primary"
                                endIcon={<NavigateNextIcon />}
                            >
                                LOGIN
              </Button>
                        </div>

                        <div className="options">
                            <div className="signup">
                                {/* <Grid container alignItems="center" justify="center">
                  <p>DON'T HAVE AN ACCOUNT?</p>
                  <Link to="/signup">
                    <p id="red-button">
                      <u>SIGN UP</u>
                    </p>
                  </Link>
                </Grid> */}
                            </div>
                        </div>
                    </form>
                </Paper>
                <TermsBar />
            </div>
        );
    }
}
