import React, { Component } from "react";
import style from "./CSOrderDetails.module.scss";
import time from "../../../core/lib/time";

import classnames from 'classnames'
import Page from "../shared/Page/Page";

import Chip from "@material-ui/core/Chip";

import backIcon from "../img/back.svg";

import customerOrderApi from "../../../core/api/customer-order-api/customer-order.api";
import authApi from '../../../core/api/auth-api/auth-api';
import { CS_Roles } from "../shared/Utils/common";
import orderApi from "../../../core/api/order-api/order-api";
import OrderCancel from "./components/OrderCancel";
import { convertToLast3digit } from "../../../utils/MainUtils";
import UpdateOrderAddress from "./components/UpdateOrderAddress/UpdateOrderAddress";
export default class CSOrderDetails extends Component {
    
    state = {
        orderDetails: [],
        resaurantOrderItems: [],
        orderItems: [],
        totalPrice: null,
        user:null,
        Order_Details:null,
       
    };

    componentDidMount() {
        this.fetchOrderDetails();
    }

    isPermited = async () => {
        
        const user = await authApi.getCSUser()
        // if (user.roleDescription !== "Customer Service") {
        //     window.location.href = "/customer-support";
        // }
        if (!CS_Roles.includes(user.roleDescription)) {
            window.location.href = "/customer-support";
            return;
        }
        this.setState({user})
    }

    async fetchOrderDetails() {
        this.isPermited()
        const orderDetails = await customerOrderApi.getOrderDetails(
            this.props.match.params.orderId
        );
        const orderDetails2 = await orderApi.getOrderDetails(
            this.props.match.params.orderId
        );

        this.setState({
            orderDetails: orderDetails,
            resaurantOrderItems: orderDetails.resaurantOrderItems,
            Order_Details:orderDetails2
        });
        // console.log(orderDetails2)

    }

    renderIngredients(ingredients) {
        return ingredients.map((i, j) => {
            return (<div className={style.chip} key={j}>
                <Chip key={j} label={i.ingredient} size="small" />
            </div>);
        });
    }

    renderPreferences(preferences) {
        return preferences.map((i, j) => {
            return (<div className={style.label} key={j}>{i.productPreference}: {i.productPreferenceOption}</div>)
        });
    }

    renderCard(resaurantOrderItem, orderItems) {
        return orderItems.map((x, y) => {
            return (
                <div key={y} className={style.orderItem}>
                    <div
                        className={style.image}
                        style={{
                            backgroundImage: `url(${x.thumbnailUrl})`,
                        }}
                    ></div>

                    <div className={style.details}>
                        <div className={style.restaurant}>
                            {resaurantOrderItem.restaurant} [
                            {x.restaurantOrderStatusDescription}]
                        </div>

                        <div className={style.label}>{x.productName}</div>
                        <div className={style.label}>Size : {x.productSizeVarientName}</div>
                        {this.renderPreferences(x.productPreferenceOptions)}

                        {!!x.itemProductIngredients.length ? (
                            <div className={classnames(style.ingredients, style.label)}>
                                <div>Ingredients :</div>
                                <div className={style.items}>{this.renderIngredients(x.itemProductIngredients)}</div>
                            </div>
                        ) : (
                            <div></div>
                        )}

                        <div className={style.subLabel}>
                            {x.unitPrice.toFixed(2)} x {x.quantity}
                        </div>
                    </div>

                    <div className={style.price}>
                        <div>{x.totalPrice.toFixed(2)}</div>
                    </div>
                </div>
            );
        });
    }

    renderOrderItems() {
        let { resaurantOrderItems, orderItems } = this.state;
        return resaurantOrderItems.map((x, y) => {
            let resaurantOrderItem = x;
            orderItems = resaurantOrderItem.orderItems;
            return this.renderCard(resaurantOrderItem, orderItems);
        });
    }

    getTotalPrice() {
        let { resaurantOrderItems, orderDetails } = this.state;
        let total = 0;
        if (!!orderDetails.totalPrice) {
            total = orderDetails.totalPrice;
        }


        // for (let i = 0; i < resaurantOrderItems.length; i++) {
        //     let orderItems = resaurantOrderItems[i].orderItems;
        //     orderItems.forEach((item) => {
        //         total += item.totalPrice;
        //     });
        // }
        return total.toFixed(2);
    }

    getUsedPoints() {
        let { orderDetails } = this.state;
        let total = 0;
        if (!!orderDetails.userFoodHubPoint) {
            total = orderDetails.userFoodHubPoint / 100;
        }


        // for (let i = 0; i < resaurantOrderItems.length; i++) {
        //     let orderItems = resaurantOrderItems[i].orderItems;
        //     orderItems.forEach((item) => {
        //         total += item.totalPrice;
        //     });
        // }
        return total.toFixed(2);
    }

    getDeliveryCharge() {
        let { orderDetails } = this.state;
        let charge = 0;
        if (!!orderDetails.deliveryCharge) {
            charge = orderDetails.deliveryCharge;
        }
        return charge.toFixed(2);
    }

    render() {
        const { 
            orderDetails ,
            user,
            Order_Details,
            cancelDialogOpen,
            cancelNote
        } = this.state;
        // const isMain_CS_User = user && user.roleDescription === CS_Roles[0];
        return (
            <Page>
                <div className={style.container}>
                    <div className={style.header}>
                    <div onClick={() => this.props.history.goBack()}>
                        <img src={backIcon} alt="" />
                    </div>
                        {convertToLast3digit(orderDetails.orderNo)}
                    </div>

                    <div className={style.content}>
                        <div className={style.title}>Order Details</div>

                        <div className={style.orderDetails}>
                            <div className={style.orderInformation}>
                                <table>
                                    <tbody>
                                        <tr>
                                            <th colSpan={2} className={style.title}>
                                                Order Information
                                            </th>
                                        </tr>

                                        <tr>
                                            <th>Order Number</th>

                                            <td>{convertToLast3digit(orderDetails.orderNo)}</td>
                                        </tr>

                                        <tr>
                                            <th>Customer Name</th>

                                            <td>{orderDetails.customerName}</td>
                                        </tr>
                                        {
                                            !!Order_Details &&
                                            <tr>
                                                <th>Order Type</th>

                                                <td>{Order_Details.orderTypeDescription}</td>
                                            </tr>
                                        }
                                    </tbody>

                                    <tbody>
                                        <tr>
                                            <th colSpan={2} className={style.title}>
                                                Delivery Information
                                            </th>
                                        </tr>

                                        <tr>
                                            <th>Delivery Zone</th>

                                            <td>{orderDetails.deliveryZone}</td>
                                        </tr>

                                        <tr>
                                            <th>Driver Name</th>

                                            <td>{orderDetails.driverName || "-"}</td>
                                        </tr>
                                        <tr>
                                            <th>Delivery Charge</th>

                                            <td>{this.getDeliveryCharge()}</td>
                                        </tr>

                                        <tr>
                                            <th colSpan={2}> Address 
                                            <UpdateOrderAddress 
                                                orderId = {orderDetails.orderId}
                                                fetchOrderDetails={()=>this.fetchOrderDetails()}
                                            />
                                            {/* <label htmlFor="icon-button-file">
                                                <IconButton 
                                                    style={{padding:'6px',marginLeft:'10px'}}
                                                    color="primary" aria-label="upload picture" component="span">
                                                    <Edit />
                                                </IconButton>
                                            </label> */}
                                                                                    
                                            </th>
                                        </tr>

                                        <tr>
                                            <td colSpan={2}>{orderDetails.customerAddress || "-"}</td>
                                        </tr>
                                        <tr>
                                            <th colSpan={2}> Phone Number </th>
                                        </tr>

                                        <tr>
                                            <td colSpan={2}>{Order_Details && Order_Details.userMobile ? Order_Details.userMobile : "-"}</td>
                                        </tr>
                                    </tbody>

                                    <tbody>
                                        <tr>
                                            <th colSpan={2} className={style.title}>
                                                Order State
                                            </th>
                                        </tr>



                                        <tr>
                                            <td>New</td>

                                            <td>
                                                {time.timestampToDateString(orderDetails.orderPlacedOn)}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>Acknowledged</td>

                                            <td>
                                                {time.timestampToDateString(
                                                    orderDetails.orderAcknowledgedDate
                                                )}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>Order Cooking</td>

                                            <td>
                                                {time.timestampToDateString(orderDetails.orderCookingDate)}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>Ready</td>

                                            <td>
                                                {time.timestampToDateString(orderDetails.orderReadyDate)}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>Collected</td>

                                            <td>
                                                {time.timestampToDateString(
                                                    orderDetails.orderCollectedDate
                                                )}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Requested Delivery Time</td>

                                            <td>
                                                {time.timestampToDateString(orderDetails.requestedTime)}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Status</td>

                                            <td>
                                                {
                                                    orderDetails.statusDescription
                                                }
                                            </td>
                                        </tr>
                                       { 
                                            !!orderDetails && orderDetails.status === 5 &&
                                            <tr>
                                                <td>Cancellation Note</td>

                                                <td>
                                                    {
                                                        orderDetails.cancellationNote
                                                    }
                                                </td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                                {
                                    !!orderDetails && orderDetails.status !== 5 && 
                                    <OrderCancel 
                                        orderId={orderDetails.orderId}
                                        fetchOrderDetails={()=>this.fetchOrderDetails()}
                                    />   
                                }
                            </div>

                            <div className={style.cart}>
                                <div className={style.title}>Order Summary</div>

                                <div className={style.orderItems}>
                                    {this.renderOrderItems()}
                                </div>

                                <div className={style.footer}>
                                    <div className={style.item}>
                                        <div>Delivery Charge</div>
                                        <div>{this.getDeliveryCharge()}</div>
                                    </div>
                                    <div className={style.item}>
                                        <div>Used Points</div>
                                        <div>{this.getUsedPoints()}</div>
                                    </div>
                                    <div className={style.item}>
                                        <div>Total</div>
                                        <div>{this.getTotalPrice()}</div>
                                    </div>

                                    {     
                                        !!Order_Details &&
                                        <div className={style.item}>
                                            <div>Paid by { Order_Details.paymentMethod === 0?'Cash':'Card'}</div>
                                        </div>
                                    }
                                    <div className={style.item}>
                                        <b>Special Notes</b>
                                    </div>
                                    <div className={style.item}>
                                        <div>{Order_Details && !!Order_Details.specialNotes?Order_Details.specialNotes:'--'}</div>
                                    </div>
                              
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </Page>
        );
    }
}


