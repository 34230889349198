import { Button } from '@material-ui/core';
import React, { useState } from 'react';
import time from '../../../../../../core/lib/time';
import style from '../OrderList.module.scss';
import deliveryIcon from '../../../../img/delivery.svg';
import chevronRightIcon from '../../../../img/chevron-right.svg';
import classnames from 'classnames';
import panIcon from '../../../../img/pan.svg';
import { orderStatus } from '../../../../../../core/contants';
import checkIcon from '../../../../img/check.svg';
import printIcon from '../../../../img/print.svg';
import { OrderTypes } from '../../../../shared/Utils/common';
import CollectionOrderDialoge from './CollecetionOrderDialoge.component';
import { convertToLast3digit } from '../../../../../../utils/MainUtils';

const ReadyOrderCollectionList = ({
    params,
    orderHistory=[],
    fetchOrderByIdAndPrintReceipt,
    routeOrderDetails,
    isDanger,
    print,
    fetchOrderHistory,
    markCollected,
    selectedOrderDetails
}) => {
    const [isDialogOpen,setDialogOpen] = useState(false);

    const handleCollectClick = (orderId) =>{
        fetchOrderByIdAndPrintReceipt(orderId,false);
        setTimeout(()=>setDialogOpen(true),1000)
    }

    const confirmCollectionHandle =(paymentMethod) =>{
        // console.log("Handle callection confirm ",paymentMethod,selectedOrderDetails.orderNumber);
        markCollected(selectedOrderDetails.id);
        toggleDialogOpen();
        print();
        setTimeout(()=>print(),1000);
    }

    const toggleDialogOpen = () =>{
        setDialogOpen(!isDialogOpen)
    }

   const renderOrders =()=> {
        return orderHistory.map((i, j) => {
            if(i.status === orderStatus.ReadyOrder && i.orderType === OrderTypes.Collection)
                return (
                    <tr key={j} className={classnames({
                        [style.danger]: isDanger(i)
                    })}>
                        <td> {convertToLast3digit(i.orderNo) } </td>
                        
                        <td> {time.timestampToDateString(i.orderPlacedOn)}  </td>
                        
                        <td> {i.customerName} </td>
                        
                        <td> {i.orderCost.toFixed(2)} </td>
                        
                        <td className={style.actions}>

                            <Button
                                variant="contained"
                                onClick={() => handleCollectClick(i.orderId)}
                                color="primary"
                            >
                                {/* <img src={checkIcon} alt="" /> */}
                                Collect
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => fetchOrderByIdAndPrintReceipt(i.orderId)}>
                                <img src={printIcon} alt="" style={{padding:2}} />
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => routeOrderDetails(i.orderId)}>
                                <img src={chevronRightIcon} alt="" />
                            </Button>

                        </td>
                    </tr>
                )
            else
                return null;
        })
    }
    
    return(
        <>
            <table className={style.deliveryStyle}>
                <thead>
                    <tr>
                        <th># Order</th>
                        <th> Order Time </th>
                        <th> Customer Name</th>
             
                        <th> Cost</th>

                        <th></th>
                    </tr>
                </thead>

                <tbody>
                    {renderOrders()}
                </tbody>
            </table>

            <CollectionOrderDialoge 
                isDialogOpen={isDialogOpen}
                toggleDialogOpen = {toggleDialogOpen}
                orderData={selectedOrderDetails}
                confirmCollectionHandle={confirmCollectionHandle}
            />
        </>
    )

};

export default ReadyOrderCollectionList;
