import { Button,FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import React from 'react';
import style from '../OrderList.module.scss';
import Dialog from '../../../../../../shared/Dialog/Dialog';
import { checkDriverIsActive } from '../../../../shared/Utils/common';

const AssignDriverDialoge = ({
    params,
    isDialogOpen,
    toggleAssignDriverDialog,
    handleAssignDriver,
    selectedDriver,
    onDriverChange,
    drivers=[]
}) => {
    const renderDrivers =()=> {

        return drivers.map((i, j) => {
            return checkDriverIsActive(i)?(
                <MenuItem key={j} value={i}>{i.driverName}</MenuItem>
            ):null;
        })
    }
    return (
        <Dialog isOpen={isDialogOpen} onClose={toggleAssignDriverDialog}>
            <div className={style.dialogBox}>
                <div className={style.title}>
                    Assign Driver
                </div>

                <div className={style.form}>
                    <div className={style.formControl}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Driver</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selectedDriver}
                                onChange={onDriverChange}
                            >
                                {
                                    drivers.length?
                                        renderDrivers()
                                    :<MenuItem key={1} value='null'>No active driver found.</MenuItem>
                                }
                            </Select>
                        </FormControl>
                    </div>
                </div>

                <div className={style.tools}>
                    <Button
                        variant="contained"
                        onClick={handleAssignDriver}
                        color="primary"
                        disabled={drivers.length?false:true}
                    >
                        Assign
                    </Button>
                </div>

            </div>
        </Dialog>
    )
};

export default AssignDriverDialoge;
