import axios from "axios";

import { showConfrimBox } from '../../../shared/ComfirmAlertBox/ConfirmAlertBox';
class HttpApi {
  // host = "http://dev.foodhub.api.bizminds.io";
  host = "https://api.pizzeriaplazafoodhub.gi";
  // host = "https://localhost:44332"
  token = "";

  setToken(token) {
    axios.defaults.headers.common["authorization"] = "Bearer " + token;
    this.token = token;
  }

  sendGetRequest = async (url, props) => {
    const authToken = props?.authToken || null; // Optional chaining for brevity
    const headers = authToken
        ? { 'Authorization': `Bearer ${authToken}` }
        : undefined;
    const res = await axios.get(`${this.host}${url}`, {headers});
    const success = res.status;

    if (success === 200) {
      return res.data;
    }
    else if (success === 400 && (res.data?.message || res.data?.Message)) {
      alert((res.data?.message ?? res.data?.Message))
      throw new Error( res.data);
    } else {
      const { errMessage } = res.data;
      throw new Error(errMessage);
    }
  };

  sendPostRequest = async (url, data, props) => {
    const authToken = props?.authToken || null; // Optional chaining for brevity
  
    try {
      const headers = authToken
        ? { 'Authorization': `Bearer ${authToken}` }
        : undefined; // Use undefined if there's no token to avoid sending an empty header
  
      const res = await axios.post(`${this.host}${url}`, data, { headers });
  
      // Handle success and error responses based on status code
      if (res.status === 200) {
        return res.data;
      } 
      else if (res.status === 400 && (res.data?.message || res.data?.Message)) {
        alert((res.data?.message ?? res.data?.Message))
        throw new Error( res.data);
      } else {
        const errMessage = res.data?.errMessage || 'An unexpected error occurred';
        alert(errMessage);
        throw new Error(errMessage);
      }
    } catch (error) {
      if (error.response) {
        // The request was made, and the server responded with a status code
        const { status, data } = error.response;
  
        
        if (status === 400 && (data?.message || data?.Message)) {
          alert((data?.message ?? data?.Message))
          throw new Error( data);
        } 
        else {
          const errMessage = data?.errMessage || 'An unexpected error occurred';
          alert(errMessage);
          throw new Error(errMessage);
        }
      } else {
        // Handle errors that are not related to the response
        console.error('Network error:', error);
        throw new Error('Network error occurred');
      }
    }
  };

  sendPutRequest = async (url, data, props) => {
    let authToken = null;
    if (props && props.authToken) {
      authToken = props.authToken
    }

    if (authToken) {
      const res = await axios.put(`${this.host}${url}`, data, {
        headers: {'authorization': "Bearer " + authToken}
      });
      const success = res.status;
      if (success === 200) {
        return res.data;
      }
      else if (success === 400 && res.data.message) {
        showConfrimBox({
          title:'Validation Error',
          description:res.data.message,
          onOkClick:()=>{ }
        })
        throw new Error( res.data);
      } else {
        const { errMessage } = res.data;
        throw new Error(errMessage);
      }
    } else {
      const res = await axios.put(`${this.host}${url}`, data);
      const success = res.status;
      if (success === 200) {
        return res.data;
      } else {
        const { errMessage } = res.data;
        throw new Error(errMessage);
      }
    }
  };

  sendDeleteRequest = async (url, props) => {
    let authToken = null;

    if (props && props.authToken) {
      authToken = props.authToken
    }
    let headers = {};
    if (authToken) {
      headers = {
        'authorization': "Bearer " + authToken
      }
      
    } 
    const res = await axios.delete(`${this.host}${url}`, {
      headers: headers
    });
    const success = res.status;

    if (success === 200) {
      return res.data;
    }
    else if (success === 400 && res.data.message) {
      showConfrimBox({
        title:'Validation Error',
        description:res.data.message,
        onOkClick:()=>{ }
      })
      throw new Error( res.data);
    } else {
      const { errMessage } = res.data;
      throw new Error(errMessage);
    }
  };
}

export default new HttpApi();