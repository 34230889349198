import moment from "moment";

const Second = 1000;
const Minute = 60 * Second;
const Hour = Minute * 60;
const Day = Hour * 24;

const getUtcDate = () => {
  const now = new Date();
  const utc = new Date(now.getTime() + now.getTimezoneOffset() * 60000);
  return now;
};

const getUtcDateByDate = (timeString) => {
  const now = new Date(timeString);
  const utc = new Date(now.getTime() + now.getTimezoneOffset() * 60000);
  return now;
};

const format = (x) => {
  if (x > 9) {
    return x;
  }

  return "0" + x;
};

const isoDateToDateSting = (isoString) => {
  if (!isoString) {
    return "-";
  }

  const date = new Date(isoString);
  const month = date.getMonth() + 1;
  const day = date.getDate();

  const hours = date.getHours();
  const minutes = date.getMinutes();

  return `${format(month)}/${format(day)} ${format(hours)}:${format(minutes)}`;
};

const timestampToDateString = (timestamp) => {
  if (!timestamp) {
    return "-";
  }

  timestamp = timestamp + "z";

  const date = new Date(timestamp);
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear();

  const hours = date.getHours();
  const minutes = date.getMinutes();

  return `${format(day)}/${format(month)}/${year} ${format(hours)}:${format(
    minutes
  )}`;
};

const getMinRequestTime = (prepTime, delay) => {
  const now = new Date();
  const deliveryTime = 15;
  const timeToAck = 10;
  const minTime = new Date(
    now.getTime() + (deliveryTime + prepTime + delay + timeToAck) * 60000
  );

  return minTime;
};

const getDuration = (timestamp) => {
  if (!timestamp) {
    return "-";
  }

  timestamp = timestamp + "z";

  const duration = new Date().getTime() - new Date(timestamp).getTime();

  return duration;
};

const getStartCookingTime = (
  timestamp,
  restaurantPrepTime,
  maxPrepTime,
  deductDeliveryTime
) => {
  if (!timestamp) {
    return "-";
  }
  timestamp = timestamp + "z";

  const date = new Date(timestamp);
  const newDate = new Date(
    date.getTime() +
      (maxPrepTime - restaurantPrepTime - (deductDeliveryTime ? 15 : 0)) * 60000
  );
  const month = newDate.getMonth() + 1;
  const day = newDate.getDate();
  const year = newDate.getFullYear();

  const hours = newDate.getHours();
  const minutes = newDate.getMinutes();

  return `${format(day)}/${format(month)} ${format(hours)}:${format(minutes)}`;
};

const getTargetTime = (timestamp) => {
  if (!timestamp) {
    return "-";
  }
  timestamp = timestamp + "z";
  const deliveryTime = 15;

  const date = new Date(timestamp);
  const newDate = new Date(date.getTime() - deliveryTime * 60000);
  const month = newDate.getMonth() + 1;
  const day = newDate.getDate();
  const year = newDate.getFullYear();

  const hours = newDate.getHours();
  const minutes = newDate.getMinutes();

  return `${format(day)}/${format(month)} ${format(hours)}:${format(minutes)}`;
};

const getCurrentDateTime = () => {
  const date = new Date();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear();

  const hours = date.getHours();
  const minutes = date.getMinutes();

  return `${format(day)}/${format(month)}/${year} ${format(hours)}:${format(
    minutes
  )}`;
};

const getCurrentDateTimeWithDate = (calDate) => {
  const date = new Date(calDate);
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear();

  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds =date.getSeconds();
  return `${format(day)}/${format(month)}/${year} ${format(hours)}:${format(minutes)}:${format(seconds)}`;
};

export default {
  timestampToDateString,
  isoDateToDateSting,
  getTargetTime,
  getDuration,
  getUtcDate,
  getUtcDateByDate,
  getMinRequestTime,
  getStartCookingTime,
  getCurrentDateTime,
  getCurrentDateTimeWithDate,
  timeUnits: {
    Second,
    Minute,
    Hour,
    Day,
  },
};
