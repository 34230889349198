import React, { Component } from 'react';
import style from './CSDashboard.module.scss';

import Page from '../shared/Page/Page';

import OrderSummary from './components/OrderSummary/OrderSummary';
import OrderList from './components/OrderList/OrderList';

import dashboardIcon from '../img/dashboard.svg';
import authApi from '../../../core/api/auth-api/auth-api';
import { CS_Roles } from '../shared/Utils/common';
import Constant,{ orderStatus} from "../../../core/contants";
import { Button } from '@material-ui/core';
import masterApi from '../../../core/api/master-api/master-api';
import { showConfrimBox } from '../../../shared/ComfirmAlertBox/ConfirmAlertBox';
import CustomerIssues from './components/CustomerIssues/CustomerIssues';

export default class CSDashboard extends Component {
    constructor(){
        super();
        const selectedFilter = localStorage.getItem('CS-selected-filter');
        this.state = {
            filter: selectedFilter !== 'null'?Number(selectedFilter):orderStatus.NewOrder,
            user:null,
            isOnlineOrderAllowed:false
        }
        this.summaryChild = null
    }


    componentDidMount = () => {
        this.isPermited();
        this.getData();
    }

    async getData(){
       const isAllowed = await masterApi._csGetOnlineOrderAllowed();
       this.setState({isOnlineOrderAllowed:isAllowed});
    }
    isPermited = async () => {
        const user = await authApi.getCSUser();
        if (!CS_Roles.includes(user.roleDescription)) {
            window.location.href = "/customer-support";
            return
        }
        this.setState({ user });
    }

    onFilterChange = (filter) => {
        this.setState({
            filter
        });
        localStorage.setItem('CS-selected-filter',filter);

    }

    onGetNewOrder(){
        console.log("get New Order...",this.summaryChild);
        this.summaryChild?.fetchOrderHistory();
    }
 
    handleOnlineOrder = async() =>{
        const {isOnlineOrderAllowed} = this.state;

        const onConfirm = async() =>{
            masterApi.setOnlineOrderAllowed(!isOnlineOrderAllowed).then(()=>this.setState({isOnlineOrderAllowed:!isOnlineOrderAllowed}))
        }
        showConfrimBox({
            title:`Are you sure you want to turn ${isOnlineOrderAllowed?'off':'on'} online orders?`,
            description:``,
            okButtonText:'Yes',
            cancelButtonText:'No',
            onOkClick:onConfirm 
        })
    }

    render() {
        const { filter,user,isOnlineOrderAllowed } = this.state;

        return (
            <Page>
                <div className={style.container}>
                    <div className={style.header}>
                        <span><img src={dashboardIcon} alt="" /> Dashboard</span>
                        <Button
                            variant="contained"
                            onClick={this.handleOnlineOrder}
                            // color="primary"
                            style={{
                                fontSize: "smaller",
                                backgroundColor: isOnlineOrderAllowed? "#32bd38" : "#d9211d",
                                color:'aliceblue',
                                fontWeight:'600'
                            }}
                        >
                            Online Orders
                        </Button>
                    </div>

                                   
                        <div className={style.content}>
                            {  
                                user && user.roleDescription === CS_Roles[0] && 
                                <div>
                                    <div className={style.title}>
                                        ORDER SUMMARY
                                    </div>

                                    <div className={style.orderSummary}>
                                        <OrderSummary filter={filter} onChange={this.onFilterChange} provideCtrl={instance => { this.summaryChild = instance; }} />
                                    </div>
                                    <div className={style.orderList}>
                                        {
                                            filter !== Constant.customerIssue &&
                                                <OrderList filter={filter} onGetNewOrder={()=>this.onGetNewOrder()} />
                                        }
                                        {
                                            filter === Constant.customerIssue &&
                                                <CustomerIssues history = {this.props.history} />
                                        }
                                    </div>
                                </div>
                            }
                            {
                                user && user.roleDescription === CS_Roles[1] && 
                                <div className={style.orderList}>
                                    <OrderList filter={orderStatus.ReadyOrder} />
                                </div>
                            }


                        </div>
                </div>
            </Page>
        )
    }
}
