import React, { Component } from "react";

import "./OrderList.scss";
import OrderTopBar from "../../shared/OrderTopBar/OrderTopBar";
import time from "../../core/lib/time";

import BackButton from "../../shared/BackButton/BackButton";

import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import TimerOutlinedIcon from "@material-ui/icons/TimerOutlined";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import ListAltOutlinedIcon from "@material-ui/icons/ListAltOutlined";
import LocalMallOutlinedIcon from "@material-ui/icons/LocalMallOutlined";
import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined";
import { LinearProgress } from "@material-ui/core";

import orderApi from "../../core/api/order-api/order-api";
import authApi from "../../core/api/auth-api/auth-api";
import TopBar from "../../shared/TopBar/TopBar";

export default class OrderList extends Component {
  state = {
    orderHistory: [],
    isLoading: true,
  };

  componentDidMount = () => {
    this.getOrderHistory();
  };

  getOrderHistory = async () => {
    const user = await authApi.getUser()
    if (user.roleDescription === "POS") {
      window.location.href = "/order-page-one/";
    }
    this.setState({
      isLoading: true,
    });
    const orderHistory = await orderApi.getOrderHistory();

    this.setState({
      orderHistory: orderHistory,
      isLoading: false,
    });
  };

  routeOrderDetails = (orderId) => {
    this.props.history.push(`/order-details/${orderId}`)
  }

  elapesedTime = (startTime) => {
    let timeDiff = Math.ceil(time.getDuration(startTime) / time.timeUnits.Minute)

    if (timeDiff < 100) {
      return timeDiff + " minutes";
    }

    return time.timestampToDateString(startTime);
  };

  render() {
    const { orderHistory, isLoading } = this.state;

    return (
      <div className="order-list-container">
        <div className="top-bar">
          <TopBar label="Orders" />
        </div>
        {isLoading ? (
          <LinearProgress />
        ) : (
          <div className="section">
            <div className="back-button">
              <BackButton text="BACK TO HOME" link="/order-page-one" />
            </div>

            <div className="table-section">
              <div>
                <TableContainer component={Paper}>
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <div className="head-item">
                            <DescriptionOutlinedIcon fontSize="small" />
                            <div className="title">ORDER NUMBER</div>
                          </div>
                        </TableCell>
                        <TableCell align="left">
                          <div className="head-item">
                            <ListAltOutlinedIcon fontSize="small" />
                            <div className="title">NUMBER OF ITEMS</div>
                          </div>
                        </TableCell>
                        <TableCell align="left">
                          <div className="head-item">
                            <CheckCircleOutlineIcon fontSize="small" />
                            <div className="title">STATUS</div>
                          </div>
                        </TableCell>
                        <TableCell align="left">
                          <div className="head-item">
                            <TimerOutlinedIcon fontSize="small" />
                            <div className="title">ORDER DATE</div>
                          </div>
                        </TableCell>
                        <TableCell align="left">
                          <div className="head-item">
                            <TimerOutlinedIcon fontSize="small" />
                            <div className="title">ESTIMATED TIME</div>
                          </div>
                        </TableCell>
                        <TableCell align="left">
                          <div className="head-item">
                            <LocalMallOutlinedIcon fontSize="small" />
                            <div className="title">MULTI ORDER</div>
                          </div>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {orderHistory.map((item, i) => (
                        <TableRow hover key={i} onClick={() => { this.routeOrderDetails(item.productPreferenceNo) }}>
                          <TableCell  component="th" scope="row">
                          <div className="order-title">{item.orderNo}</div>
                            {/* <div className="head-item">
                              <ErrorOutlineOutlinedIcon fontSize="small" />
                              <div className="order-title">{item.orderNo}</div>
                            </div> */}
                          </TableCell>
                          <TableCell align="left">
                            {item.numberOfItems}
                          </TableCell>
                          <TableCell align="left">
                            {item.statusDescription}
                          </TableCell>
                          <TableCell align="left">
                            {time.timestampToDateString(item.orderPlacedOn)}
                          </TableCell>
                          <TableCell align="left">
                            {time.timestampToDateString(item.requestedDate)}
                          </TableCell>
                          <TableCell align="left">
                            {item.multiOrder ? "Yes" : "No"}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
