import moment from 'moment';
import Constant from '../../../../core/contants'

export const CS_Roles = [
    'Customer Service',
    'Delivery'
];

export const restaurantPasswords = [
    {
        id:1,
        password:'PP2020'
    },
    {
        id:5,
        password:'TA2020'
    },
    {
        id:4,
        password:'HD2021'
    }
]


const OrderTypes ={
    "Delivery":0,
    "Collection":1,
    "WalkIn":2
}

const filterDescription = {
    null:"All Orders",
    [Constant.NewOrder]:'New Orders',
    [Constant.AcknowledgedOrder]:'Acknowledged Orders',
    [Constant.CookingOrder]:'Cooking Orders',
    [Constant.ReadyOrder]:'Delivery Ready Orders',
    [Constant.ReadyCollectionOrder]:'Collections Ready Orders',
    [Constant.CollectedOrder]: "Collected Orders",
    [Constant.PaymentPending]: "Pending Payments"
}

const checkDriverIsActive =(driverData)=>{
    // console.log(driverData.driverId,driverData)
    if(driverData && !driverData.checkOut && driverData.checkIn){
        const currentUtcDate = moment().utc();
        const checkInDate = moment.utc(driverData.checkIn);
        return currentUtcDate.isSameOrAfter(checkInDate)
    }
}

const PromotionDiscountType = {
    percentage:0,
    amount:1
}

export {
    OrderTypes,
    filterDescription,
    checkDriverIsActive,
    PromotionDiscountType
}