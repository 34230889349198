import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import "./SideMenu.scss";

import { CartActions } from "../../core/constants/actions";

import shoppingCartIcon from "../../assets/shopping-cart.png";

import SimpleButton from "../SimpleButton/SimpleButton";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";

import CartItem from "../CartItem/CartItem";

import authApi from "../../core/api/auth-api/auth-api";
import cartApi from "../../core/api/cart-api/cart-api";
import masterApi from "../../core/api/master-api/master-api";
import * as sideMenuUtils from "../../utils/SideMenuUtils";
import CustomAlert from "../Alert/Alert";
import { showConfrimBox } from "../ComfirmAlertBox/ConfirmAlertBox";

class SideMenu extends Component {
    updateQtyTimer = null;

    deleteCartItemId = null;

    state = {
        userId: "",
        cart: [],
        total: 0,
        charge: 0,
        isDeleteDialogOpen: false,
        isHold: false,
        isHoldMsg:'',
        halfPizzaError:false,
        halfPizzaErrorMsg:"",
        isOnlineOrderAllowed:false
    };

    componentDidMount = () => {
        this.getCartDetails();
    };

    getCartDetails = async () => {
        let user = await authApi.getUser();
        const userId = user.userId;

        let { cart } = await cartApi.getCartDetails();
        let isOnlineOrderAllowed = await masterApi.getOnlineOrderAllowed();
        this.fetchDeliveryCharge()

        this.setState({
            cart: cart,
            userId: userId,
            isOnlineOrderAllowed:isOnlineOrderAllowed
        });
    };

    handleCheckoutClick = async ()=>{
        const {cart,walkInStatusButton,deliveryStatusButton} = this.props;
        const getMinimumAmoutValue  = await cartApi.getMinimumOrderAmount()
        if(getMinimumAmoutValue && (Number(getMinimumAmoutValue) > Number(this.getCartTotal())) && deliveryStatusButton ){  //checking miminimum amount value
          showConfrimBox({title:`Minimum order value for delivery is £${Number(getMinimumAmoutValue).toFixed(2)}`})
          return;
        }

        const onlyForAdult = cart.findIndex((item)=>item.onlyForAdult);
        // console.log("onlyForAdult",onlyForAdult,cart);
        if (cart.filter(item => item.outOfStock === true).length > 0) {
            // check if any item in the cart has gone out of stock
            return showConfrimBox({
                title:"Please remove out of stock items from the cart.",
                description:"",
                okButtonText:'',
                onCancelClick:false
            })
        }
        if(onlyForAdult !== -1 && !walkInStatusButton){
            showConfrimBox({
                title:"Please confirm that you are aged 18 or over",
                description:"",
                okButtonText:'Yes',
                cancelButtonText:'No',
                onOkClick:this.handleCheckout 
            })
        } else {
            this.handleCheckout();
        }
    }

    handleCheckout = async () => {
        let { cart } = await cartApi.getCartDetails();
        const halfPizzaValidate = sideMenuUtils.validateHalfPizza(cart);
        // console.log(halfPizzaValidate)

        if(!halfPizzaValidate.success){
            this.setState({
                halfPizzaError:true,
                halfPizzaErrorMsg:halfPizzaValidate.errMessage
            });
            return;
   
        }
        if(!this.props.walkInStatusButton){
            await this.fetchHoldState()
        }
        let isEmpty = await this.isCartEmpty()
        const { isHold } = this.state
        if (!isHold) {
            if (!isEmpty) {
                this.props.history.push("/my-details")
            }
        }
        else {
            setTimeout(() => this.setState({ isHold: false }), 3000)
        }
    }

    fetchHoldState = async () => {
        const { cart } = this.props
        const restaurants = await masterApi.getRestaurants();
        const selectedRestaurants = []
        const mapNames = new Map();
        for (const item of cart) {
            if (!mapNames.has(item.restaurantId)) {
                mapNames.set(item.restaurantId, true);
                selectedRestaurants.push(item.restaurantId);
            }
        }
        for (let restaurant of restaurants) {
            if (selectedRestaurants.includes(restaurant.id)) {
                if (!restaurant.isAllowOrder) {
                    this.setState({ 
                        isHold: !restaurant.isAllowOrder,
                        isHoldMsg:`${restaurant.name} is closed, please check later.` 
                    })
                    break;
                }

            }
        };
    }

    fetchDeliveryCharge = async () => {
        const charge = await masterApi.getDeliveryCharge()
        this.setState({
            charge: charge
        })
    }

    handleAddClick = (i) => {
        const { cart } = this.props;
        let newCart = cart;
        newCart[i].quantity = newCart[i].quantity + 1;
        this.setState(
            {
                cart: newCart,
            },
            () => {
                this.updateQuantity(i);
            }
        );
    };

    handleMinusClick = (i) => {
        const { cart } = this.props;

        if (cart[i].quantity > 1) {
            let newCart = cart;
            newCart[i].quantity = newCart[i].quantity - 1;
            this.setState({cart: newCart,},() => {this.updateQuantity(i)});
        }
    };

    confirmDelete = async (i) => {
        const { cart } = this.props;

        this.setState({
            deleteCartItemId: cart[i].cartItemID,
        });

        this.openDialog();
    };

    removeItem = async () => {
        const { deleteCartItemId } = this.state;

        this.closeDialog();
        await cartApi.removeItem(deleteCartItemId);
        this.getCartDetails();
    };

    getTotalPaymentAmount = () => {
        const { usePoints,location,cartFinalTotal } = this.props;
        let total = this.getCartTotal()

        if (Boolean(location.pathname.match('/my-details'))) {
            total = total + this.getDeliveryCharges()
        }
        if (usePoints) {
            total = total - this.getUsedPoints();
        }
        if(Number(cartFinalTotal) !== total){
            this.props.setCartTotal(total.toFixed(2))
        }
        return total

    };

    getUsedPoints = () => {
        const { usePoints, points, location } = this.props;
        let total = this.getCartTotal()

        if (!usePoints) {
            return 0;
        } else {
            if (total > (points / 100)) {
                return points / 100;
            }
            else {
                return total
            }

        }

    };

    getCartTotal = () => {
        const { cart,cartInfo } = this.props;

        if(cartInfo && cartInfo?.['netTotal']){
            return cartInfo?.['netTotal']
        }
        let total = 0;
        cart.map((item) => (total = total + item.quantity * item.unitPrice));

        return total;
    };

    getDeliveryCharges = () => {
        const { deliveryStatusButton } = this.props;
        const { charge } = this.state
        let deliveryCharge = 0;
        if (deliveryStatusButton) {
            deliveryCharge = charge
        }

        return deliveryCharge;

    };

    isCartEmpty = () => {
        const { cart } = this.props;
        return cart.length === 0;
    };

    updateQuantity = (i) => {
        const { cart } = this.props;
        let cartItem = {
            itemId: cart[i].cartItemID,
            quantity: cart[i].quantity,
            cartId: cart[i].cartID,
        };
        if (this.updateQtyTimer) {
            clearTimeout(this.updateQtyTimer);
        }
        this.updateQtyTimer = setTimeout(() => {
            this.sendUpdateRequest(cartItem);
            this.updateQtyTimer = null;
        }, 1000);
    };

    sendUpdateRequest = async (cartItem) => {
        const res = await cartApi.editQuantity(cartItem);
    };

    clearCart = async () => {
        const { cart } = this.props;
        if (cart.length !== 0) {
            const res = await cartApi.clearCart(cart[0].cartID);
        }

    };

    getCartItems() {
        const { cart } = this.props;

        return cart.map((item, index) => (
            <CartItem
                key={index}
                indexValue={index}
                productName={item.productName}
                productDescription={item.productDescription}
                itemProductIngredients={item.itemProductIngredients}
                productPreferenceOptions={item.productPreferenceOptions}
                productSizeVarientName={item.productSizeVarientName}
                totalPrice={item.totalPrice}
                quantity={item.quantity}
                productId={item.productId}
                productSizeVarientId={item.productSizeVarientId}
                productSizeVarientIngredientId={item.productSizeVarientIngredientId}
                productPreferenceId={item.productPreferenceId}
                productPreferenceOptionId={item.productPreferenceOptionId}
                unitPrice={item.unitPrice}
                cartItemId={item.cartItemID}
                outOfStock={item.outOfStock}
                handleAddClick={this.handleAddClick}
                handleMinusClick={this.handleMinusClick}
                removeCartItem={this.confirmDelete}
            />
        ));
    }

    openDialog = () => {
        this.setState({
            isDeleteDialogOpen: true,
        });
    };

    closeDialog = () => {
        this.setState({
            isDeleteDialogOpen: false,
        });
    };

    render() {
        const { location, deliveryStatusButton ,cartInfo} = this.props;
        const { 
            isDeleteDialogOpen, 
            isHold,halfPizzaError,
            halfPizzaErrorMsg,
            isHoldMsg ,
            isOnlineOrderAllowed
        } = this.state;
        return (
            <div className="side-menu">
                <div className="title-container">
                    <div className="title">My order</div><SimpleButton onFunction={this.clearCart} disabled={this.isCartEmpty()} text="CLEAR" />
                </div>
                {this.isCartEmpty() ? (
                    <div className="empty-basket">
                        <div className="img">
                            <img src={shoppingCartIcon} />
                        </div>
                        Your basket is empty
                    </div>
                ) : (
                    <div className="cart-items">{this.getCartItems()}</div>
                )}

                <div className="cart-bottom">
                    {Boolean(location.pathname.match('/my-details')) && cartInfo && cartInfo?.['totalPrice'] &&
                        <div className="sub-total">
                            <div>CART TOTAL</div>
                            <div>£{cartInfo?.['totalPrice'].toFixed(2)}</div>
                        </div>
                    }
                    {   cartInfo && cartInfo?.['promoDiscount']  > 0 &&
                        (
                            <>
                                {!Boolean(location.pathname.match('/my-details')) &&
                                    <div className="sub-total">
                                        <div>CART TOTAL</div>
                                        <div>£{cartInfo?.['totalPrice'].toFixed(2)}</div>
                                    </div>
                                }
                                <div className="sub-total">
                                    <div>
                                        <div>PROMO DISCOUNT</div>
                                        <div className="promoName">({cartInfo?.['promoName']})</div>
                                    </div>
                                    <div>£{cartInfo?.['promoDiscount'].toFixed(2)}</div>
                                </div>
                            </>
                        )              

                    }
                    {deliveryStatusButton && Boolean(location.pathname.match('/my-details')) &&
                        <div className="sub-total">
                            <div>DELIVERY CHARGE</div>
                            <div>£{this.getDeliveryCharges().toFixed(2)}</div>
                        </div>
                    }
                    {Boolean(location.pathname.match('/my-details')) &&
                        <div className="sub-total">
                            <div>USED POINTS</div>
                            <div>£{this.getUsedPoints().toFixed(2)}</div>
                        </div>
                    }


                    <div className="sub-total">
                        <div>PAYMENT TOTAL</div>
                        <div>£{this.getTotalPaymentAmount().toFixed(2)}</div>
                    </div>

                    <div className="checkout">
                        {
                           ( Boolean(location.pathname.match('/my-details')) || !isOnlineOrderAllowed) || (
                                // <Link to={this.isCartEmpty()  ? "#" : "/my-details"}>
                                <SimpleButton disabled={this.isCartEmpty()} text="CHECKOUT" onFunction={this.handleCheckoutClick} />
                                // </Link>
                            )
                        }

                    </div>
                </div>

                <Dialog
                    open={isDeleteDialogOpen}
                    onClose={this.closeDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to remove this item from your basket?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.removeItem} color="primary">
                            Yes
                        </Button>
                        <Button onClick={this.closeDialog} color="primary" autoFocus>
                            No
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={isHold}>
                    <DialogTitle >Alert</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {isHoldMsg}
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
                <CustomAlert
                    open={halfPizzaError}
                    title="Alert!"
                    description={halfPizzaErrorMsg}
                    onOkClick={()=>{
                        this.setState({
                            halfPizzaError:false,
                            halfPizzaErrorMsg:''
                        })
                    }}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { cart, usePoints, points,cartInfo,cartFinalTotal } = state.cartReducer;
    const { walkInStatusButton, pickUpStatusButton, deliveryStatusButton } = state.orderReducer;
    return {
        cart, usePoints, points, walkInStatusButton,
        pickUpStatusButton,
        deliveryStatusButton,
        cartInfo,
        cartFinalTotal
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setCartTotal: (netTotal=0) => {
            dispatch({
                type: CartActions.CART_UPDATE,
                payload:{cartFinalTotal:netTotal} ,
            });
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(SideMenu));
