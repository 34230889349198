import { Button } from '@material-ui/core';
import React from 'react';
import time from '../../../../../../core/lib/time';
import style from '../OrderList.module.scss';
import chevronRightIcon from '../../../../img/chevron-right.svg';
import classnames from 'classnames';
import panIcon from '../../../../img/pan.svg';
import { orderStatus } from '../../../../../../core/contants';
import { convertToLast3digit } from '../../../../../../utils/MainUtils';
import { showConfrimBox } from '../../../../../../shared/ComfirmAlertBox/ConfirmAlertBox';
import customerOrderApi from '../../../../../../core/api/customer-order-api/customer-order.api';
import { showToast } from '../../../../../../shared/ToastAlert/ToastAlert';

const PendingOrderList = ({
    orderHistory=[],
    routeOrderDetails,
    fetchOrderHistory=()=>{},

}) => {


    const proceedOrder = (orderId) =>{
        showConfrimBox({
            title:"Are you sure you want to proceed this order?",
            description:"As the payment is not completed.",
            okButtonText:"YES",
            onOkClick:async ()=>{
                customerOrderApi.updateOrderState(orderId, orderStatus.NewOrder)
                .then(()=>{
                    showToast({
                        title:"Order Proceed Successfully."
                    });
                    fetchOrderHistory()
                })
                .catch(()=>  showToast({title:"Failed to update order, Please try again."}))
            }
        })
    }


   const renderOrders =()=> {
        return orderHistory.map((i, j) => {
            return (
                <tr key={j} >
                    <td> {convertToLast3digit(i.orderNo)} </td>
                    
                    <td> {time.timestampToDateString(i.orderPlacedOn)}  </td>

                    <td> {i.customerName} </td>
                    
                    <td> {i.orderCost.toFixed(2)} </td>
                    
               
                    <td className={style.actions}>
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={() => proceedOrder(i.orderId)}>
                            Proceed Order
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => routeOrderDetails(i.orderId)}>
                            <img src={chevronRightIcon} alt="" />
                        </Button>

                    </td>
                </tr>
            )
        })
    }
    
    return(
        <table className={style.deliveryStyle}>
                <thead>
                    <tr>
                        <th># Order</th>
                        <th> Order Time </th>
                        <th> Customer</th>
                        <th> Cost </th>
                        <th></th>
                    </tr>
                </thead>

                <tbody>
                    {renderOrders()}
                </tbody>
            </table>
    )

};

export default PendingOrderList;
