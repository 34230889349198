import { 
    Button, 
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogContentText, 
 } from '@material-ui/core';
import moment from 'moment';
import React, { createRef, forwardRef, PureComponent, useImperativeHandle } from 'react';
import orderApi from '../../../../../core/api/order-api/order-api';
import CustomDatePicker from '../../../../../shared/DatePicker/DatePicker';
import exportIcon from "../../../img/external-link.svg";
import csvToJson from "convert-csv-to-json";
import {
  useJsonToCsv
} from 'react-json-csv';
import style from "../Sidebar.module.scss";
import time from '../../../../../core/lib/time';

export default class ExportOrders extends PureComponent {
  jsonToCsvRef = createRef();
  constructor(props) {
    super(props);
    this.state = {
        openDialog:false,
        startDate:moment().subtract(1, 'day'),
        endDate:moment().subtract(1, 'day'),
        loading:false
    };
  }
  handleButtonClick=()=>{
    this.setState({openDialog:true});

  }

  isDateBetweenDates = () =>{
    
  }
  handleSubmit = async () =>{
        const {startDate,endDate} = this.state;
        var _startDate   = moment(startDate).format("YYYY/MM/DD");
        var _endDate     = moment(endDate).format("YYYY/MM/DD");
    
        this.setState({loading:true})
        const csvstring = await orderApi.exportOrders();

        // const csvFile = new Blob([csvstring], { type: 'text/csv;charset=utf-8;' })
        // let fileName = `Order Data ${time.getCurrentDateTime()}.csv`
        // FileSaver.saveAs(csvFile, fileName);

        let json = csvToJson.fieldDelimiter(',').csvStringToJson(csvstring);

        let filterJson = !!json && json.length ?json.filter(res=>{
            var compareDate = moment(res['DateofOrder'], "DD/MM/YYYY");
            return(
                (res['OrderType'] === "Delivery" || res['OrderType'] === "Collection") && 
                compareDate.isBetween(_startDate,_endDate,null,'[]')
            )
        }):{}; 

        let fileName = `Order Data ${time.getCurrentDateTime()}`

        this.jsonToCsvRef.current.JsonToCSV(filterJson,fileName);

        this.setState({loading:false})

  }

  handleClose = () =>{
      this.setState({openDialog:false});
  }

  render() {
      const {openDialog,startDate,endDate,loading} = this.state;
    return (
        <div>
            <div className={style.navItem} onClick={this.handleButtonClick}>
                <img src={exportIcon} alt="" /> Export Orders
            </div>
            <Dialog open={openDialog}>
                <DialogContent>
                    <DialogContentText>Export Orders</DialogContentText>
                    {
                        loading &&
                        <h4 style={{marginBottom:0}}>Please wait while fetching data....</h4>
                    }
                    <CustomDatePicker 
                        onChange={(value)=>this.setState({startDate:value})}
                        value={startDate}
                        label="Start Date"
                    />
                    <CustomDatePicker 
                        onChange={(value)=>this.setState({endDate:value})}
                        value={endDate}
                        label="End Date"
                    />
                </DialogContent>
                <DialogActions>
                   
                    <Button 
                        color="primary" 
                        variant="contained" 
                        onClick={this.handleSubmit}
                        disabled={loading}
                    >
                        Submit
                    </Button>

                    <Button color="primary" onClick={this.handleClose}>
                        Cancel
                    </Button>
                </DialogActions>
                
            </Dialog>
            
            <JsonToCsv  ref={this.jsonToCsvRef}/>
        </div>
    );
  }
}



const  JsonToCsv=forwardRef((props,ref)=>{

    const { saveAsCsv } = useJsonToCsv();
    useImperativeHandle(ref, () => ({
      JsonToCSV(data,filename) {
       const fields = {
          "DateofOrder": "Date of Order",
          "OrderType": "Order Type",
          "Time": "Time",
          "OrderNumber": "Order Number",
          "OrderStatus": "Order Status",
          "TotalOrderValue":"Total Order Value",
          "TotalPointsUsed": "Total Points Used",
          "PaymentMethod": "Payment Method",
          "Restaurant1Total": "Pizzeria Total",
          "Restaurant2Total": "Restaurant2 Total",
          "Restaurant3Total": "Heal Dine Total",
          "Restaurant4Total": "Takeaway Total",
        };
  
        saveAsCsv({ data, fields, filename })
      }
  
    }));
  
    return (
      <></>
    )
  })