import React, { Component } from "react";
import "./EditOrderItem.scss";
import SideMenu from "../../shared/SideMenu/SideMenu";
import TopBar from "../../shared/TopBar/TopBar";
import SimpleButton from "../../shared/SimpleButton/SimpleButton";
import BackButton from "../../shared/BackButton/BackButton";
import TermsBar from "../../shared/TermsBar/TermsBar";

import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputAdornment from "@material-ui/core/InputAdornment";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import IconButton from "@material-ui/core/IconButton";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import { LinearProgress } from "@material-ui/core";

import productApi from "../../core/api/product-api/product-api";
import cartApi from "../../core/api/cart-api/cart-api";
import authApi from "../../core/api/auth-api/auth-api";

export default class OrderItem extends Component {
    state = {
        isLoading: true,
        product: [],
        sizes: [],
        ingredients: [],
        preferences: [],
        selectedPreferences: [],
        productId: null,
        cartItemID: null,
        userId: null,
        productSizeVarientId: "",
        selectedIngredients: [],
        disabledIngredients: [],
        price: 0,
        successMessage: "",
    };

    componentDidMount = () => {
        this.getProduct();
    };

    componentDidUpdate() {
        if (this.state.productId !== this.props.match.params.productId) {
            this.getProduct();
        }
    }

    getProduct = async () => {
        const productId = await this.props.match.params.productId;
        const product = await productApi.getProduct(productId);

        let user = await authApi.getUser();
        const userId = user.userId;

        let { cart } = await cartApi.getCartDetails();

        cart.forEach((item) => {
            if (Number(item.productId) === Number(productId)) {
                console.log(item);

                let selectedPreferences = [];
                item.productPreferenceOptions.forEach((cartOp) => {
                    product.preferences.forEach((prodOp) => {
                        if (cartOp.productPreferenceId === prodOp.productPreferenceId) {
                            selectedPreferences.push(cartOp.productPreferenceOptionId);
                        }
                    });
                });

                let selectedIngredients = [];
                item.itemProductIngredients.forEach((i) => {
                    selectedIngredients.push(i.ingredient);
                });

                this.setState({
                    productId: productId,
                    product: item,
                    quantity: item.quantity,
                    cartItemID: item.cartItemID,
                    selectedIngredients: selectedIngredients,
                    productSizeVarientId: item.productSizeVarientId,
                    selectedPreferences: selectedPreferences,
                });
            }
        });

        let disabledIngredients = [];
        product.ingredients.forEach((item) => {
            if (!item.canModify) {
                disabledIngredients.push(item.ingredientDescription);
            }
        });

        this.setState({
            userId: userId,
            sizes: product.sizes,
            ingredients: product.ingredients,
            preferences: product.preferences,
            price: product.price,
            disabledIngredients: disabledIngredients,
            isLoading: false,
            successMessage: "",
        });

        this.getPrice();
    };

    getPrice = () => {
        const {
            selectedIngredients,
            productSizeVarientId,
            ingredients,
            sizes,
        } = this.state;

        let price = 0;

        sizes.forEach((item) => {
            if (item.productSizeVarientId === productSizeVarientId) {
                price = item.price;
            }
        });

        selectedIngredients.forEach((selected) => {
            ingredients.forEach((item) => {
                if (item.ingredientDescription === selected && !item.isStandard) {
                    item.ingredientCosts.forEach((i) => {
                        if (i.productSizeVariantId === productSizeVarientId) {
                            price = price + i.price;
                        }
                    });
                }
            });
        });

        this.setState({
            price: price,
        });
    };


    handleAddClick = () => {
        const { product } = this.state;
        let newProduct = product;
        newProduct.quantity = newProduct.quantity + 1;
        this.setState({
            product: newProduct,
        });
    };

    handleMinusClick = () => {
        const { product } = this.state;
        if (product.quantity > 1) {
            let newProduct = product;
            newProduct.quantity = newProduct.quantity - 1;
            this.setState({
                product: newProduct,
            });
        }
    };

    handleSizeChange = (event) => {
        event.persist();

        const { sizes, product } = this.state;
        let newProduct = product;

        sizes.forEach((item) => {
            if (item.productSizeVarientId * 10 === event.target.value) {
                newProduct.productSizeVarientId = event.target.value / 10;
                newProduct.unitPrice = item.price;
                this.setState({
                    product: newProduct,
                    productSizeVarientId: event.target.value / 10,
                },
                    () => {
                        this.getPrice();
                    });
            }
        });
    };

    handleIngredientChange = (event) => {
        event.persist();

        let { selectedIngredients } = this.state;
        const { disabledIngredients } = this.state;

        let newSelect = event.target.value;
        let difference = "";
        if (selectedIngredients.length > newSelect.length) {
            difference = selectedIngredients.filter((x) => !newSelect.includes(x));
            difference = difference[0];

            if (disabledIngredients.indexOf(difference) === -1) {
                const index = selectedIngredients.indexOf(difference);
                selectedIngredients.splice(index, 1);
            }
        } else {
            difference = newSelect.filter((x) => !selectedIngredients.includes(x));
            difference = difference[0];

            if (disabledIngredients.indexOf(difference) === -1) {
                selectedIngredients.push(difference);
            }
        }

        this.setState({
            selectedIngredients: selectedIngredients,
        },
            () => {
                this.getPrice()
            });
    };

    handlePreferencesChange = (event) => {
        event.persist();

        const { selectedPreferences } = this.state;
        let newSelected = selectedPreferences;
        newSelected[parseInt(event.target.name)] = event.target.value / 10;
        this.setState({
            selectedPreferences: newSelected,
        });
    };

    handleSubmit = async () => {
        const {
            product,
            cartItemID,
            ingredients,
            preferences,
            selectedPreferences,
            selectedIngredients,
            price
        } = this.state;

        this.setState({
            successMessage: "",
        });

        let productPreferenceOptions = [];

        preferences.map((item, i) => {
            let obj = {
                productPreferenceOptionId: selectedPreferences[i],
                productPreferenceId: item.productPreferenceId,
            };
            productPreferenceOptions.push(obj);
        });

        let selectedIngredientIds = [];

        selectedIngredients.forEach((item) => {
            ingredients.forEach((i) => {
                if (i.ingredientDescription === item) {
                    selectedIngredientIds.push(i.ingredientId);
                }
            });
        });

        let cartItem = {
            quantity: product.quantity,
            productId: product.productId,
            productSizeVarientId: product.productSizeVarientId,
            selectedIngredientIds: selectedIngredientIds,
            productPreferenceOptions: productPreferenceOptions,
            unitPrice: price,
            cartItemId: cartItemID,
        };

        try {
            await cartApi.addItem(cartItem);
            this.getProduct()

            this.setState({
                successMessage: "Basket Updated",
            });
        } catch (err) {
            console.error(err);
        }
    };

    render() {
        const { sizes, ingredients, preferences } = this.state;
        const {
            product,
            selectedPreferences,
            selectedIngredients,
            disabledIngredients,
            successMessage,
            price,
            isLoading,
            productSizeVarientId
        } = this.state;
        return (
            <div className="edit-order-item-container">
                <div className="section">
                    <div className="top">
                        <TopBar />
                    </div>
                    {isLoading ? (
                        <LinearProgress />
                    ) : (
                        <div className="child">
                            <div className="left">
                                <div className="title">{product.productName}</div>
                                <div className="subtitle">{product.productDescription}</div>
                                <div className="form">
                                    <div className="item">
                                        <div className="item-name">Size</div>
                                        <div className="item-action">
                                            <FormControl variant="outlined">
                                                <Select
                                                    fullWidth
                                                    value={product.productSizeVarientId * 10}
                                                    onChange={this.handleSizeChange}
                                                    labelId="demo-simple-select-outlined-label"
                                                    id="demo-simple-select-outlined"
                                                >
                                                    {sizes.map((item, i) => (
                                                        <MenuItem
                                                            value={item.productSizeVarientId * 10}
                                                            key={i}
                                                        >
                                                            {item.description}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>

                                    {preferences.map((item, index) => (
                                        <div className="item" key={index}>
                                            <div className="item-name">{item.preference}</div>
                                            <div className="item-action">
                                                <FormControl variant="outlined">
                                                    <Select
                                                        fullWidth
                                                        value={selectedPreferences[index] * 10}
                                                        onChange={this.handlePreferencesChange}
                                                        labelId="demo-simple-select-outlined-label"
                                                        name={index.toString()}
                                                    >
                                                        {item.options.map((op, i) => (
                                                            <MenuItem key={i} value={op.id * 10}>
                                                                {op.description}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </div>
                                    ))}
                                    {ingredients.length ? (
                                        <div className="item">
                                            <div className="item-name">
                                                <div>Extras</div>
                                                <div style={{fontSize: 'smaller'}}>(click to edit)</div>
                                            </div>
                                            <div className="item-action">
                                                <FormControl variant="outlined">
                                                    <Select
                                                        fullWidth
                                                        multiple
                                                        value={selectedIngredients}
                                                        renderValue={(selected) => selected.join(", ")}
                                                        onChange={this.handleIngredientChange}
                                                    >
                                                        {ingredients.map((item, i) => (
                                                            <MenuItem
                                                                key={item.ingredientDescription}
                                                                value={item.ingredientDescription}
                                                            >
                                                                <Checkbox
                                                                    checked={
                                                                        selectedIngredients.indexOf(
                                                                            item.ingredientDescription
                                                                        ) > -1
                                                                    }
                                                                    disabled={
                                                                        disabledIngredients.indexOf(
                                                                            item.ingredientDescription
                                                                        ) > -1
                                                                    }
                                                                />
                                                                <ListItemText
                                                                    // primary={item.ingredientDescription}
                                                                    primary={`${item.ingredientDescription} - (£${(item.ingredientCosts.find(item=>item.productSizeVariantId === productSizeVarientId)['price']).toFixed(2)})`}

                                                                />
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </div>
                                    ) : (
                                        <div></div>
                                    )}
                                    <div className="item">
                                        <div className="item-name"></div>
                                        <div className="item-action">
                                            <div className="count">
                                                <TextField
                                                    variant="outlined"
                                                    size="small"
                                                    value={product.quantity}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <IconButton onClick={this.handleMinusClick}>
                                                                    <RemoveIcon fontSize="small" />
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton onClick={this.handleAddClick}>
                                                                    <AddIcon fontSize="small" />
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="item-name"></div>
                                        <div className="item-action">
                                            <div className="price">
                                                £{(price * product.quantity).toFixed(2)}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="success-message">{successMessage}</div>

                                <div className="actions">
                                    <SimpleButton text="SAVE" onFunction={this.handleSubmit} />
                                </div>
                            </div>
                            <div className="right">
                                <div className="back">
                                    <BackButton text="BACK TO HOME" link="/order-page-one" />
                                </div>
                                <div className="thumb">
                                    <img src={product.thumbnailUrl} alt="thumbnail" />
                                </div>
                            </div>
                        </div>)
                    }
                    <div className="footer">
                        <TermsBar />
                    </div>
                </div>
                <div className="side-menu-container">
                    <SideMenu />
                </div>
            </div>
        );
    }
}
