import React, { Component } from "react";
import "./MyProfile.scss";

import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import PersonIcon from "@material-ui/icons/Person";
import PhoneIcon from "@material-ui/icons/Phone";

import SideMenu from "../../shared/SideMenu/SideMenu";
import TopBar from "../../shared/TopBar/TopBar";
import SimpleButton from "../../shared/SimpleButton/SimpleButton";
import BackButton from "../../shared/BackButton/BackButton";

import authApi from "../../core/api/auth-api/auth-api";
import accountApi from "../../core/api/account-api/account-api";

import StarsIcon from '@material-ui/icons/Stars';

export default class MyProfile extends Component {
    state = {
        user: null,

        email: '',
        firstName: '',
        lastName: '',
        phoneNumber: '',
        password: '',
        cpassword: '',
        currentPassword: '',

        emailError: false,
        firstNameError: false,
        lastNameError: false,
        phoneNumberError: false,
        passwordError: false,
        currentPasswordError: false,
        emailErrorText: '',
        firstNameErrorText: '',
        lastNameErrorText: '',
        phoneNumberErrorText: '',
        passwordErrorText: "",
        currentPasswordErrorText: "",

        foodHubPoint: '',

        successMessage: ''
    };

    componentDidMount = () => {
        this.getUserDetails()
    }

    getUserDetails = async () => {
        let user = await authApi.getUser()

        this.setState({
            user,
            email: user.email,
            firstName: user.firstName,
            lastName: user.lastName,
            phoneNumber: user.phoneNumber,
        })

        this.fetchLoyaltyPoints();
    }

    isPOS() {
        const { user } = this.state
        let isPos = false
        console.log(user)
        if (!!user && user.roleDescription === "POS") {
            isPos = true
        }
        return isPos
    }

    fetchLoyaltyPoints = async () => {
        const { user } = this.state;
        const foodHubPoint = await accountApi.getPoints(user.userId);

        this.setState({
            foodHubPoint
        })
    }

    handleChange = (event) => {
        event.persist();

        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handlePhoneChange = (event) => {
        event.persist();
        const onlyNums = event.target.value.replace(/[^0-9]/g, '');
        // if (onlyNums.length < 12) {
        this.setState({ [event.target.name]: onlyNums });
        // }

    };

    
    handleSubmit = async () => {
        const { password, cpassword, currentPassword, user } = this.state

        let passwordErrorText = ''
        let currentPasswordErrorText = ''

        let passwordError = false
        let currentPasswordError = false

        if (password) {
            if (!currentPassword) {
                currentPasswordError = true
                currentPasswordErrorText = "This field cannot be empty"
            }

            if (password !== cpassword) {
                passwordError = true
                passwordErrorText = "New password and Re-Entered New Password didn't match "
            }

            let data = {
                password: currentPassword,
                newPassword: password,
                conformPassword: cpassword
            }

            if (!passwordError && !currentPasswordError) {

                try {
                    await accountApi.updatePassword(user.userId, data);
                    this.updateUser()
                    // this.props.history.push('/profile');
                } catch (err) {
                    this.setState({
                        passwordError: true,
                        passwordErrorText: err.message,
                        currentPasswordError: true,
                        currentPasswordErrorText: err.message,
                        password: '',
                        cpassword: '',
                        currentPassword: '',
                    })
                }
            } else {
                this.setState({
                    passwordError: passwordError,
                    passwordErrorText: passwordErrorText,
                    currentPasswordError: currentPasswordError,
                    currentPasswordErrorText: currentPasswordErrorText,
                })
            }

        } else {
            this.updateUser()
        }
    }

    updateUser = async () => {
        let user = await authApi.getUser()
        const { email, firstName, lastName, phoneNumber } = this.state

        let emailErrorText = ''
        let firstNameErrorText = ''
        let lastNameErrorText = ''
        let phoneNumberErrorText = ''

        let emailError = false
        let firstNameError = false
        let lastNameError = false
        let phoneNumberError = false

        if (!email) {
            emailError = true
            emailErrorText = 'This field cannot be empty'
        }

        if (!firstName) {
            firstNameError = true
            firstNameErrorText = 'This field cannot be empty'
        }
        if (!lastName) {
            lastNameError = true
            lastNameErrorText = 'This field cannot be empty'
        }
        if (!phoneNumber) {
            phoneNumberError = true
            phoneNumberErrorText = 'This field cannot be empty'
        }

        let pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

        if (!pattern.test(email)) {
            emailError = true
            emailErrorText = "Please enter valid email address.";
        }

        let data = {
            userId: user.userId,
            role: user.role,
            roleDescription: user.roleDescription,
            restaurantId: user.restaurantId,
            restaurant: user.restaurant,
            foodHubPoint: user.foodHubPoint,
            firstName: firstName,
            lastName: lastName,
            phoneNumber: phoneNumber,
            email: email
        }

        if (!emailError && !firstNameError && !lastNameError && !phoneNumberError) {

            try {
                const user = await accountApi.update(data);
                authApi.setUser(user);
                // this.props.history.push('/profile');

                this.setState({
                    email: user.email,
                    firstName: user.firstName,
                    lastName: user.lastName,
                    phoneNumber: user.phoneNumber,
                    successMessage: "User Profile Updated"
                })
            } catch (err) {
                console.log(err);
            }
        } else {
            this.setState({
                emailError: emailError,
                emailErrorText: emailErrorText,
                firstNameError: firstNameError,
                firstNameErrorText: firstNameErrorText,
                lastNameError: lastNameError,
                lastNameErrorText: lastNameErrorText,
                phoneNumberError: phoneNumberError,
                phoneNumberErrorText: phoneNumberErrorText
            })
        }

    }

    render() {
        const { successMessage } = this.state;

        return (
            <div className="myProfile-container">
                <div className="section">
                    <div className="top">
                        <TopBar />
                    </div>
                    <div className="child">
                        <form onSubmit={this.handleSubmit}>
                            <div className="title">My Profile</div>
                            <div className="split-form">
                                <Grid container>
                                    <div className="login-form">
                                        <div className="form-item">
                                            <div className="name">FIRST NAME</div>
                                            <TextField
                                                onChange={this.handleChange}
                                                error={this.state.firstNameError}
                                                helperText={this.state.firstNameErrorText}
                                                value={this.state.firstName}
                                                name="firstName"
                                                variant="outlined"
                                                fullWidth />
                                        </div>
                                        <div className="form-item">
                                            <div className="name">PHONE NUMBER</div>
                                            <TextField
                                                onChange={this.handlePhoneChange}
                                                error={this.state.phoneNumberError}
                                                helperText={this.state.phoneNumberErrorText}
                                                value={this.state.phoneNumber}
                                                name="phoneNumber"
                                                variant="outlined"
                                                fullWidth
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <IconButton disabled>
                                                                <PhoneIcon />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div className="login-form">
                                        <div className="form-item">
                                            <div className="name">LAST NAME</div>
                                            <TextField
                                                onChange={this.handleChange}
                                                error={this.state.lastNameError}
                                                helperText={this.state.lastNameErrorText}
                                                value={this.state.lastName}
                                                name="lastName"
                                                variant="outlined"
                                                fullWidth />
                                        </div>

                                        <div className="form-item">
                                            <div className="name">EMAIL</div>
                                            <TextField
                                                onChange={this.handleChange}
                                                error={this.state.emailError}
                                                helperText={this.state.emailErrorText}
                                                value={this.state.email}
                                                name="email"
                                                variant="outlined"
                                                fullWidth
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <IconButton disabled>
                                                                <PersonIcon />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </div>
                                    </div>
                                </Grid>
                            </div>

                            <div className='changePW'>
                                Change Password
                            </div>

                            <div className="split-form">
                                <Grid container>
                                    <div className="login-form">
                                        <div className="form-item">
                                            <div className="name">CURRENT PASSWORD</div>
                                            <TextField
                                                onChange={this.handleChange}
                                                type="password"
                                                error={this.state.currentPasswordError}
                                                helperText={this.state.currentPasswordErrorText}
                                                value={this.state.currentPassword}
                                                name="currentPassword"
                                                variant="outlined"
                                                fullWidth />
                                        </div>
                                        <div className="form-item">
                                            <div className="name">RE-ENTER NEW PASSWORD</div>
                                            <TextField
                                                onChange={this.handleChange}
                                                type="password"
                                                error={this.state.passwordError}
                                                helperText={this.state.passwordErrorText}
                                                value={this.state.cpassword}
                                                name="cpassword"
                                                variant="outlined"
                                                fullWidth />
                                        </div>
                                    </div>

                                    <div className="login-form">
                                        <div className="form-item">
                                            <div className="name">NEW PASSWORD</div>
                                            <TextField
                                                onChange={this.handleChange}
                                                type="password"
                                                error={this.state.passwordError}
                                                helperText={this.state.passwordErrorText}
                                                value={this.state.password}
                                                name="password"
                                                variant="outlined"
                                                fullWidth />
                                        </div>

                                    </div>
                                </Grid>
                            </div>
                            {!this.isPOS() &&
                                <div className="points">
                                    <StarsIcon className="icon" /> Your Food Hub points balance is {this.state.foodHubPoint}
                                </div>
                            }

                            {
                                successMessage && (
                                    <div className="success-message">
                                        {successMessage}
                                    </div>
                                )
                            }

                            <div className="actions">
                                <SimpleButton text="SAVE" onFunction={this.handleSubmit} onSubmit={this.handleSubmit} />
                                <BackButton text="BACK TO HOME" link="/order-page-one" />
                            </div>
                        </form>
                    </div>
                </div>
                <div className="side-menu-container">
                    <SideMenu />
                </div>
            </div>
        );
    }
}
