import { Button } from '@material-ui/core';
import React, { useRef, useState } from 'react';
import time from '../../../../../../core/lib/time';
import style from '../OrderList.module.scss';
import deliveryIcon from '../../../../img/delivery.svg';
import chevronRightIcon from '../../../../img/chevron-right.svg';
import classnames from 'classnames';
import panIcon from '../../../../img/pan.svg';
import { orderStatus } from '../../../../../../core/contants';
import checkIcon from '../../../../img/check.svg';
import printIcon from '../../../../img/print.svg';
import { checkDriverIsActive, OrderTypes } from '../../../../shared/Utils/common';
import AssignDriverDialoge from './AssignDriverDialoge.component';
import customerOrderApi from '../../../../../../core/api/customer-order-api/customer-order.api';
import masterApi from '../../../../../../core/api/master-api/master-api';
import { convertToLast3digit } from '../../../../../../utils/MainUtils';

const ReadyOrderList = ({
    params,
    orderHistory=[],
    filter,
    markCollected,
    fetchOrderByIdAndPrintReceipt,
    routeOrderDetails,
    isDanger,
    fetchOrderHistory,
    print,
    setSelectedOrderDetails,
    
}) => {
    const [isDialogOpen,setDialogOpen] = useState(false);
    const [selectedDriver,setSelectedDriver] = useState(null);
    const [selectedOrderId,setSelectedOrderId] = useState(null);
    const [drivers,setDriverList] = useState([]);

     const fetchDrivers =async()=> {
        const drivers = await masterApi.getDrivers();
        const activeDriverList = drivers.filter(res=>checkDriverIsActive(res));
        setDriverList(activeDriverList);
        
    }

    const openAssignDriverDialog = async(selectedOrderId) => {
        // console.log(selectedOrderId)
        setSelectedOrderId(selectedOrderId);
        await fetchDrivers();
        toggleAssignDriverDialog();
    }

    const toggleAssignDriverDialog = () => {
        setDialogOpen(!isDialogOpen);
    }

    const onDriverChange = (event) => {
        // console.log(event.target.value);
        setSelectedDriver(event.target.value);
    }

    const handleAssignDriver = async () => {
        // console.log(selectedOrderId,selectedDriver)
        const orderRef = await customerOrderApi.assignDriver(selectedOrderId, selectedDriver.driverId);
        // console.log("Order Ref",orderRef)

        setSelectedOrderDetails(orderRef);

        setTimeout(()=>{
            print();
            setTimeout(()=>print(),1000);
            toggleAssignDriverDialog()
            fetchOrderHistory();
        },500)
    }

    

   const renderOrders =()=> {

        return orderHistory.map((i, j) => {
            if(i.status === orderStatus.ReadyOrder && i.orderType === OrderTypes.Delivery)
                return (
                    <tr key={j} className={classnames({
                        [style.danger]: isDanger(i)
                    })}>
                        <td> {convertToLast3digit(i.orderNo) } </td>
                        
                        <td> {time.timestampToDateString(i.orderPlacedOn)}  </td>
                        
                        <td> {i.addressLine1} </td>
                        
                        <td> {i.driverName || '-'} </td>
                        
                        <td className={style.actions}>
                            {
                                    i.driverName ? (
                                        <Button
                                            disabled={i.status !== orderStatus.ReadyOrder}
                                            variant="contained"
                                            onClick={() => markCollected(i.orderId)}
                                            color="primary">
                                            <img src={checkIcon} alt="" />
                                        </Button>
                                    ) : (
                                        <Button
                                            disabled={i.status !== orderStatus.ReadyOrder}
                                            variant="contained"
                                            onClick={() => openAssignDriverDialog(i.orderId)}
                                            color="primary">
                                            <img src={deliveryIcon} alt="" />
                                        </Button>
                                    )
                            }
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => fetchOrderByIdAndPrintReceipt(i.orderId)}>
                                <img src={printIcon} alt="" style={{padding:2}} />
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => routeOrderDetails(i.orderId)}>
                                <img src={chevronRightIcon} alt="" />
                            </Button>

                        </td>
                    </tr>
                )
            else
                return null;
        })
    }
    
    return(
        <>
            <table className={style.deliveryStyle}>
                    <thead>
                        <tr>
                            <th># Order</th>
                            <th> Order Time </th>
                            <th> Address</th>
                
                            <th> Driver Name </th>

                            <th></th>
                        </tr>
                    </thead>

                    <tbody>
                        {renderOrders()}
                    </tbody>
            </table>
            <AssignDriverDialoge
                    isDialogOpen={isDialogOpen}
                    toggleAssignDriverDialog={toggleAssignDriverDialog}
                    handleAssignDriver={handleAssignDriver}
                    selectedDriver={selectedDriver}
                    onDriverChange={(value)=>onDriverChange(value)}
                    drivers={drivers}
            />
        </>
    )

};

export default ReadyOrderList;
