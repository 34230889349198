import React, { Component } from 'react';
import style from './PaymentInformation.module.scss';
import orderApi from '../../core/api/order-api/order-api';
import httpApi from '../../core/api/http-api/http-api';
import { showConfrimBox } from '../../shared/ComfirmAlertBox/ConfirmAlertBox';
import { paymentGateway } from '../../core/contants';

export default class PaylandsPayment extends Component {
    state = {
        paymentUrl:null,
        isLoading:true,
        showBackButton:false,
        orderId:null,
        isMobile:false //used to detect is site working in webview
    }


    componentDidMount() {
        //this.triggerCard();
        let query = this.getQuery();
        const orderId=query.get('orderId')
        const isMobile=query.get('isMobile');
        const token = query.get('token');
        if(token)
            httpApi.setToken(token);

        this.setData(orderId,isMobile);
        window.addEventListener('message', (ev) => this.handler(ev))
    }

    async setData(orderId,isMobile){
        
        const orderCheckoutDetails = await orderApi.retrieveOrderCheckout(orderId);
        if(orderCheckoutDetails.gateway !== paymentGateway.paylands){
            this.props.history.push(`/payments/${orderId}?isMobile=${isMobile}`);
        }
        localStorage.setItem("orderId", JSON.stringify(orderId));
        this.setState({paymentUrl: orderCheckoutDetails.url, isLoading: false});
    }
    getQuery=()=> {
        const {location} = this.props;
        return new URLSearchParams(location.search);
    }

    displayStatusFailedAlert =async ()=>{
        

        showConfrimBox({
            title:'Payment failed!',
            description:'The payment was unsuccessful due to an abnormality. Please try again.',
            onOkClick:()=>{
                this.props.history.push('/my-details');
            },
            onCancelClick: false
        })
    }

    displayErrorAlert = (body='')=>{
        showConfrimBox({
            title:'Payment failed!',
            description:JSON.stringify(body),
            onCancelClick: false,
            onOkClick:()=>{ }
        })
        this.setState({showBackButton:true});
    }

    cancelPayment = (order)=>{
        showConfrimBox({
            title:'Are you sure you want to go back to cart?',
            description:'',
            okButtonText:"YES",
            onOkClick:async ()=>{
                if(this.state.isMobile){
                    this.props.history.push('/cancel-payment');
                }else{
                    await orderApi.cancelOrderPayment(this.state.orderId);
                    this.props.history.push('/order-page-one');
                }
            }
        })
    }
    handler = (ev) => {
        if (typeof ev.data !== 'object') return
        if (!ev.data.type) return
        if (ev.data.type !== 'payment') return
        if (!ev.data.message) return
        window.document.getElementById('paymentGatewayContainer').style.display = 'none';
        const {isMobile} = this.state;
        if(ev.data.message.toLowerCase() === 'success'){
            if(isMobile){
                this.props.history.push('/order-placed/order-success');
            }else{
                this.props.history.push('/order-placed/success');
            }
        }
        else if(ev.data.message.toLowerCase() === 'failed'){
            localStorage.removeItem("orderId");
            this.displayStatusFailedAlert();
        }
    }

    render() {
        const {isLoading, paymentUrl, orderId, isMobile} = this.state;
        return (
            <div style={{ width: '100%' }} className={style.container}>
               
                <div style={{ overflow: 'auto' }} className={style.section}>
                    
                    { paymentUrl &&
                    <div style={{ width:'100%', height:'100%' }} id="paymentGatewayContainer">
                        <iframe id={orderId} src={paymentUrl} style={{ width: '100%', height: '100%', border:'none' }}></iframe>
                    </div>
                    }
                    {
                       isLoading && <div>
                            <h5 style={{textAlign:'center'}}>Loading please wait....</h5>
                       </div>
                       
                    }
                </div>
            </div>
        )
    }
}
