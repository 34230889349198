import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./Home.scss";

import logo from "../../assets/logo.png";
import fork from "../../assets/fork.png";
import devices from "../../assets/devices.png";
import star from "../../assets/star.png";

import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

import authApi from '../../core/api/auth-api/auth-api'
import { isMobile } from "react-device-detect";

export default class Home extends Component {
    skipLogin = async () => {
        await authApi.clearStorage()
        window.location.href = "/order-page-one";
    }

    componentDidMount(){
        if(isMobile){
            this.props.history.push('/login')
        }
        
    }

    render() {
        return (
            <div className="cover">
                <div className="section">
                    <img src={logo} />

                    <div className="title">Order great local food, Gibraltar's best food hub</div>

                    <div className="subtitle">
                    </div>

                    <div className="items">
                        <Grid container alignItems="center" justify="center">
                            <div className="item">
                                <img src={fork} />
                                <div className="tag">GREAT FOOD</div>
                            </div>
                            <Divider orientation="vertical" flexItem />
                            <div className="item">
                                <img src={star} />
                                <div className="tag">SPECIAL OFFERS</div>
                            </div>
                            <Divider orientation="vertical" flexItem />
                            <div className="item">
                                <img src={devices} />
                                <div className="tag">ONLINE ORDERING</div>
                            </div>
                        </Grid>
                    </div>

                    <div className="login">
                        <Link to="/login">
                            <Button
                                variant="contained"
                                color="primary"
                                endIcon={<NavigateNextIcon />}
                            >
                                LOGIN
                            </Button>
                        </Link>
                    </div>

                    <div className="options">
                        <div className="signup">
                            <Grid container alignItems="center" justify="center">
                                <p>DON'T HAVE AN ACCOUNT?</p>
                                <p id="signup-button">
                                    <Link to="/signup">
                                        <u>SIGN UP</u>
                                    </Link>
                                </p>
                            </Grid>
                        </div>
                        <div className="skip" onClick={this.skipLogin}>
                            <Grid container alignItems="center" justify="center">
                                <p>SKIP LOGIN</p>
                                <NavigateNextIcon />
                            </Grid>
                        </div>
                    </div>
                    <Divider variant="middle" />
                    <Link to="/tnc" target="_blank">
                        <div className="terms">
                            <u>TERMS AND CONDITIONS</u>
                        </div>
                    </Link>
                </div>
            </div>
        );
    }
}
