import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import customerOrderApi from '../../../core/api/customer-order-api/customer-order.api';
import authApi from '../../../core/api/auth-api/auth-api';

import { Grid, Paper } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Page from "../shared/Page/Page";
import InputAdornment from '@material-ui/core/InputAdornment';
import AccountCircle from '@material-ui/icons/PhoneAndroid';
import Button from "@material-ui/core/Button";

import style from "./CSTakeOrder.module.scss";
import { encryptStorage } from "../../../core/EncryptStorage";

class CSTakeOrder extends Component {
    state = {
        mobileNumber: '',
        user: null,
        emptyResult: false,
        note: '',
        noteDialogOpen: false,
    }

    componentDidCatch = () => {
        this.isPermited()
    }

    isPermited = async () => {
        const user = await authApi.getCSUser()
        if (user.roleDescription !== "Customer Service") {
            window.location.href = "/customer-support";
        }
    }

    handleChange = (event) => {
        event.persist();

        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    onSearch = async (event) => {
        event.preventDefault();
        const { mobileNumber } = this.state;
        const user = await customerOrderApi.getUserByMobile(mobileNumber);

        if (user && user[0]) {
            this.setState({
                user: user[0]
            })
        } else {
            this.setState({
                user: null,
                emptyResult: true
            })
        }
    }

    onSearchUpdate = async () => {
        const { mobileNumber } = this.state;
        const user = await customerOrderApi.getUserByMobile(mobileNumber);

        if (user && user[0]) {
            this.setState({
                user: user[0]
            })
        } else {
            this.setState({
                user: null,
                emptyResult: true
            })
        }
    }

    handleAddNote = async () => {
        const { note, user } = this.state;
        await customerOrderApi.addNote(user.userId, note);

        this.onSearchUpdate();

        this.handleClose();
    }

    handleOpen = () => {
        const { user } = this.state
        this.setState({
            noteDialogOpen: true,
            note: user.userNote
        })
    }

    handleClose = () => {
        this.setState({
            noteDialogOpen: false,
            note: ''
        })
    }

    handleLoginAsUser = async () => {
        const { user } = this.state;
        await authApi.loginAsUser(user.userId);
        encryptStorage.setItem('cs_guest_user','true');
        window.open('/order-page-one');
    }

    handleContinueAsGuest = async () => {
        await authApi.logout(true);
        encryptStorage.setItem('cs_guest_user','true');
        window.open('/order-page-one');
    }

    render() {
        const { mobileNumber, emptyResult, user, note, noteDialogOpen } = this.state;

        return (
            <Page>
                <div className={style.container}>
                    <div className={style.header}>
                        Take Order
                    </div>

                    <div className={style.body}>
                        <form className={style.takeOrder} onSubmit={this.onSearch}>
                            <div className={style.title}>
                                Enter mobile number
                            </div>
                            <div className={style.searchBar}>
                                <TextField
                                    label="Search By Mobile"
                                    variant="outlined"
                                    value={mobileNumber}
                                    onChange={this.handleChange}
                                    name="mobileNumber"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <AccountCircle />
                                            </InputAdornment>
                                        ),
                                    }}
                                />

                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    onClick={this.onSearch}
                                >
                                    Search
                                </Button>
                            </div>

                            {
                                user ? (
                                    <div className={style.userInfo}>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <th>
                                                        Name
                                                    </th>

                                                    <td>
                                                        {user.firstName} {user.lastName}
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <th>
                                                        Email
                                                    </th>

                                                    <td>
                                                        {user.email}
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <th colSpan={2}> Notes </th>
                                                </tr>

                                                <tr>
                                                    <td colSpan={2}>
                                                        {user.userNote || 'No comments about this user'}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <div className={style.actions}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={this.handleLoginAsUser}
                                            >
                                                Login as User
                                            </Button>

                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={this.handleOpen}
                                            >
                                                {user.userNote ? 'Edit Notes' : 'Add Note'}
                                            </Button>
                                        </div>
                                    </div>
                                ) : (
                                    emptyResult ? (
                                        <div className={style.noSearch}>
                                            User not registered with this phone number

                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={this.handleContinueAsGuest}
                                            >
                                                Continue as Guest
                                            </Button>
                                        </div>
                                    ) : (
                                        <div className={style.noSearch}>
                                            No user selected
                                        </div>
                                    )
                                )
                            }
                        </form>
                    </div>

                    <Dialog open={noteDialogOpen} className={style.dialog}>
                        <DialogContent>
                            <DialogContentText>
                                Enter note about the customer
                            </DialogContentText>

                            <div style={{ width: 500 }}>
                                <TextField
                                    autoFocus
                                    multiline
                                    rows={10}
                                    label="Note"
                                    name="note"
                                    value={note}
                                    fullWidth
                                    onChange={this.handleChange}
                                />
                            </div>
                        </DialogContent>

                        <DialogActions>
                            <Button color="secondary" variant="contained" onClick={this.handleAddNote}>
                                Submit
                            </Button>

                            <Button color="secondary" onClick={this.handleClose}>
                                Cancel
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </Page>
        );
    }
}

export default withRouter(CSTakeOrder);
