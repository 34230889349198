import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./CartItem.scss";
import classnames from "classnames";

import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SimpleButton from "../SimpleButton/SimpleButton";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import IconButton from "@material-ui/core/IconButton";
import Chip from "@material-ui/core/Chip";

import cartApi from "../../core/api/cart-api/cart-api";

export default class CartItem extends Component {
  state = {
    quantity: 0,
    productId: "",
    productSizeVarientId: 0,
    productSizeVarientIngredientId: 0,
    productPreferenceId: 0,
    productPreferenceOptionId: 0,
    unitPrice: 0,
    cartItemId: "",

    ingredients: "",
    preferences: "",
    description: "",
    productSize: "",
  };

  componentWillReceiveProps = (nextProps) => {
    this.setState({
      quantity: nextProps.quantity,
      productId: nextProps.productId,
      productSizeVarientId: nextProps.productSizeVarientId,
      productSizeVarientIngredientId: nextProps.productSizeVarientIngredientId,
      productPreferenceId: nextProps.productPreferenceId,
      productPreferenceOptionId: nextProps.productPreferenceOptionId,
      unitPrice: nextProps.unitPrice,
      cartItemId: nextProps.cartItemId,

      ingredients: nextProps.itemProductIngredients,
      preferences: nextProps.productPreferenceOptions,
      description: nextProps.productDescription,
      productSize: nextProps.productSizeVarientName,
    });
  };

  componentDidMount = () => {
    this.setState({
      quantity: this.props.quantity,
      productId: this.props.productId,
      productSizeVarientId: this.props.productSizeVarientId,
      productSizeVarientIngredientId: this.props.productSizeVarientIngredientId,
      productPreferenceId: this.props.productPreferenceId,
      productPreferenceOptionId: this.props.productPreferenceOptionId,
      unitPrice: this.props.unitPrice,
      cartItemId: this.props.cartItemId,

      ingredients: this.props.itemProductIngredients,
      preferences: this.props.productPreferenceOptions,
      description: this.props.productDescription,
      productSize: this.props.productSizeVarientName,
    });
  };

  handleAddClick = () => {
    let i = this.props.indexValue;
    this.props.handleAddClick(i);
  };

  handleMinusClick = () => {
    let i = this.props.indexValue;
    this.props.handleMinusClick(i);
  };

  removeCartItem = () => {
    let i = this.props.indexValue;
    this.props.removeCartItem(i);
  };

  renderIngredients() {
    const { ingredients } = this.state;

    return ingredients.map((i, j) => {
      return <Chip key={j} label={i.ingredient} size="small" />;
    });
  }

  renderPreferences() {
    const { preferences } = this.state;

    return preferences.map((i, j) => {
      return (
        <div key={j}>
          {i.productPreference} : {i.productPreferenceOption}
        </div>
      );
    });
  }

  render() {
    const { ingredients, preferences, description, productSize } = this.state;
    const { outOfStock } = this.props;
    return (
      <div>
        <div className="sideMenuItem">
          <Paper>
            <div className="item-name-row">
              <div className="item-name">{this.props.productName}</div>
              <div className="remove">
                <IconButton onClick={this.removeCartItem}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              </div>
            </div>
            {!!description ? (
              <div className="subtitle">{description}</div>
            ) : (
              <div></div>
            )}

            {!!productSize ? (
              <div className={classnames("product-size", "subtitle")}>
                Size : {productSize}
              </div>
            ) : (
              <div></div>
            )}

            {!!preferences.length ? (
              <div className={classnames("preferences", "subtitle")}>
                {this.renderPreferences()}
              </div>
            ) : (
              <div></div>
            )}

            {!!ingredients.length ? (
              <div className={classnames("ingredients", "subtitle")}>
                <div>Ingredients :</div>
                <div className="items">{this.renderIngredients()}</div>
              </div>
            ) : (
              <div></div>
            )}
            <div className="item-details">
              <div className="count">
                <TextField
                  variant="outlined"
                  size="small"
                  value={this.props.quantity}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton onClick={this.handleMinusClick}>
                          <RemoveIcon fontSize="small" />
                        </IconButton>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={this.handleAddClick}>
                          <AddIcon fontSize="small" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className="price">
                £{(this.props.unitPrice * this.props.quantity).toFixed(2)}
                {
                  outOfStock? (
                    <SimpleButton text="OUT OF STOCK" disabled={true} />
                  ) : (
                    <Link to={`/edit-order-item/${this.props.productId}`}>
                      <SimpleButton text="EDIT" />
                    </Link>
                  )
                }
              </div>
            </div>
          </Paper>
        </div>
      </div>
    );
  }
}
