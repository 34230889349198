import React, { Component } from "react";
import style from "./OrderSummary.module.scss";
import classnames from "classnames";
import Constants, { orderStatus } from "../../../../../core/contants";

import customerOrderApi from "../../../../../core/api/customer-order-api/customer-order.api";
import { OrderTypes } from "../../../shared/Utils/common";
import { CustomerMessages } from "../../../../../core/api";
import { connect } from "react-redux";
import { CommonActions } from "../../../../../core/constants/actions";
import { withRouter } from "react-router-dom";

 class OrderSummary extends Component {
  state = {
    stateCount: { all: 0, new: 0, ack: 0, cook: 0, ready: 0,readyCollection:0, out: 0 ,pending:0},
  };
  onFilterChange = (selectedFilter) => {
    const { onChange } = this.props;

    if (onChange) {
      onChange(selectedFilter);
      (selectedFilter === Constants.customerIssue)?this.setCustomerIssueCount():this.fetchOrderHistory();
    }
  };

  componentDidMount = () => {
    // this.fetchOrderHistory();
    this.fetchOrderHistory();
    this.setCustomerIssueCount();
    this.props.provideCtrl({
      fetchOrderHistory: this.fetchOrderHistory
    });
  };

  setCustomerIssueCount = async () =>{
    const res = await CustomerMessages.getNotResolveCount();
    this.props.updateNotResolvedMessageCount(res['count'])
  }

  fetchOrderHistory = async () => {
    // console.log("fetch summary count");
    try {
      const res = await Promise.all([
          customerOrderApi.getOrderHistory(orderStatus.NewOrder),
          customerOrderApi.getOrderHistory(orderStatus.AcknowledgedOrder),
          customerOrderApi.getOrderHistory(orderStatus.CookingOrder),
          customerOrderApi.getOrderHistory(orderStatus.ReadyOrder),
          customerOrderApi.getOrderHistory(orderStatus.CollectedOrder),
          customerOrderApi.getOrderHistory(orderStatus.PendingOrder)
      ]);
      // console.log(res)
      let count = { all: 0, new: 0, ack: 0, cook: 0, ready: 0,readyCollection: 0, out: 0 ,pending:0 };

      count.new = res[0].length;
      count.ack = res[1].length;
      count.cook = res[2].length;
      count.out = res[4].length;
      count.pending = res[5].length;

      res[3].forEach((i) => {
        let status = i.status;
  
        //TODO : Counting only Delivery Ready orders
        if (status === Constants.ReadyOrder && i.orderType === OrderTypes.Delivery) {
          count.ready = count.ready + 1;
        }
  
        //TODO : Counting only Collection Ready orders
        if (status === Constants.ReadyOrder && i.orderType === OrderTypes.Collection) {
          count.readyCollection = count.readyCollection + 1;
        }
   
      });
      this.setState({
        stateCount: count,
      });

    } catch {
      throw Error("Promise failed");
    }
  };

 
  render() {
    const { filter,csNotResolvedIssueCount } = this.props;
    const { stateCount} = this.state;
    return (
      <div className={style.orderSummary}>
        {/* <div
          onClick={() => this.onFilterChange(null)}
          className={classnames(style.card, {
            [style.active]: filter === null,
          })}
        >
          <div className={style.value}>{stateCount.all}</div>

          <div className={style.label}>All Orders</div>
        </div> */}

        <div
          onClick={() => this.onFilterChange(Constants.NewOrder)}
          className={classnames(style.card, {
            [style.active]: filter === Constants.NewOrder,
          })}
        >
          <div className={style.value}>{stateCount.new}</div>

          <div className={style.label}>New Orders</div>
        </div>

        <div
          onClick={() => this.onFilterChange(Constants.AcknowledgedOrder)}
          className={classnames(style.card, {
            [style.active]: filter === Constants.AcknowledgedOrder,
          })}
        >
          <div className={style.value}>{stateCount.ack}</div>

          <div className={style.label}>Acknowledged</div>
        </div>

        <div
          onClick={() => this.onFilterChange(Constants.CookingOrder)}
          className={classnames(style.card, {
            [style.active]: filter === Constants.CookingOrder,
          })}
        >
          <div className={style.value}>{stateCount.cook}</div>

          <div className={style.label}>Cooking Orders</div>
        </div>

        <div
          onClick={() => this.onFilterChange(Constants.ReadyOrder)}
          className={classnames(style.card, {
            [style.active]: filter === Constants.ReadyOrder,
          })}
        >
          <div className={style.value}>{stateCount.ready}</div>

          <div className={style.label}>Delivery Ready</div>
        </div>

        <div
          onClick={() => this.onFilterChange(Constants.ReadyCollectionOrder)}
          className={classnames(style.card, {
            [style.active]: filter === Constants.ReadyCollectionOrder,
          })}
        >
          <div className={style.value}>{stateCount.readyCollection}</div>

          <div className={style.label}>Collections Ready</div>
        </div>

        <div
          onClick={() => this.onFilterChange(Constants.CollectedOrder)}
          className={classnames(style.card, {
            [style.active]: filter === Constants.CollectedOrder,
          })}
        >
          <div className={style.value}>{stateCount.out}</div>

          <div className={style.label}>Completed</div>
        </div>

        <div
          onClick={() => this.onFilterChange(Constants.customerIssue)}
          className={classnames(style.card, {
            [style.active]: filter === Constants.customerIssue,
          })}
        >
          <div className={style.value}>{csNotResolvedIssueCount}</div>

          <div className={style.label}>Customer Issue</div>
        </div>

        <div
          onClick={() => this.onFilterChange(orderStatus.PendingOrder)}
          className={classnames(style.card, {
            [style.active]: filter === orderStatus.PendingOrder,
          })}
        >
          <div className={style.value}>{stateCount.pending}</div>

          <div className={style.label}>Pending Payment</div>
        </div>
      </div>
    );
  }
}


const mapStateToProps = (state)=>{
  const {csNotResolvedIssueCount} = state.commonReducer;
  return {
    csNotResolvedIssueCount
  }
}

const mapDispatchToProps = (dispatch) =>{
  return {
    updateNotResolvedMessageCount:(count)=>{
      dispatch({
        type:CommonActions.UPDTE_NOT_RESOLVED_COUNT,
        payload:{
          count
        }
      })
    }
  }
}

export default connect(mapStateToProps,mapDispatchToProps,null,{forwardRef:true})(withRouter(OrderSummary));