import React, { Component } from "react";

import "./KitchenOrderItem.scss";

import Divider from '@material-ui/core/Divider';
import Button from "@material-ui/core/Button";
import Chip from '@material-ui/core/Chip';

import restaurantApi from "../../../core/api/restaurant-api/restaurant-api"
import BackButton from "../../../shared/BackButton/BackButton"

export default class KitchenOrderItem extends Component {
  state = {
    currentStateId: 0,
    currentState: ["NEW", "ACK", "PREP", "READY", "Completed"],
    nextState: ["ACKNOWLEDGE ORDER", "START PREP", "Ready", "Completed"],
    orderHistory: [],
    restaurant: '',
    orderNumber: '',
    orderItems: [],
    timeOrderPlaced: '',
    timeSincePlaced: "",
    currentTime: '',
    startPrepTime: '',
    timer: null
  };

  constructor() {
    super();

    if (!restaurantApi.isLoggedIn) {
      window.location.href = '/kitchen/';
    }
  }

  componentDidMount = () => {
    this.getOrderHistory()
    this.updateTime()
  }

  componentWillUnmount() {
    clearInterval(this.state.timer)
  }

  getOrderHistory = async () => {
    const orderId = await this.props.match.params.orderId

    let orderHistory = await restaurantApi.getOrderHistoryById(orderId);
    let timeOrderPlaced = this.formatReceived(orderHistory[0].timeSincePlaced)
    let orderItems = await restaurantApi.getRestaurantOrderDetails(orderId);
    let startPrep = this.formatPrepTime(orderHistory.timeSincePlaced, orderHistory[0].orderETATime)

    this.setState({
      orderHistory: orderHistory[0],
      restaurant: orderHistory[0].restaurant,
      orderNumber: orderHistory[0].orderNumber,
      orderItems: orderItems,
      currentStateId: orderHistory[0].orderStatus,
      timeOrderPlaced: timeOrderPlaced,
      startPrepTime: startPrep
    })
  }

  updateOderState = async () => {
    const { orderItems, currentStateId } = this.state
    if (currentStateId < 3) {
      let newStateId = currentStateId + 1
      await restaurantApi.updateOrderState(orderItems[0].restaurantOrderId, newStateId)
      this.getOrderHistory()
    }
  }

  handleStateClick = () => {
    this.updateOderState()
  }

  setOrderItemSummery = (item) => {
    let qty = item.quantity
    qty = qty.toString()
    let itemText = item.productName + " x " + qty

    return itemText
  }

  updateTime = () => {
    let timer = setInterval(this.getTime, 1000);
    this.setState({
      timer: timer
    })
  }

  getTime = () => {
    const { orderHistory } = this.state
    let d = new Date()
    let currentTime = ''
    let h = d.getHours();
    let m = d.getMinutes();
    let s = d.getSeconds();
    currentTime = this.formatTime(h, m, s)

    this.getTimeSinceRecieved(orderHistory.timeSincePlaced)
    this.setState({
      currentTime: currentTime
    })

  }

  getTimeSinceRecieved = (t) => {
    let rec = new Date(Date.parse(t))
    let d = new Date()
    var difference = new Date(d - rec);
    var diff_hours = difference.getHours();
    var diff_mins = difference.getMinutes();
    var diff_secs = difference.getSeconds();
    let diff = this.formatTime(diff_hours, diff_mins, diff_secs)

    if (diff.slice(0, 3) === "00:") {
      diff = diff.substring(3)
    }

    this.setState({
      timeSincePlaced: diff
    })
  }

  formatTime = (h, m, s) => {
    let hh = String(h)
    let mm = String(m)
    let ss = String(s)

    if (hh[1] === undefined) {
      hh = "0" + hh
    }

    if (mm[1] === undefined) {
      mm = "0" + mm
    } if (mm[1] === undefined) {
      mm = "0" + mm
    }

    if (ss[1] === undefined) {
      ss = "0" + ss
    }

    let res = hh + ":" + mm + ":" + ss
    return res
  }

  formatReceived = (t) => {

    let rec = new Date(Date.parse(t))
    let recTime = ''
    let h = rec.getHours();
    let m = rec.getMinutes();
    let s = rec.getSeconds();

    recTime = this.formatTime(h, m, s)
    return recTime
  }
  formatPrepTime = (start, mm) => {
    let rec = new Date(Date.parse(start))
    rec.setMinutes(rec.getMinutes() + mm)
    let ss = (mm - Math.floor(mm)) * 60
    rec.setSeconds(rec.getSeconds() + ss)
    let recTime = ''
    let h = rec.getHours();
    let m = rec.getMinutes();
    let s = rec.getSeconds();

    recTime = this.formatTime(h, m, s)
    return recTime
  }

  render() {
    const { currentState, nextState, currentStateId, restaurant, orderNumber, orderItems, currentTime } = this.state
    const { timeOrderPlaced, timeSincePlaced, startPrepTime } = this.state
    return (
      <div className="kitchen-order-item-container">
        <div className="section">
          <div className="heading">
            <div className="kitchen-name">
              {restaurant}
            </div>
            <div className="order-no">
              #{orderNumber}
            </div>
            <div className="time">
              {currentTime}
            </div>
          </div>
          <Divider variant="fullWidth" />
          <div className="middle-bar">
            <div className="title">
              ORDER DETAILS
            </div>
            <div className="add-time-container">
              <div className="add-time-title">
                ADD TIME TO ORDER
              </div>
              <div className="add-time-button-list">
                <Button
                  variant="contained"
                  color="primary"
                >
                  5
              </Button>
                <Button
                  variant="contained"
                  color="primary"
                >
                  10
              </Button>
                <Button
                  variant="contained"
                  color="primary"
                >
                  15
              </Button>
                <Button
                  variant="contained"
                  color="primary"
                >
                  20
              </Button>
                <Button
                  variant="contained"
                  color="primary"
                >
                  30
              </Button>
                <Button
                  variant="contained"
                  color="primary"
                >
                  40
              </Button>
                <Button
                  variant="contained"
                  color="primary"
                >
                  50
              </Button>
                <Button
                  variant="contained"
                  color="primary"
                >
                  60
              </Button>
              </div>
            </div>
          </div>
          <div className="order-body">
            <div className="summery">
              <div className="times">
                <div className="name">
                  Order Received
                </div>
                <div className="time">
                  {timeOrderPlaced}
                </div>
              </div>

              <div className="times">
                <div className="name">
                  Start Preparation
                </div>
                <div className="time">
                  {startPrepTime}
                </div>
              </div>

              <div className="times">
                <div className="name">
                  Order Due
                </div>
                <div className="time">
                  18:43:12
                </div>
              </div>

              <div className="times">
                <div className="name">
                  Order ETA
                </div>
                <div className="time">
                  18:43:12
                </div>
              </div>

              <div className="times">
                <div className="name">
                  Order Status
                </div>
                <div className="current-state">
                  <div className={currentState[currentStateId].toLowerCase()}>
                    {currentState[currentStateId]}
                  </div>
                </div>
              </div>
              <div className="action">
                <div className={currentState[currentStateId + 1].toLowerCase()}>
                  <Button
                    onClick={this.handleStateClick}
                    variant="contained"
                  >
                    {nextState[currentStateId]}
                  </Button>
                </div>

              </div>
              <div className="count">
                <div>
                  Mins Since Order Recieved
                </div>
                <div className="count-time">
                  {timeSincePlaced}
                </div>
              </div>
            </div>
            <div className="details">
              {orderItems.map((item, i) => (
                <div key={i}>
                  <div className="single-item">
                    <Chip label={this.setOrderItemSummery(item)} />
                  </div>
                  <Divider variant="fullWidth" />
                </div>
              ))}
            </div>
          </div>
          <div className="back">
            <BackButton text="BACK TO KITCHEN" link="/kitchen/order-list" />
          </div>

        </div>
      </div>
    );
  }
}
