import React, { Component } from 'react';
import style from './Page.module.scss';

import Sidebar from '../Sidebar/Sidebar';
import authApi from '../../../../core/api/auth-api/auth-api';
import { CS_Roles } from '../Utils/common';

export default class Page extends Component {
    state={
        user:null
    }
    componentDidMount(){
        this.setUserInfo();
    }
    setUserInfo = async () => {
        const user = await authApi.getCSUser();
        this.setState({user})
    }
    render() {
        const {user} = this.state;
        return (
            <div className={style.page}>
                <Sidebar
                    isMainUser = {user && user.roleDescription === CS_Roles[0]}
                />

                <div className={style.pageContent}>
                    {this.props.children}
                </div>
            </div>
        )
    }
}
