import React, { Component } from 'react';
import style from './ConfirmDialog.module.scss';

import Dialog from '../Dialog/Dialog';
import Button from "../SimpleButton/SimpleButton";

export default class ConfirmDialog extends Component {
    handleConfirm = () => {
        const { onClose } = this.props;
        onClose(true);
    }

    handleCancel = () => {
        const { onClose } = this.props;
        onClose(false);
    }

    render() {
        const { isOpen } = this.props;

        return (
            <Dialog isOpen={isOpen} onClose={this.handleCancel}>
                <div className={style.container}>
                    <div>
                        <div className={style.title}>
                            <i className="feather-info-circle"> </i> Confirmation
                        </div>

                        <div className={style.message}>
                            {this.props.children}
                        </div>

                        <div className={style.actions}>
                            <Button
                                className={style.deleteButton}
                                size="small"
                                variant="contained"
                                color="primary"
                                type="button"
                                onFunction={this.handleConfirm}
                                text="Confirm"
                            >
                            </Button>

                            <Button
                                className={style.deleteButton}
                                size="small"
                                variant="outlined"
                                color="primary"
                                type="button"
                                onFunction={this.handleCancel}
                                text="Cancel"
                            >
                            </Button>
                        </div>
                    </div>
                </div>
            </Dialog>
        )
    }
}
