import { CommonActions } from "../constants/actions";

const defaultState = {
    notReadMessageCount:0,
    csNotResolvedIssueCount:0
};

const common = (state = defaultState, action) => {
  if (action && action.payload) {
    switch (action.type) {
      case CommonActions.UPDTE_READ_COUNT:
        const {count} = action.payload
          return {
            ...state,
            notReadMessageCount:count
          }
      case CommonActions.UPDTE_NOT_RESOLVED_COUNT:
          return {
            ...state,
            csNotResolvedIssueCount:action.payload.count
          }
          
      default:
        return { ...state };
    }
  }

  return state;
};

export default common;
