import React, { Component} from "react";
import style from "./Sidebar.module.scss";
import { NavLink as Link } from "react-router-dom";

import logo from "./img/logo.png";

import dashboardIcon from "../../img/dashboard.svg";
import listIcon from "../../img/list.svg";
import logoutIcon from "../../img/logout.svg";
import phoneIcon from "../../img/phone.svg";
import authApi from "../../../../core/api/auth-api/auth-api";
import * as SC from './components'


export default class Sidebar extends Component {
  handleLogout = () => {
    authApi.csLogout();
  };
  
  render() {
    const {isMainUser} = this.props;
    return (
      <div className={style.container}>
        <div className={style.header}>
          <img src={logo} alt="" />
        </div>

        <div className={style.navigation}>
          <div>
            <Link exact to="/customer-support/dashboard" activeClassName={style.active}>
              <div className={style.navItem}>
                <img src={dashboardIcon} alt="" /> Dashboard
              </div>
            </Link>
            { isMainUser &&
              <div>
                <Link
                  exact
                  to="/customer-support/order-history"
                  activeClassName={style.active}
                >
                  <div className={style.navItem}>
                    <img src={listIcon} alt="" /> Order History
                  </div>
                </Link>

                <Link
                  exact
                  to="/customer-support/take-order"
                  activeClassName={style.active}
                >
                  <div className={style.navItem}>
                    <img src={phoneIcon} alt="" /> Take Order
                  </div>
                </Link>
             
                <SC.ExportOrders />
                <SC.FinanceReport />
                <SC.DriverReport />
                <SC.DriverCheckIn />
                <SC.KitchenStatus />
                <Link
                  exact
                  to="/customer-support/promotions"
                  activeClassName={style.active}
                >
                  <div className={style.navItem}>
                    <img src={phoneIcon} alt="" /> Promotions
                  </div>
                </Link>
              </div>
            }
          </div>

          <div>
            <div className={style.navItem} onClick={this.handleLogout}>
              <img src={logoutIcon} alt="" /> Logout
            </div>
          </div>
        </div>
      </div>
    );
  }
}
