import React, { Component } from "react";

import "./AddOrderItem.scss";
import OrderTopBar from "../../shared/OrderTopBar/OrderTopBar";
import Backbutton from "../../shared/BackButton/BackButton";
import SimpleButton from "../../shared/SimpleButton/SimpleButton";

import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";

export default class AddOrderItem extends Component {
  render() {
    return (
      <div className="add-order-item-container">
        <OrderTopBar />
        <div className="section">
          <Paper className="paper">
            <Backbutton text="BACK TO ORDERS" />
            <div className="title">Add Item</div>
            <div className="form-section">
              <div className="row">
                <div className="left">PRODUCT CODE</div>
                <div className="right">
                  <TextField variant="outlined" fullWidth />
                </div>
              </div>
              <div className="row">
                <div className="left">DESCRIPTION</div>
                <div className="right">
                  <TextField
                    className="multiline-textbox"
                    multiline
                    rows={5}
                    variant="outlined"
                    fullWidth
                  />
                </div>
              </div>
              <div className="row">
                <div className="left">PRICE</div>
                <div className="right">
                  <TextField variant="outlined" fullWidth />
                </div>
              </div>
              <div className="row">
                <div className="left">Tags</div>
                <div className="right">
                  <TextField
                    className="multiline-textbox"
                    variant="outlined"
                    fullWidth
                  />
                </div>
              </div>
              <div className="row">
                <div className="left"></div>
                <div className="right">
                  <div className="action">
                    <SimpleButton text="ADD ITEM" />
                    <div className="cancel">
                      <u>Cancel</u>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Paper>
        </div>
      </div>
    );
  }
}
