import { Button } from '@material-ui/core';
import React from 'react';
import time from '../../../../../../core/lib/time';
import style from '../OrderList.module.scss';
import chevronRightIcon from '../../../../img/chevron-right.svg';
import classnames from 'classnames';
import panIcon from '../../../../img/pan.svg';
import { orderStatus } from '../../../../../../core/contants';
import { convertToLast3digit } from '../../../../../../utils/MainUtils';

const NewOrderList = ({
    orderHistory=[],
    filter,
    markAcknowledge,
    routeOrderDetails,
    isDanger
}) => {
   const renderOrders =()=> {
        return orderHistory.map((i, j) => {
            if(i.status === 5)
                return null;
            return (
                <tr key={j} className={classnames({
                    [style.danger]: isDanger(i)
                })}>
                    <td> {convertToLast3digit(i.orderNo)} </td>
                    
                    <td> {time.timestampToDateString(i.orderPlacedOn)}  </td>

                    <td> {i.customerName} </td>
                    
                    <td> {i.orderCost.toFixed(2)} </td>
                    
                    <td> {i.deliveryZone} </td>
                    
                    <td> {i.driverName || '-'} </td>

                    <td> {time.timestampToDateString(i.requestedTime)} </td>

                    <td> {time.timestampToDateString(i.deliveredTime)} </td>
               
                    <td className={style.actions}>
                        {
                            i.status === orderStatus.NewOrder &&
                                (<Button
                                    variant="contained"
                                    onClick={()=>markAcknowledge(i.orderId)}
                                    color="primary">
                                    <img src={panIcon} alt="" />
                                </Button>)
                   
                        }
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => routeOrderDetails(i.orderId)}>
                            <img src={chevronRightIcon} alt="" />
                        </Button>

                    </td>
                </tr>
            )
        })
    }
    
    return(
        <table className={filter === 3? style.deliveryStyle:{}}>
                <thead>
                    <tr>
                        <th># Order</th>
                        <th> Order Time </th>
                        <th> Customer</th>
                        <th> Cost </th>
                        <th> Delivery Zone </th>
                        <th> Driver Name </th>
                        <th> Requested Time </th>
                        <th> Delivery Time </th>
                        <th></th>
                    </tr>
                </thead>

                <tbody>
                    {renderOrders()}
                </tbody>
            </table>
    )

};

export default NewOrderList;
