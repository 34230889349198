import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import './Epos.scss'

const FinanceReportPrint = forwardRef((props,ref) => {
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
    });
  
    useImperativeHandle(ref, () => ({

        Print() {
            setTimeout(()=>handlePrint(),500)
        }
    
      }));

    return (
      <div>
           <div style={{ display: "none" }}>
            <ComponentToPrint ref={componentRef} data={props.data}  />
           </div>
        {/* <button onClick={handlePrint}>Print this out!</button> */}
      </div>
    );
  });

export default FinanceReportPrint

export class ComponentToPrint extends React.PureComponent {
    render() {
        const {data} = this.props;
        // console.log("?????",data)
        if(!data){
            return <div></div>
        }

        const deliveryCost = () =>(
            <>
                <tr>
                    <th colSpan="2">Deliveries</th>
                </tr>
                <tr>
                    <td>
                        Total number of deliveries
                    </td>
                    <td>
                        {data.totalDeliveryCount}
                    </td>
                </tr>
                <tr>
                    <td>
                        Total delivery charge cash
                    </td>
                    <td>
                        £{Number(data.totalDeliveryCostCash).toFixed(2)}
                    </td>
                </tr>
                <tr>
                    <td>
                        Total delivery charge card
                    </td>
                    <td>
                        £{Number(data.totalDeliveryCostCard).toFixed(2)}
                    </td>
                </tr>
            </>

            )

        const extraFinanceReport = () =>(
            <>
                {(data.restaurantId === 1) && deliveryCost()}
                <tr>
                    <th colSpan="2">Payment Types</th>
                </tr>
                <tr>
                    <td>
                        Total cash sales
                    </td>
                    <td>
                        £{Number(data.cashSales+data.totalDeliveryCostCash).toFixed(2)}
                    </td>
                </tr>
                <tr>
                    <td>
                        Total card sales 
                    </td>
                    <td>
                        £{Number(data.cardSales+data.totalDeliveryCostCard).toFixed(2)}
                    </td>
                </tr>
                <tr>
                    <td colSpan="2"></td>
                </tr>
                <tr>
                    <th colSpan="2">Order Options</th>
                </tr>
                <tr>
                    <td>
                        Total pickup sales 
                    </td>
                    <td>
                        £{Number(data.pickupSales).toFixed(2)}
                    </td>
                </tr>
                <tr>
                    <td>
                        Total delivery sales 
                    </td>
                    <td>
                        £{Number(data.deliveredSales).toFixed(2)}
                    </td>
                </tr>
                <tr>
                    <td colSpan="2"></td>
                </tr>
            </>
        )

        const cancelledOrderReports = () =>(
            <>
                <tr>
                    <td colSpan="2"></td>
                </tr>
                { 
                    data.cancelledOrderDtoList.length > 0 &&
                    <tr>
                        <th colSpan="2">Cancelled Orders</th>
                    </tr>
                }
                {
                    data.cancelledOrderDtoList.map((res,i)=>(
                        <>
                        <tr>
                            <td>
                                #{res.cancelledOrderNumber}
                            </td>
                            <td>
                                £{Number(res.cancellAmount).toFixed(2)}
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="2">
                                    Reason: {res.cancelledOrderReason}
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="2"><hr /></td>
                        </tr>
                    </>
                    ))
                }
            </>
        )

        const POSReport = () =>(
            <>
                <tr>
                    <th colSpan="2">POS Sales</th>
                </tr>
                <tr>
                    <td>
                        Total cash sales
                    </td>
                    <td>
                    £{data.posReport?Number(data.posReport.cashAmount).toFixed(2):''}
                    </td>
                </tr>
                <tr>
                    <td>
                        Total card sales 
                    </td>
                    <td>
                        £{data.posReport?Number(data.posReport.cardAmount).toFixed(2):''}
                    </td>
                </tr>
            </>
        )


        const printHtml =()=>(
            <div style={{
                        padding:'2%',
                        display:'flex',
                        justifyContent:'center',
                        flexDirection:'column',
                        paddingLeft:'7%'
                    }}>
                <h2 style={{margin:0}}>Sales Report for {data.restaurantName} </h2>
                {/* <h3 style={{margin:0}}>From  {data.fromDateTime} </h3>
                <h3 style={{margin:0}}>To {data.toDateTime}</h3> */}
                <table>
                    <tr>
                        <th>
                            From
                        </th>
                        <th>
                            {data.fromDateTime}
                        </th>
                    </tr>
                    <tr>
                        <th>
                            To
                        </th>
                        <th>
                            {data.toDateTime}
                        </th>
                    </tr>
                </table>
                <table style={{marginTop:7}}>
                    <tbody>
                        {!data.kitchen && extraFinanceReport()}

                        {POSReport()}
                       
                        {!data.kitchen && cancelledOrderReports()}
                    </tbody>
                </table>
            </div>
        );
 
            return printHtml()

    }
  }

 