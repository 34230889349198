import React, { Component } from "react";
import { connect } from "react-redux";
import "./OrderDetails.scss";

import cartApi from '../../core/api/cart-api/cart-api';

import TopBar from "../../shared/TopBar/TopBar";

import Chip from "@material-ui/core/Chip";
import Button from "@material-ui/core/Button";

import BackButton from "../../shared/BackButton/BackButton";
import { LinearProgress } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import orderApi from "../../core/api/order-api/order-api";
import RaiseIssue from "./component/RaiseIssue";
import { CustomerMessages } from "../../core/api";

class OrderList extends Component {
    state = {
        orderItems: [],
        totalPrice: 0,
        deliveryCharge: 0,
        userFoodHubPoint: 0,
        isCartEmpty: false,
        isDialogOpen: false,
        isReorderDisable: true,
        issueTypes:[],
        issueModalOpen:false
    };

    componentDidMount() {
        this.fetchOrderDetails();
        this.isCartEmptyChecker();
        this.getIssueTypes();
    }

    async getIssueTypes(){
        const res = await CustomerMessages.getIssueTypes();
        if(res && res.length){
            this.setState({issueTypes : res.map((v)=>({value:v['id'],label:v['type']}))})
        }
    }



    async fetchOrderDetails() {
        const orderItems = await orderApi.getOrderDetails(
            this.props.match.params.orderId
        );
        console.log(orderItems)

        this.setState({
            orderItems: orderItems.cartItems,
            totalPrice: orderItems.totalPrice,
            deliveryCharge: orderItems.deliveryCharge,
            userFoodHubPoint: orderItems.userFoodHubPoint
        }, () => {
            this.setReorderEnable()
        });
    }

    setReorderEnable() {
        const { orderItems } = this.state
        for (let orderItem of orderItems) {
            const { restaurantOrderStatus } = orderItem;
            if (restaurantOrderStatus !== 3) {
                this.setState({
                    isReorderDisable: true
                })
                break;
            }
            else {
                this.setState({
                    isReorderDisable: false
                })
            }
        }
    }

    getTotalPrice() {
        let price = 0;
        const { orderItems, totalPrice } = this.state;

        if (orderItems) {
            price = totalPrice
        }

        return price.toFixed(2);
    }

    getDeliveryCharge() {
        let price = 0;
        const { orderItems, deliveryCharge } = this.state;

        if (orderItems) {
            price = deliveryCharge
        }

        return price.toFixed(2);
    }

    getUsedPoints() {
        let price = 0;
        const { orderItems, userFoodHubPoint } = this.state;

        if (orderItems) {
            price = userFoodHubPoint / 100
        }

        return price.toFixed(2);
    }

    isCartEmptyChecker = () => {
        if (this.props.cart.length === 0) {
            this.setState({
                isCartEmpty: true,
            });
        } else {
            this.setState({
                isCartEmpty: false,
            });
        }
    };

    handleOpen = () => {
        this.setState({
            isDialogOpen: true
        })
    }

    handleClose = () => {
        this.setState({
            isDialogOpen: false
        })
    }

    clearCart = async () => {
        const { cart } = this.props;
        if (cart.length !== 0) {
            const res = await cartApi.clearCart(cart[0].cartID);
        }

    };

    handleReorder = async () => {
        this.clearCart()
        const orderItems = await orderApi.reOrderById(
            this.props.match.params.orderId
        );

        this.handleClose()
        window.location.href = '/order-page-one'
    }

    renderIngredients(ingredients) {
        return ingredients.map((i, j) => {
            return (
                <div key={j} className="chip">
                    <Chip key={j} label={i.ingredient} size="small" />
                </div>
            )
        });
    }

    renderPreferences(preferences) {
        return preferences.map((i, j) => {
            return (
                <div key={j} className="label">
                    {i.productPreference}: {i.productPreferenceOption}
                </div>
            )
        });
    }

    renderOrderHistory() {
        const { orderItems } = this.state;

        return orderItems.map((x, y) => {
            return (
                <div key={y} className="orderItem">
                    <div
                        className="image"
                        style={{
                            backgroundImage: `url(${x.thumbnailUrl})`,
                        }}
                    ></div>

                    <div className="details">
                        <div className="label">{x.productName}</div>
                        <div className="label">Size : {x.productSizeVarientName}</div>
                        {this.renderPreferences(x.productPreferenceOptions)}

                        {!!x.itemProductIngredients.length ? (
                            <div className="ingredients label">
                                <div>Ingredients :</div>
                                <div className="items">{this.renderIngredients(x.itemProductIngredients)}</div>
                            </div>
                        ) : (
                            <div></div>
                        )}

                        <div className="subLabel">
                            {x.unitPrice.toFixed(2)} x {x.quantity}
                        </div>
                    </div>

                    <div className="price">
                        <div>{x.totalPrice.toFixed(2)}</div>
                    </div>
                </div>
            );
        });
    }

    toggleIssueModal = () =>{
        this.setState({issueModalOpen:!this.state.issueModalOpen})
    }

    render() {
        const { isLoading, isCartEmpty, isDialogOpen, isReorderDisable,issueTypes,issueModalOpen } = this.state;
        const {orderId} = this.props.match.params;
        return (
            <div className="order-details-container">
                <div className="top-bar">
                    <TopBar />
                </div>
                <RaiseIssue 
                    open ={issueModalOpen}
                    issueTypes={issueTypes}
                    orderId={orderId}
                    close={this.toggleIssueModal}
                />
                {isLoading ? (
                    <LinearProgress />
                ) : (
                    <div className="section">
                        <div className="back-button">
                            <BackButton text="BACK" link="/order-list" />
                            <Button   
                                variant="contained"
                                color="primary"
                                onClick={this.toggleIssueModal}
                            >
                                    Raise Issue
                            </Button>
                        </div>

                        <div className="header">
                            <div>
                                Order Summary
                            </div>


                            {/* <Button
                                variant="contained"
                                color="primary"
                                disabled={isReorderDisable}
                                onClick={isCartEmpty ? this.handleReorder : this.handleOpen}
                            >
                                Order Again
                            </Button> */}
                        </div>


                        <div className="cart">
                            <div className="orderItems">
                                {this.renderOrderHistory()}
                            </div>

                            <div className="footer">
                                <div className="footerItem">
                                    <div>
                                        Delivery Charge
                                    </div>

                                    <div>
                                        {this.getDeliveryCharge()}
                                    </div>
                                </div>
                                <div className="footerItem">
                                    <div>
                                        FoodHub Points Used
                                    </div>

                                    <div>
                                        {this.getUsedPoints()}
                                    </div>
                                </div>
                                <div className="footerItem">
                                    <div>
                                        Total Price
                                    </div>

                                    <div>
                                        {this.getTotalPrice()}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Dialog open={isDialogOpen} className={"dialog"}>
                            <DialogTitle>
                                Warning
                        </DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    This action will clear your current items in the basket. Do you wish to proceed?
                        </DialogContentText>
                            </DialogContent>

                            <DialogActions>
                                <Button color="Primary" variant="contained" onClick={this.handleReorder}>
                                    Submit
                            </Button>

                                <Button color="primary" onClick={this.handleClose}>
                                    Cancel
                            </Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { cart } = state.cartReducer;
    return {
        cart,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderList);