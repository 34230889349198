import React, { Component } from "react";

import "./UserInbox.scss";
import time from "../../core/lib/time";

import BackButton from "../../shared/BackButton/BackButton";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TimerOutlinedIcon from "@material-ui/icons/TimerOutlined";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import SubjectRoundedIcon from "@material-ui/icons/SubjectRounded";
import { IconButton, LinearProgress, TablePagination } from "@material-ui/core";
import TopBar from "../../shared/TopBar/TopBar";
import { CustomerMessages } from "../../core/api";
import UserMessage from "./Message/UserMessage";
import { CommonActions } from "../../core/constants/actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { messageType } from "../../core/contants";

// const TablePagination = ({onNext,onPrev,pageIndex=1,totalPages=1}) =>(
//   <div className="tblPagination">
//     <span className="pageIndex">Page-{pageIndex} of {totalPages}</span>
//     <IconButton aria-label="right" onClick={onPrev}>
//       <ArrowBackIosIcon fontSize="small" />
//     </IconButton>
//     <IconButton aria-label="left" onClick={onNext}>
//       <ArrowForwardIosIcon fontSize="small" />
//     </IconButton>
//   </div>
// )

 class UserInbox extends Component {
  state = {
    inbox: [],
    isLoading: true,
    userMessageModal : false,
    selectedMessage:{},
    pageIndex:1,
    totalPages:1,
    totalCount:0,
    rowsPerPage:10
  };

  componentDidMount = () => {
    this.setState({isLoading: true});
    this.getUserInbox(1);
  };

  getUserInbox = async (pageIndex) => {
    try{
      const result = await CustomerMessages.getUserInbox(pageIndex,this.state.rowsPerPage);
      // console.log("result",result.length );
      if(result){
        this.setState({
          inbox: result['messages'],
          isLoading: false,
          pageIndex,
          totalPages:result['totalPages'],
          totalCount:result["totalCount"]
        });
      }else{
        this.setState({isLoading: false})
      }
    }catch(err){
      this.setState({
        isLoading: false,
      });
    }
  };

  routeOrderDetails = (orderId) => {
    this.props.history.push(`/order-details/${orderId}`)
  }

  elapesedTime = (startTime) => {
    let timeDiff = Math.ceil(time.getDuration(startTime) / time.timeUnits.Minute)

    if (timeDiff < 100) {
      return timeDiff + " minutes";
    }

    return time.timestampToDateString(startTime);
  };

  toggleModal = (data={}) =>{
    this.setState({
        userMessageModal:!this.state.userMessageModal,
        selectedMessage:data
    },()=>{
      if(!data['read'] && data['id']){
        this.markAsRead(data['id'])
      }
    })
  }
  markAsRead =(id)=>{
    CustomerMessages.markAsRead(id)
    .then(async ()=>{
      const ref = await CustomerMessages.getNotReadCount();
      this.props.updateNotReadMessageCount(ref['count']);
      await this.getUserInbox()
    })
  }
 
  onNext = ()=>{
    const pageIndex = this.state.pageIndex;
    if(pageIndex < this.state.totalPages)
    this.getUserInbox(pageIndex+1);
  }
  onPrev = ()=>{
    const pageIndex = this.state.pageIndex;
    if(pageIndex > 1)
    this.getUserInbox(pageIndex-1);
  }

  onChangePage =(event, newPage) =>{
    this.state.pageIndex>newPage?this.onPrev():this.onNext()
  }
  handleChangeRowsPerPage = (event) =>{
    this.setState({rowsPerPage:parseInt(event.target.value, 10)},()=>{
      this.getUserInbox(1)
    })
  }

  render() {
    const { inbox, isLoading,userMessageModal,selectedMessage,
      pageIndex,totalPages,totalCount,rowsPerPage} = this.state;

    return (
      <div className="order-list-container">
        <div className="top-bar">
            <TopBar label="Inbox" />
        </div>

        <UserMessage
            open={userMessageModal}
            close={this.toggleModal}
            data={selectedMessage}
        />
        {isLoading ? (
          <LinearProgress />
        ) : (
          <div className="section">
            <div className="back-button">
              <BackButton text="BACK TO HOME" link="/order-page-one" />
            </div>

            <div className="table-section">
              <div>
                <Paper>
                  <TableContainer >
                    <Table stickyHeader>
                      <TableHead>
                        <TableRow>
                        <TableCell align="left">
                            <div className="head-item">
                              <SubjectRoundedIcon fontSize="small" />
                              <div className="title">SUBJECT</div>
                            </div>
                          </TableCell>
                          <TableCell align="left">
                            <div className="head-item">
                              <TimerOutlinedIcon fontSize="small" />
                              <div className="title">DATE TIME</div>
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className="head-item">
                              <DescriptionOutlinedIcon fontSize="small" />
                              <div className="title">ORDER NUMBER</div>
                            </div>
                          </TableCell>
           
                          <TableCell align="left">
                            <div className="head-item">
                              <CheckCircleOutlineIcon fontSize="small" />
                              <div className="title">STATUS</div>
                            </div>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {inbox.map((item, i) => (
                          <TableRow hover key={i} onClick={() => { this.toggleModal(item) }} >
                            <TableCell align="left" className={!item['read']?'bold':''}>
                              {item.subject}
                            </TableCell>
                            <TableCell align="left" className={!item['read']?'bold':''}>
                              {time.timestampToDateString(item.createdOn)}
                            </TableCell>

                            <TableCell  component="th" scope="row" className={!item['read']?'bold':''}>
                            <div className="order-title">
                              {item['messageType'] === messageType.orderMessage && item.orderNo}
                              {item['messageType'] === messageType.broadcast && (<LocalOfferIcon color="primary" fontSize="small"/>)}
                            </div>
                            </TableCell>
                            <TableCell align="left" className={!item['read']?'bold':''}>
                              {item['messageType'] === messageType.orderMessage && (<span style={{color:item.resolved?'#008000':'#d9211d'}}>{item.resolved?"Resolved":"Pending"}</span>)}
                              {item['messageType'] === messageType.broadcast && "---"}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[5,10,25]}
                    component="div"
                    count={totalCount}
                    rowsPerPage={rowsPerPage}
                    page={pageIndex-1}
                    onChangePage={this.onChangePage}
                    style={{borderTop:'1px solid lightgrey'}}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  />
                </Paper>


                {/* <TablePagination 
                    onNext={this.onNext}
                    onPrev={this.onPrev}
                    pageIndex={pageIndex}
                    totalPages={totalPages}
                  /> */}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}


const mapStateToProps = (state)=>{
  const {notReadMessageCount} = state.commonReducer;
  return {
    notReadMessageCount
  }
}

const mapDispatchToProps = (dispatch) =>{
  return {
    updateNotReadMessageCount:(count)=>{
      dispatch({
        type:CommonActions.UPDTE_READ_COUNT,
        payload:{
          count
        }
      })
    }
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(UserInbox));