import httpApi from "../http-api/http-api";

class AccountApi {
  create = async (user) => {
    const data = await httpApi.sendPostRequest("/Account/register", user);
    return data;
  };

  getUser = async (userId) => {
    const user = await httpApi.sendGetRequest(
      `/Account/UserProfile/userId?=${userId}`
    );
    return user;
  };

  updatePassword = async (userId, ChangePassword) => {
    await httpApi.sendPostRequest(
      `/Account/${userId}/updatePassword`,
      ChangePassword
    );
  };

  getAddresses = async (userId) => {
    const userAddress = await httpApi.sendGetRequest(
      `/Account/UserAddress/${userId}`
    );
    return userAddress;
  };

  async update(updates) {
    const user = await httpApi.sendPostRequest(
      `/Account/UpdateProfile`,
      updates
    );
    return user;
  }

  async getPoints(id) {
    const { foodHubPoint } = await httpApi.sendGetRequest(
      `/Account/GetUserPoint/${id}`
    );
    return foodHubPoint;
  }

  async isSessionValid(id) {
    return await httpApi.sendGetRequest(`/Account/isvalid`);
  }
}

export default new AccountApi();
