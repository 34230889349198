import httpApi from "../http-api/http-api";
import axios from 'axios';
import qs from 'qs';

class OrderApi {
  checkout = async (Checkout) => {
    const res = await httpApi.sendPostRequest("/Order/Checkout", Checkout);
    return res;
  };

  retrieveOrderCheckout = async(orderId)=>{
    const orderCheckout = await httpApi.sendGetRequest(`/Order/${orderId}/RetrieveOrderCheckout/`);
    return orderCheckout;
  }
  retrieveSumupCheckout = async (checkoutRefId)=>{
    // console.log("checkoutRefId",checkoutRefId)
    const orderHistory = await httpApi.sendGetRequest(`/Order/RetrieveSumupCheckout/${checkoutRefId}`);
    return orderHistory;
  }
  
  getSumupCheckoutDetails = async (orderId)=>{
    // console.log("checkoutRefId",checkoutRefId)
    const sumupDetails = await httpApi.sendGetRequest(`/Order/SumupCheckoutDetails/${orderId}`);
    return sumupDetails;
  }
  getPaylandsPaymentUrl = async (orderId)=>{
    // console.log("checkoutRefId",checkoutRefId)
    const paylandsDetails = await httpApi.sendGetRequest(`/Order/PaylandsPaymentUrl/${orderId}`);
    return paylandsDetails;
  }
  orderPlaced = async (OrderData) => {
    const res = await httpApi.sendPostRequest("/Order/PaymentAuthApp", OrderData);
    return res;
  };

  posCheckout = async (Checkout) => {
    const res = await httpApi.sendPostRequest("/POS/Checkout", Checkout);
    return res;
  };

  getOrderHistory = async () => {
    const orderHistory = await httpApi.sendGetRequest("/Order/GetOrderHistory");
    return orderHistory;
  };

  getOrderDetails = async (orderId) => {
    const orderDetails = await httpApi.sendGetRequest(
      `/Order/GetOrderDetails/${orderId}`
    );
    // console.log(orderDetails);
    return orderDetails;
  };

  reOrderById = async (orderId) => {
    const orderDetails = await httpApi.sendGetRequest(
      `/Order/ReOrder/${orderId}`
    );
    return orderDetails;
  };

  exportOrders = async () => {
    const res = await httpApi.sendGetRequest(`/Order/ExportToCSV`);
    return res;
  };

  getPaymentUrl = async (paymentInfo) => {
    const res = await httpApi.sendPostRequest(`/Order/PaymentUrl`, paymentInfo);
    return res;
  }

  async sendPaymentRequest(url, data) {
    console.log(url, data);

    const resp = await axios({
      method: 'post',
      url,
      data,
      headers: {
        'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
      }
    })

    console.log(resp);
  }

  _sendPaymentRequest(url, data) {
    let formData = ''

    for (let key in data) {
      const input = `<input type="text" name="${key}" value="${data[key]}">`
      formData += `${input}\n`;
    }

    console.log(formData);

    const form = `
      <form>
        ${formData}
      </form>
    `

    const formElement = document.createElement('form');
    formElement.innerHTML = form;
    formElement.method = 'POST';
    formElement.action = url;

    console.log(formElement);

    document.getElementsByTagName('body')[0].appendChild(formElement);

    formElement.submit();
  }


  cancelOrder = async (data) => {
    const res = await httpApi.sendPostRequest(`/Order/CancelOrder`, data);
    return res;
  };
  
  cancelOrderPayment = async (orderId=null) => {
    const res = await httpApi.sendPostRequest(`/Order/${orderId}/CancelPayment`, {});
    return res;
  };

  updateOrderDeliveryAddress = async (
    sendReq={"orderId": "","addressLine1": "","addressLine2": "","city": "","postCode": ""
  }) => {
    if(sendReq.orderId === '')
    return;

    const res = await httpApi.sendPutRequest(`/Order/DeliveryAddress/${sendReq.orderId}`, sendReq);
    return res;
  };
}

export default new OrderApi();