import React, { PureComponent } from 'react';
import { 
    Button, 
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogContentText, 
    IconButton, 
    TextField 
} from "@material-ui/core";
import MuiAlert from '@material-ui/lab/Alert';
import { Edit } from '@material-ui/icons';
import { showConfrimBox } from '../../../../../shared/ComfirmAlertBox/ConfirmAlertBox';
import { showToast } from '../../../../../shared/ToastAlert/ToastAlert';
import style from "./UpdateOrderAddress.module.scss";
import orderApi from '../../../../../core/api/order-api/order-api';

class UpdateOrderAddress extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
        cancelDialogOpen:false,
        addressLine1:'',
        addressLine2:'',
        city: "Gibraltar",
        postCode: "GX11 1AA",
        hasError:false
    };
  }


  handleConfirm = async () => {
    const { addressLine1,addressLine2,city,postCode } = this.state;
    const {orderId} = this.props;
    orderApi.updateOrderDeliveryAddress({
        orderId,
        addressLine1,
        addressLine2,
        city,
        postCode
    }).then(()=>{
        this.handleConfirmClose();
        this.props.fetchOrderDetails()
        showToast({
            title:"Delivery address updated successfully."
        })
    })
    .catch(()=>{
        showToast({
            title:"Something went wrong please try again.",
            type:'error'
        })
    })
    // if(!!orderCancelRef && orderCancelRef.status === 5){
    //     fetchOrderDetails();
    //     this.setState({successBar:true})
    // }
    // console.log("Cancel Order",cancelNote,orderId,orderCancelRef)



  
    }

    handleOpen = () => {
        this.setState({
            cancelDialogOpen: true,
            cancelNote:'',
            errorMsg:''
        })
    }
    handleChange = (event) => {
        event.persist();

        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    handleClose = () => {
        this.setState({
            cancelDialogOpen: false,
            cancelNote: ''
        })
    }
    handleConfirmClose = () => {
        this.setState({
            confirmCancel:false
        })
    }

    handleSubmit =() =>{
        const { addressLine1,addressLine2,city,postCode } = this.state;
        if(addressLine1 === '' || addressLine2 === '' || city === '' || postCode === ''){
            this.setState({hasError:true});
            return
        }
        this.setState({
            cancelDialogOpen: false,
        })

        showConfrimBox({
            title:"Are you sure?",
            description:"You want to update delivery Address.",
            okButtonText:"YES",
            onOkClick:this.handleConfirm,
            cancelButtonText:"CANCEL",
            onCancelClick:this.handleConfirmClose,
        })
    }

    handleCloseSuccessBar = ()=>{
        this.setState({successBar:false})
    }

  render() {
      const {
        cancelDialogOpen,
        addressLine1,
        addressLine2,
        city,
        postCode,
        hasError
      } = this.state;
    return (
        <span>
            <label htmlFor="icon-button-file">
                <IconButton
                    style={{padding:'6px',marginLeft:'10px'}}
                    color="primary" aria-label="upload picture" component="span"
                    onClick={this.handleOpen}
                >

                    <Edit />
                </IconButton>
            </label> 
            <Dialog open={cancelDialogOpen} >
                <DialogContent>
                    <DialogContentText>
                        Update Delivery Address
                    </DialogContentText>

                    <div className={style.formClass}>
                        <div className={style.flexBox}>
                            <TextField
                                autoFocus
                                label="Address Line 1"
                                name="addressLine1"
                                value={addressLine1}
                                fullWidth
                                onChange={this.handleChange}
                                className={style.inputBox}
                                error = {hasError && addressLine1 === ''}
                                helperText={(hasError && addressLine1 === '')?'Address Line 1 must not be empty':''}
                            />
                            <TextField
                                label="Address Line 2"
                                name="addressLine2"
                                value={addressLine2}
                                fullWidth
                                onChange={this.handleChange}
                                className={style.inputBox}
                                error = {hasError && addressLine2 === ''}
                                helperText={(hasError && addressLine2 === '')?'Address Line 2 must not be empty':''}
                            />
                        </div>
                        <div className={style.flexBox}>
                            <TextField
                                autoFocus
                                label="City"
                                name="city"
                                value={city}
                                fullWidth
                                onChange={this.handleChange}
                                className={style.inputBox}
                                disabled
                                error = {hasError && city === ''}
                                helperText={(hasError && city === '')?'City must not be empty':''}
                            />
                            <TextField
                                label="Post Code"
                                name="postCode"
                                value={postCode}
                                fullWidth
                                onChange={this.handleChange}
                                className={style.inputBox}
                                disabled
                                error = {hasError && postCode === ''}
                                helperText={(hasError && postCode === '')?'Post Code must not be empty':''}
                            />
                        </div>
                  
                    </div>
                </DialogContent>

                <DialogActions>
                    <Button color="primary" variant="contained" onClick={this.handleSubmit}>
                        Update
                    </Button>

                    <Button color="primary" onClick={this.handleClose}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </span>
    );
  }
}

export default UpdateOrderAddress;

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }